import {useState} from "react";

/**
 * Custom React-Hook: to manage input fields on the form
 * @param initialValue: the initial value of the filter field on the form
 */
export function useInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return [value, setValue, handleChange];
}

/**
 * Custom React-Hook: to manage checkbox fields on the form
 * @param initialValue: set the value-attribute's initial value
 * @param checkedValue: if the checkbox is checked, set the value-attribute's value
 * @param unCheckedValue: if the checkbox is unChecked, set the value-attribute's value
 */
export function useInputCheckbox(initialValue, checkedValue, unCheckedValue) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    event.currentTarget.checked
        ? setValue(checkedValue)
        : setValue(unCheckedValue)
  };

  return [value, setValue, handleChange];
}

/**
 * Custom React-Hook: to manage switch fields on the form
 * @param initialValue: set the value-attribute's initial value
 */
export const useInputSwitch = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (parameter) => {
    setValue(parameter);
  };

  return [value, handleChange];
};
