import React, {useEffect, useRef, useState} from 'react';
import {Modal, Form, Input, Button, Spin, Alert, message} from 'antd';
import {AutoCompleteSelectBox} from "../../common/AutoCompleteSelectBox";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const FormAccount = ({
  visible,
  accountIdForUpdate,
  accountUserName,
  optionsPPCTypeSelect,
  optionsRoleSelect,
  optionsCompanySelect,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  // SELECT BOX - ACCOUNT
  const [ppcType, setPpcType] = useState(null);
  const selectPpcTypeRef = useRef(null);
  const [optionsPpcType, setOptionsPpcType] = useState(optionsPPCTypeSelect);

  // SELECT BOX - ROLE
  const [role, setRole] = useState(null);
  const selectRoleRef = useRef(null);
  const [optionsRole, setOptionsRole] = useState(optionsRoleSelect);

  // SELECT BOX - COMPANY
  const [company, setCompany] = useState(null);
  const selectCompanyRef = useRef(null);
  const [optionsCompany, setOptionsCompany] = useState(optionsCompanySelect);

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  useEffect(() => {
    setOptionsPpcType(optionsPPCTypeSelect);
    setOptionsRole(optionsRoleSelect);
    setOptionsCompany(optionsCompanySelect);
  }, [optionsPPCTypeSelect, optionsRoleSelect, optionsCompanySelect]);

  const successSave = (response) => {
    // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("A felhasználó mentése sikertelen volt!")
    // } else {
    //   message.success("A felhasználó sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("A felhasználó sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("A felhasználó mentése sikertelen volt!")
  };

  const serverSaveProcess = (values) => {
    let roleIdArray = [];
    if (role && Array.isArray(role)) {
      role.forEach(item => roleIdArray.push(Number(item.value)));
    }

    let accountValues = {
      ...values,
      ppcType: ppcType && ppcType.label ? ppcType.label : null,
      roleIds: roleIdArray.length > 0 ? roleIdArray : null,
      companyId: company && company.value ? Number(company.value) : null
    };

    axios.post("/api/account/createAccount", accountValues)
    .then((response) => {
      // console.log("Account Form (SAVE) - server saved the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      selectPpcTypeRef.current.select.clearValue();
      selectRoleRef.current.select.clearValue();
      selectCompanyRef.current.select.clearValue();
      setPpcType(null);
      setRole(null);
      setCompany(null);
      form.resetFields();
      onCreate(accountValues);
    })
    .catch((error) => {
      console.error("Account Form (SAVE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  const serverUpdateProcess = (values) => {
    let roleIdArray = [];
    if (role && Array.isArray(role)) {
      role.forEach(item => roleIdArray.push(Number(item.value)));
    }

    let accountValues = {
      ...values,
      ppcType: ppcType && ppcType.label ? ppcType.label : null,
      roleIds: roleIdArray.length > 0 ? roleIdArray : null,
      companyId: company && company.value ? Number(company.value) : null
    };

    axios.put("/api/account/updateAccount/" + accountIdForUpdate, accountValues)
    .then((response) => {
      // console.log("Account Form (UPDATE) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      selectPpcTypeRef.current.select.clearValue();
      selectRoleRef.current.select.clearValue();
      selectCompanyRef.current.select.clearValue();
      setPpcType(null);
      setRole(null);
      setCompany(null);
      form.resetFields();
      onCreate(accountValues);
    })
    .catch((error) => {
      console.error("Account Form (UPDATE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  useEffect(() => {
    const findAccountById = () => {
      if (accountIdForUpdate) {
        // console.log("findAccountById: ", accountIdForUpdate);
        axios.get("/api/account/findByIdAndGetDTO/" + accountIdForUpdate)
        .then((response) => {
          // console.log("findAccountById axios.get response: ", response);
          if (isSessionInvalid(response)) {
            logoutWhenSessionIsInvalid(setIsLogout)
          } else {
            setRefreshSession(i => i + 1);
            message.success("A felhasználó adatai sikeresen betöltésre kerültek a szerkesztéshez!");
            setPpcType(response && response.data && response.data.ppcType ? response.data.ppcType : null);
            setRole(response && response.data && response.data.roles ? response.data.roles : null);
            setCompany(response && response.data && response.data.company ? response.data.company : null);
            form.setFieldsValue(response.data);
            setLoadingInitialValues(false);
          }
        })
        .catch((error) => {
          console.error("findAccountById axios.get error: ", error);
          message.error("A felhasználó szerkesztése sikertelen volt, mert nem található az adatbázisban!");
          form.resetFields();
          setLoadingInitialValues(false);
        })
      }
    };

    if (accountIdForUpdate) {
      setLoadingInitialValues(true);
      findAccountById();
      // setTimeout(() => findAccountById(), 3000);
    }
  }, [accountIdForUpdate]);

  return (
      <Modal
          visible={visible}
          title={accountIdForUpdate && accountUserName
              ? "Felhasználó (" + accountUserName + ") szerkesztése"
              : "Új felhasználó rögzítése"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            selectPpcTypeRef.current.select.clearValue();
            selectRoleRef.current.select.clearValue();
            selectCompanyRef.current.select.clearValue();
            setPpcType(null);
            setRole(null);
            setCompany(null);
            form.resetFields();
            setServerValidation(null);
            // setTimeout: This is necessary: clear SELECT values before close the modal.
            setTimeout(() => onCancel(), 500)
          }}
          cancelButtonProps={{disabled: (loadingInitialValues || saving)}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              if (accountIdForUpdate) {
                serverUpdateProcess(values);
              } else {
                serverSaveProcess(values);
              }
            })
            .catch((error) => {
              console.error("Account Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: (loadingInitialValues || saving)}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={loadingInitialValues || saving}>
          <Form
              form={form}
              layout="vertical"
              name="account_form_in_modal"
              autoComplete="off"
          >
            <Form.Item
                name="lastName"
                label="Vezetéknév"
                rules={[
                  {
                    required: true,
                    message: "Kérjük töltse ki a vezetélnevet!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="firstName"
                label="Keresztnév"
                rules={[
                  {
                    required: true,
                    message: "Kérjük töltse ki a keresztnevet!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="userName"
                label="Falhasználónév"
                rules={[
                  {
                    required: true,
                    message: "Kérjük töltse ki a felhasználónevet!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    required: true,
                    message: "Kérjük töltse ki az e-mail címet!"
                  },
                  {
                    type: "email",
                    message: "Nem érvényes az e-mail cím formátuma!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            {accountIdForUpdate
                ?
                null
                :
                <Form.Item
                    name="password"
                    label="Jelszó"
                    rules={[
                      {
                        required: true,
                        message: "Kérjük töltse ki a jelszót!",
                      },
                    ]}
                >
                  <Input.Password/>
                </Form.Item>
            }

            {accountIdForUpdate
                ?
                null
                :
                <Form.Item
                    name="confirm_password"
                    label="Jelszó megerősítése"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Kérjük erősítse meg a fentebb már megadott jelszót!",
                      },
                      ({getFieldValue}) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("A jelszavak nem egyeznek meg!"));
                        },
                      }),
                    ]}
                >
                  <Input.Password/>
                </Form.Item>
            }

            <Form.Item
                name="ppcType"
                label="* PPC típus (Kötelező egyet választani!)"
            >
              <AutoCompleteSelectBox
                  nameAndClassAttr="ppcTypeSELECT"
                  placeholder="Válasszon egy PPC típust!"
                  selectFilterFieldRef={selectPpcTypeRef}
                  filterField={ppcType}
                  setFilterField={setPpcType}
                  optionsArray={optionsPpcType}
                  isMultiSelect={false}
                  isClearable={true}
              />
            </Form.Item>

            <Form.Item
                name="roleIds"
                label="* Jogosultság (Kötelező min. egyet, de lehet többet is választani!)"
            >
              <AutoCompleteSelectBox
                  nameAndClassAttr="roleSELECT"
                  placeholder="Válasszon egy jogosultságot!"
                  selectFilterFieldRef={selectRoleRef}
                  filterField={role}
                  setFilterField={setRole}
                  optionsArray={optionsRole}
                  isMultiSelect={true}
                  isClearable={true}
              />
            </Form.Item>

            <Form.Item
                name="companyId"
                label="* Cég (Kötelező egyet választani!)"
            >
              <AutoCompleteSelectBox
                  nameAndClassAttr="companySELECT"
                  placeholder="Válasszon egy céget!"
                  selectFilterFieldRef={selectCompanyRef}
                  filterField={company}
                  setFilterField={setCompany}
                  optionsArray={optionsCompany}
                  isMultiSelect={false}
                  isClearable={true}
              />
            </Form.Item>

            <Button onClick={() => {
              selectPpcTypeRef.current.select.clearValue();
              selectRoleRef.current.select.clearValue();
              selectCompanyRef.current.select.clearValue();
              setPpcType(null);
              setRole(null);
              setCompany(null);
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );
};

export default FormAccount;
