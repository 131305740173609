import React, {Fragment} from "react";

const NotFound = React.memo(() => {
  return (
      <Fragment>
        <h1>A keresett oldal nem található / Nincs jogosultsága!</h1>
      </Fragment>
  );
});

export default NotFound;
