import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, Spin, Alert, message} from 'antd';
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const FormCompany = ({
  visible,
  companyIdForUpdate,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  const successSave = (response) => {
    // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("A cég mentése sikertelen volt!")
    // } else {
    //   message.success("A cég sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("A cég sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("A cég mentése sikertelen volt!")
  };

  const serverSaveProcess = (values) => {
    axios.post("/api/company/createCompany", values)
    .then((response) => {
      // console.log("Company Form (SAVE) - server saved the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      onCreate(values);
    })
    .catch((error) => {
      console.error("Company Form (SAVE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  const serverUpdateProcess = (values) => {
    axios.put("/api/company/updateCompany/" + companyIdForUpdate, values)
    .then((response) => {
      // console.log("Company Form (UPDATE) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      onCreate(values);
    })
    .catch((error) => {
      console.error("Company Form (UPDATE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  useEffect(() => {
    const findCompanyById = () => {
      if (companyIdForUpdate) {
        // console.log("findCompanyById: ", companyIdForUpdate);
        axios.get("/api/company/findByIdAndGetDTO/" + companyIdForUpdate)
        .then((response) => {
          // console.log("findCompanyById axios.get response: ", response);
          if (isSessionInvalid(response)) {
            logoutWhenSessionIsInvalid(setIsLogout)
          } else {
            setRefreshSession(i => i + 1);
            message.success("A cég adatai sikeresen betöltésre kerültek a szerkesztéshez!");
            form.setFieldsValue(response.data);
            // form.setFieldsValue({
            //   ["companyName"]: response.data.companyName ? response.data.companyName : "",
            //   ["companyInfo"]: response.data.companyInfo ? response.data.companyInfo : ""
            // });
            setLoadingInitialValues(false);
          }
        })
        .catch((error) => {
          console.error("findCompanyById axios.get error: ", error);
          message.error("A cég szerkesztése sikertelen volt, mert nem található az adatbázisban!");
          form.resetFields();
          setLoadingInitialValues(false);
        })
      }
    };

    if (companyIdForUpdate) {
      setLoadingInitialValues(true);
      findCompanyById();
      // setTimeout(() => findCompanyById(), 3000);
    }
  }, [companyIdForUpdate]);

  return (
      <Modal
          visible={visible}
          title={companyIdForUpdate ? "Cég szerkesztése" : "Új cég rögzítése"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            form.resetFields();
            setServerValidation(null);
            onCancel();
          }}
          cancelButtonProps={{disabled: (loadingInitialValues || saving)}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              if (companyIdForUpdate) {
                serverUpdateProcess(values);
                // setTimeout(() => serverUpdateProcess(values), 3000);
              } else {
                serverSaveProcess(values);
                // setTimeout(() => serverSaveProcess(values), 3000);
              }
            })
            .catch((error) => {
              console.error("Company Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: (loadingInitialValues || saving)}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={loadingInitialValues || saving}>
          <Form
              form={form}
              layout="vertical"
              name="company_form_in_modal"
              autoComplete="off"
              // initialValues={}
          >
            <Form.Item
                name="companyName"
                label="Cég neve"
                rules={[
                  {
                    required: true,
                    message: "Kérjük töltse ki a cég neve mezőt!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="companyInfo"
                label="Céggel kapcsolatos információk, megjegyzések"
            >
              <Input/>
            </Form.Item>

            <Button onClick={() => {
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );
};

export default FormCompany;
