import React, {Fragment} from "react";
import {Button, Divider, Popconfirm} from "antd";
import {EditOutlined, DeleteOutlined, PlusSquareOutlined} from "@ant-design/icons";

const writeConsole = (text, data) => {
  console.log(`BuildEditorPage - writeConsole: ${text}: `, data);
};

const BuildEditorPage = ({
  procedureData,
  openUpdateProcedureDataModal,
  openNewProcedureDataDetailsModal,
  openUpdateProcedureDataDetailsModal,
  openNewBiddingModal,
  openUpdateBiddingModal,
  openNewContractModal,
  openUpdateContractModal,
  serverDeleteProcess
}) => {

  // 1. procedureData {object}:         if !==null         --> PD with pd-EDIT button
  //                                    if ===null         --> BuiltEditorPage return NULL
  // 2. procedureDataDetails [array]:   if length > 0      --> PDD-LIST with pdd-EDIT button and pdd.bidding-CREATE button
  //                                    if length === 0    --> PDD return NULL
  // 3. PDD.biddings [array]:           if length > 0      --> PDD.LIST[i].B-LIST
  //                                                           with b-EDIT button and c-CREATE button(if b.winner=true and pdd.c=null)
  //                                    if lenth === 0     --> B return NULL
  // 4. PDD.contract {object}           if !==null         --> C with c-EDIT button
  //                                    if ===null         --> C return NULL

  //console.log("procedureData: ", procedureData);

  const ProcedureDataElement = () => {
    return (
        <div>
          <Divider orientation="left">{`Eljárás: ${procedureData.eljarasEKRId}`}</Divider>
          <Button
              className="custom-edit-button"
              // onClick={() => writeConsole("PD-EDIT-BUTTON - pdId", procedureData.eljarasId)}
              onClick={() => openUpdateProcedureDataModal(procedureData.eljarasId)}
              icon={<EditOutlined/>}
          >
            Eljárás szerkesztése
          </Button>
          &nbsp;&nbsp;&nbsp;
          {
            (procedureData.eljarasReszAdatokLista
                && Array.isArray(procedureData.eljarasReszAdatokLista)
                && procedureData.eljarasReszAdatokLista.length === 0)
                ?
                <Popconfirm
                    title="Biztosan ki szeretné törölni az eljárást?"
                    okText="Törlés"
                    cancelText="Mégsem"
                    onConfirm={() => serverDeleteProcess("/api/procedureData/deleteProcedureData/" + procedureData.eljarasId, "Eljárás")}
                >
                  <Button className="custom-delete-button" icon={<DeleteOutlined/>}>
                    Eljárás törlése
                  </Button>
                </Popconfirm>
                :
                null
          }
          &nbsp;&nbsp;&nbsp;
          <AddNewProcedureDataDetails/>
        </div>
    );
  };

  const AddNewProcedureDataDetails = () => {
    let procedureDataId = procedureData && procedureData.eljarasId ? procedureData.eljarasId : null;
    return (procedureDataId
            ?
            <Button
                className="custom-create-button"
                // onClick={() => writeConsole("PDD-CREATE-BUTTON - pdId", procedureDataId)}
                onClick={() => openNewProcedureDataDetailsModal(procedureDataId)}
                icon={<PlusSquareOutlined/>}
            >
              Új Eljárásrész rögzítése
            </Button>
            :
            null
    );
  };

  const ProcedureDataDetailsList = () => {
    const pddItems = [...procedureData.eljarasReszAdatokLista].map((item, index) => {
      return (
          <li key={item.eljarasReszAdataiId + "_" + item.eljarasReszAdataiSorszam}>
            <ProcedureDataDetailsElement
                pddElement={item}
                pdId={procedureData.eljarasId}
            />
          </li>
      );
    });

    return (
        <Fragment>
          <Divider orientation="left">Eljárásrészek</Divider>
          <ul>{pddItems}</ul>
        </Fragment>
    );
  };

  const ProcedureDataDetailsElement = ({pddElement, pdId}) => {
    // console.log("ProcedureDataDetails: ", pddElement);
    return (
        <div className="procedureDataDetailsElement">
          <Divider/>
          <div>
            Sorszám: <strong>{pddElement.eljarasReszAdataiSorszam}</strong>; Hatóanyag: <strong>{pddElement.hatoanyag.hatoanyagNeve}</strong>
          </div>

          <Button
              className="custom-edit-button"
              // onClick={() => writeConsole("PDD-EDIT-BUTTON - pdId, pddId", pdId + "_" + pddElement.eljarasReszAdataiId)}
              onClick={() => openUpdateProcedureDataDetailsModal(pdId, pddElement.eljarasReszAdataiId)}
              icon={<EditOutlined/>}
          >
            {`Eljárásrész (${pddElement.hatoanyag.hatoanyagNeve}) szerkesztése`}
          </Button>

          &nbsp;&nbsp;&nbsp;
          {
            (pddElement && pddElement.ajanlattetelekLista
                && Array.isArray(pddElement.ajanlattetelekLista)
                && pddElement.ajanlattetelekLista.length === 0
                && !pddElement.szerzodes)
                ?
                <Popconfirm
                    title="Biztosan ki szeretné törölni az eljárásrészt?"
                    okText="Törlés"
                    cancelText="Mégsem"
                    onConfirm={() => serverDeleteProcess(
                        "/api/procedureDataDetails/deleteProcedureDataDetails/" + pddElement.eljarasReszAdataiId,
                        "Eljárásrész")}
                >
                  <Button className="custom-delete-button" icon={<DeleteOutlined/>}>
                    Eljárásrész törlése
                  </Button>
                </Popconfirm>
                :
                null
          }
          &nbsp;&nbsp;&nbsp;

          <Button
              className="custom-create-button"
              // onClick={() => writeConsole("B-CREATE-BUTTON - pddId", pddElement.eljarasReszAdataiId)}
              onClick={() => openNewBiddingModal(pddElement.eljarasReszAdataiId, pddElement.osszmennyiseg)}
              icon={<PlusSquareOutlined/>}
          >
            {`Új Ajánlat rögzítése (${pddElement.hatoanyag.hatoanyagNeve})`}
          </Button>

          {(pddElement.ajanlattetelekLista && Array.isArray(pddElement.ajanlattetelekLista) && pddElement.ajanlattetelekLista.length > 0)
              ?
              <BiddingsList
                  biddings={pddElement.ajanlattetelekLista}
                  pddElement={pddElement}
                  pddId={pddElement.eljarasReszAdataiId}
              />
              :
              null
          }
        </div>
    );
  };

  const BiddingsList = ({biddings, pddElement, pddId}) => {
    const bItems = [...biddings].map((item, index) => {
      return (
          <li key={item.ajanlattetelId + "_" + item.ajanlattevoAdoszama}>
            <BiddingElement
                bElement={item}
                pddElement={pddElement}
                pddId={pddId}
            />
          </li>
      );
    });

    return (
        <Fragment>
          <div><strong>Ajánlat(ok) és Szerződés:</strong></div>
          <ul>{bItems}</ul>
        </Fragment>
    );
  };

  const BiddingElement = ({bElement, pddElement, pddId}) => {
    // console.log("Bidding: ", bElement);
    let winner = bElement.nyertesEAzAjanlat ? "Nyertes " : "";

    return (
        <div>
          <div>Ajánlattevő neve: <strong>{bElement.ajanlattevoNeve}</strong></div>

          <Button
              className="custom-edit-button"
              // onClick={() => writeConsole("B-EDIT-BUTTON - pddId, bId", pddId + "_" + bElement.ajanlattetelId)}
              onClick={() => openUpdateBiddingModal(bElement.ajanlattetelId, pddId, pddElement.osszmennyiseg)}
              icon={<EditOutlined/>}
          >
            {`${winner}Ajánlat (${bElement.ajanlattevoNeve.substring(0, bElement.ajanlattevoNeve.indexOf(" ")) === "" 
                ? bElement.ajanlattevoNeve 
                : bElement.ajanlattevoNeve.substring(0, bElement.ajanlattevoNeve.indexOf(" "))}) szerkesztése`}
          </Button>

          &nbsp;&nbsp;&nbsp;

          {
            (bElement && bElement.ajanlattetelId && !pddElement.szerzodes)
                ?
                <Popconfirm
                    title="Biztosan ki szeretné törölni az ajánlatot?"
                    okText="Törlés"
                    cancelText="Mégsem"
                    onConfirm={() => serverDeleteProcess("/api/bidding/deleteBidding/" + bElement.ajanlattetelId, "Ajánlat")}
                >
                  <Button className="custom-delete-button" icon={<DeleteOutlined/>}>
                    Ajánlat törlése
                  </Button>
                </Popconfirm>
                :
                null
          }

          &nbsp;&nbsp;&nbsp;

          {(bElement.nyertesEAzAjanlat === true && pddElement.szerzodes === null)
              ?
              <Button
                  className="custom-create-button"
                  // onClick={() => writeConsole("C-CREATE-BUTTON - pddId, bId", pddId + "_" + bElement.ajanlattetelId)}
                  onClick={() => {
                    openNewContractModal(
                        pddId,
                        bElement.ajanlattetelId,
                        bElement.egysegNettoAra,
                        pddElement.aktivIdoszakHossza);
                    // console.log("New contractForm - pddElement.aktivIdoszakHossza: ", pddElement.aktivIdoszakHossza);
                  }}
                  icon={<PlusSquareOutlined/>}
              >
                {`Új Szerződés rögzítése (${pddElement.hatoanyag.hatoanyagNeve})`}
              </Button>
              :
              null
          }

          {(bElement.nyertesEAzAjanlat === true && pddElement.szerzodes !== null && pddElement.szerzodes.szerzodesId)
              ?
              <Button
                  className="custom-edit-button"
                  // onClick={() => writeConsole("C-EDIT-BUTTON - cId, pddId, bId",
                  //     pddElement.szerzodes.szerzodesId + "_" + pddId + "_" + bElement.ajanlattetelId)}
                  onClick={() => {
                    openUpdateContractModal(
                        pddElement.szerzodes.szerzodesId,
                        pddId,
                        bElement.ajanlattetelId,
                        bElement.egysegNettoAra,
                        pddElement.aktivIdoszakHossza);
                    // console.log("Edit contractForm - pddElement.aktivIdoszakHossza: ", pddElement.aktivIdoszakHossza);
                  }}
                  icon={<EditOutlined/>}
              >
                {`Szerződés (${pddElement.hatoanyag.hatoanyagNeve}) szerkesztése`}
              </Button>
              :
              null
          }

          &nbsp;&nbsp;&nbsp;

          {(bElement.nyertesEAzAjanlat === true && pddElement.szerzodes !== null && pddElement.szerzodes.szerzodesId)
              ?
              <Popconfirm
                  title="Biztosan ki szeretné törölni a szerződést?"
                  okText="Törlés"
                  cancelText="Mégsem"
                  onConfirm={() => serverDeleteProcess("/api/contract/deleteContract/" + pddElement.szerzodes.szerzodesId, "Szerződés")}
              >
                <Button className="custom-delete-button" icon={<DeleteOutlined/>}>
                  Szerződés törlése
                </Button>
              </Popconfirm>
              :
              null
          }
        </div>
    );
  };

  return (
      <div>
        {(procedureData)
            ? <ProcedureDataElement/>
            : null
        }
        {(procedureData
            && procedureData.eljarasReszAdatokLista
            && Array.isArray(procedureData.eljarasReszAdatokLista)
            && procedureData.eljarasReszAdatokLista.length > 0)
            ? <ProcedureDataDetailsList/>
            : null
        }
      </div>
  )
};

export default BuildEditorPage;
