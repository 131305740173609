import React, {useEffect} from "react";
import {GanttChart} from "ibm-gantt-chart-react";
import "ibm-gantt-chart/dist/ibm-gantt-chart.css";

export const Gantt = React.memo(({config}) => {

  const reNameActiveIngredientColumn = () => {
    if (document.getElementsByClassName("hierarchy-control sorting")[0]) {
      document.getElementsByClassName("hierarchy-control sorting")[0].innerHTML = "Hatóanyagok";
      document.getElementsByClassName("hierarchy-control sorting")[0].style.textAlign = "left";
    }
  };

  useEffect(() => {
    setTimeout(reNameActiveIngredientColumn, 200);
  }, [config]);

  return (
      <GanttChart config={config}/>
  );
});
