import React, {Fragment, useEffect, useState} from "react";
import {ConfigProvider, Table, Button, Divider, Popconfirm, Spin, Row, Col, message} from 'antd';
import {PlusSquareOutlined, EditOutlined, LockOutlined, DeleteOutlined} from '@ant-design/icons';
import huHU from 'antd/es/locale/hu_HU';
import FormCompany from "./FormCompany";
import FormAccount from "./FormAccount";
import FormPswdChange from "./FormPswdChange";
import {getColumnSearchProps} from "../../common/TableColumnSearch";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import { Chart } from "react-google-charts";
import {useGetHTTPMethodWithoutSessionRefresh} from "../../common/HttpMethods";
import {StatisticsDataTable} from "./StatisticsDataTable";
import axios from "axios";

const Admin = ({setIsLogout, setRefreshSession}) => {

  // Pie Chart data
  const [dataLoginPieChart, loadingDataLoginPieChart] = useGetHTTPMethodWithoutSessionRefresh
  (
      "/api/neak/statistics/login",
      [],
      "PieChart - dataLogin"
  );
  const [dataTablePieChart, loadingDataTablePieChart] = useGetHTTPMethodWithoutSessionRefresh
  (
      "/api/neak/statistics/table",
      [],
      "PieChart - dataTable"
  );
  const [dataGanttChartPieChart, loadingDataGanttChartPieChart] = useGetHTTPMethodWithoutSessionRefresh
  (
      "/api/neak/statistics/ganttChart",
      [],
      "PieChart - dataGanttChart"
  );
  const [dataProcedureDataExcelPieChart, loadingDataProcedureDataExcelPieChart] = useGetHTTPMethodWithoutSessionRefresh
  (
      "/api/neak/statistics/procedureDataExcel",
      [],
      "PieChart - dataProcedureDataExcel"
  );
  const [dataContractExcelPieChart, loadingDataContractExcelPieChart] = useGetHTTPMethodWithoutSessionRefresh
  (
      "/api/neak/statistics/contractExcel",
      [],
      "PieChart - dataContractExcel"
  );

  // Pie Chart options
  const optionsLoginPieChart = {
    title: "Bejelentkezések",
    fontSize: 12,
    titleTextStyle: {fontSize: 14},
    is3D: true,
  };
  const optionsTablePieChart = {
    title: "Táblázatos lekérdezések",
    fontSize: 12,
    titleTextStyle: {fontSize: 14},
    is3D: true,
  };
  const optionsGanttChartPieChart = {
    title: "Gantt Chart lekérdezések",
    fontSize: 12,
    titleTextStyle: {fontSize: 14},
    is3D: true,
  };
  const optionsProcedureDataExcelPieChart = {
    title: "Eljárás excel letöltések",
    fontSize: 12,
    titleTextStyle: {fontSize: 14},
    is3D: true,
  };
  const optionsContractExcelPieChart = {
    title: "Szerződés excel letöltések",
    fontSize: 12,
    titleTextStyle: {fontSize: 14},
    is3D: true,
  };

  // SELECT - BOX - OPTIONS (PPC TYPE, ROLE AND COMPANY)
  const [optionsPPCType, setOptionsPPCType] = useState([]);
  const [optionsRole, setOptionsRole] = useState([]);
  const [optionsCompany, setOptionsCompany] = useState([]);

  // company Modal Form (CREATE and EDIT)
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [companyIdForUpdate, setCompanyIdForUpdate] = useState(null);
  // company Data Table
  const [companyData, setCompanyData] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);

  // account Modal Form (CREATE and EDIT (edit without password))
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [accountIdForUpdate, setAccountIdForUpdate] = useState(null);
  // account Data Table
  const [accountData, setAccountData] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);

  // account's password change (EDIT - only the password)
  const [pswdChangeModalVisible, setPswdChangeModalVisible] = useState(false);
  const [accountIdForPasswordUpdate, setAccountIdForPasswordUpdate] = useState(null);
  const [accountUserName, setAccountUserName] = useState("");

  // Data Table Search inside the Column (GLOBAL variables - use this inside every columns)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  let searchInput = '';

  // Data Table Search inside the Column
  const handleTableSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Data Table Search inside the Column
  const handleTableReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const [companyColumn] = useState([
    {
      title: 'Cég DB-ID',
      dataIndex: 'companyId',
      key: 'companyId',
      sorter: (a, b) => a.companyId - b.companyId,
      width: 30
    },
    {
      title: 'Cég neve',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a.companyName.length - b.companyName.length,
      width: 30,
      ...getColumnSearchProps('companyName', searchInput, searchText, searchedColumn, handleTableSearch, handleTableReset),
    },
    {
      title: 'Cég információ',
      dataIndex: 'companyInfo',
      key: 'companyInfo',
      sorter: (a, b) => a.companyInfo.length - b.companyInfo.length,
      width: 30
    },
    {
      title: 'Céghez tartozó felhasználók száma',
      dataIndex: 'numberOfAccounts',
      key: 'numberOfAccounts',
      sorter: (a, b) => a.numberOfAccounts - b.numberOfAccounts,
      width: 30
    },
    {
      title: 'Szerkesztés',
      dataIndex: 'companyUpdate',
      render: (_, record) => {
        return <Button
            className="custom-edit-button"
            shape="circle"
            onClick={() => openUpdateCompanyModal(record.companyId)}
            icon={<EditOutlined/>}
        />
      },
      width: 20
    }
  ]);

  const [accountColumn] = useState([
    {
      title: 'Felhasználó DB-ID',
      dataIndex: 'accountId',
      key: 'accountId',
      sorter: (a, b) => a.accountId - b.accountId,
      width: 30
    },
    {
      title: 'Keresztnév',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      width: 30
    },
    {
      title: 'Családnév',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      width: 30
    },
    {
      title: 'Felhasználónév',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => a.userName.length - b.userName.length,
      width: 50,
      ...getColumnSearchProps('userName', searchInput, searchText, searchedColumn, handleTableSearch, handleTableReset),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      width: 30
    },
    {
      title: 'Cég neve',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a.companyName.length - b.companyName.length,
      width: 30,
      ...getColumnSearchProps('companyName', searchInput, searchText, searchedColumn, handleTableSearch, handleTableReset),
    },
    {
      title: 'PPC típus',
      dataIndex: 'ppcType',
      key: 'ppcType',
      sorter: (a, b) => a.ppcType.length - b.ppcType.length,
      width: 30
    },
    {
      title: 'Jogosultság',
      dataIndex: 'roles',
      key: 'roles',
      render: (rolesArray) => {
        let result = '';
        rolesArray.forEach(function (x, i) {
          result = result + x.roleType + '; ';
        });
        return result;
      },
      width: 30
    },
    {
      title: 'Jelszócsere',
      dataIndex: 'pswdChange',
      render: (_, record) => {
        return (record.userName === "ponte.hu.kft" || record.userName === "only.admin" ||
            record.userName === "only.editor" || record.userName === "only.user")
            ?
            null
            :
            <Button
                className="custom-edit-button"
                shape="circle"
                onClick={() => openPswdChangeModal(record.accountId, record.userName)}
                icon={<LockOutlined/>}
            />
      },
      width: 20
    },
    {
      title: 'Szerkesztés',
      dataIndex: 'accountUpdate',
      render: (_, record) => {
        return (record.userName === "ponte.hu.kft" || record.userName === "only.admin" ||
            record.userName === "only.editor" || record.userName === "only.user")
            ?
            null
            :
            <Button
                className="custom-edit-button"
                shape="circle"
                onClick={() => openUpdateAccountModal(record.accountId, record.userName)}
                icon={<EditOutlined/>}
            />
      },
      width: 20
    },
    {
      title: 'Törlés',
      dataIndex: 'accountDelete',
      render: (_, record) =>
          (record.userName === "ponte.hu.kft" || record.userName === "only.admin" ||
              record.userName === "only.editor" || record.userName === "only.user")
              ?
              null
              :
              <Popconfirm
                  title="Biztosan törlöd ezt a felhasználót?"
                  okText="Törlés"
                  cancelText="Mégsem"
                  onConfirm={() => deleteAccount(record.accountId)}
              >
                {/*<a>Törlés</a>*/}
                <Button className="custom-delete-button" shape="circle" icon={<DeleteOutlined/>}/>
              </Popconfirm>
      ,
      width: 20
    },
  ]);

  const getPPCTypeOptions = () => {
    axios.get("/api/account/ppcType/selectDTOList")
    .then((response) => {
      // console.log("getPPCTypeOptions axios.get response: ", response);
      setOptionsPPCType((response && response.data && Array.isArray(response.data)) ? response.data : []);
    })
    .catch((error) => {
      console.error("getPPCTypeOptions axios.get error: ", error);
    });
  };

  const getRoleOptions = () => {
    axios.get("/api/role/selectDTOList")
    .then((response) => {
      // console.log("getRoleOptions axios.get response: ", response);
      setOptionsRole((response && response.data && Array.isArray(response.data)) ? response.data : []);
    })
    .catch((error) => {
      console.error("getRoleOptions axios.get error: ", error);
    });
  };

  const getCompanyOptions = () => {
    axios.get("/api/company/selectDTOList")
    .then((response) => {
      // console.log("getCompanyOptions axios.get response: ", response);
      setOptionsCompany((response && response.data && Array.isArray(response.data)) ? response.data : []);
    })
    .catch((error) => {
      console.error("getCompanyOptions axios.get error: ", error);
    });
  };

  const getCompanyDataTable = () => {
    setCompanyLoading(true);
    axios.get("/api/company/getAllCompanyDTO")
    .then((response) => {
      // console.log("getCompanyDataTable axios.get response: ", response);
      if (isSessionInvalid(response)) {
        // logoutWhenSessionIsInvalid(setIsLogout);
      } else {
        setRefreshSession(i => i + 1);
        message.success("A cégek listája sikeresen betöltésre került!");
        setCompanyLoading(false);
        setCompanyData((response && response.data && Array.isArray(response.data)) ? response.data : []);
      }
    })
    .catch((error) => {
      console.error("getCompanyDataTable axios.get error: ", error);
      setCompanyLoading(false);
    });
  };

  const getAccountDataTable = () => {
    setAccountLoading(true);
    axios.get("/api/account/getAllAccountDTO")
    .then((response) => {
      // console.log("getAccountDataTable axios.get response: ", response);
      if (isSessionInvalid(response)) {
        // logoutWhenSessionIsInvalid(setIsLogout);
      } else {
        setRefreshSession(i => i + 1);
        message.success("A felhasználók listája sikeresen betöltésre került!");
        setAccountLoading(false);
        setAccountData((response && response.data && Array.isArray(response.data)) ? response.data : []);
      }
    })
    .catch((error) => {
      console.error("getAccountDataTable axios.get error: ", error);
      setAccountLoading(false);
    });
  };

  useEffect(() => {
    getPPCTypeOptions();
    getRoleOptions();
    getCompanyOptions();
    getCompanyDataTable();
    getAccountDataTable();
    // cleanUp function for the unMounted component
    // return () => {
    //   console.log("Admin - useEffect - cleanUp function");
    // }
  }, []);

  // COMPANY METHODS (CREATE and EDIT)
  const openNewCompanyModal = () => {
    setCompanyIdForUpdate(null);
    setCompanyModalVisible(true);
  };

  const openUpdateCompanyModal = (companyId) => {
    setCompanyIdForUpdate(companyId);
    setCompanyModalVisible(true);
  };

  const closeCompanyModal = () => {
    setCompanyIdForUpdate(null);
    setCompanyModalVisible(false);
  };

  const saveCompany = (values) => {
    // console.log("Saved values of companyModalForm: ", values);
    setCompanyIdForUpdate(null);
    setCompanyModalVisible(false);
    getCompanyOptions();
    getCompanyDataTable();
    getAccountDataTable();
  };

  // ACCOUNT METHODS (CREATE and EDIT)
  const openNewAccountModal = () => {
    setAccountIdForUpdate(null);
    setAccountUserName("");
    setAccountModalVisible(true);
  };

  const openUpdateAccountModal = (accountId, userName) => {
    setAccountIdForUpdate(accountId);
    setAccountUserName(userName);
    setAccountModalVisible(true);
  };

  const closeAccountModal = () => {
    setAccountIdForUpdate(null);
    setAccountUserName("");
    setAccountModalVisible(false);
  };

  const saveAccount = (values) => {
    // console.log("Saved values of accountModalForm: ", values);
    setAccountIdForUpdate(null);
    setAccountUserName("");
    setAccountModalVisible(false);
    getCompanyDataTable();
    getAccountDataTable();
  };

  // Only for Account's Password Change
  const openPswdChangeModal = (accountId, userName) => {
    setAccountIdForPasswordUpdate(accountId);
    setAccountUserName(userName);
    setPswdChangeModalVisible(true);
  };

  const closePswdChangeModal = () => {
    setAccountIdForPasswordUpdate(null);
    setAccountUserName("");
    setPswdChangeModalVisible(false);
  };

  const saveNewPswd = (values) => {
    // console.log("Saved values of pswdChangeModalForm: ", values);
    setAccountIdForPasswordUpdate(null);
    setAccountUserName("");
    setPswdChangeModalVisible(false);
  };

  // Delete Account
  const deleteAccount = (accountId) => {
    axios.delete("/api/account/deleteAccount/" + accountId, {data: {}})
    .then((response) => {
      // console.log("deleteAccount axios.delete response: ", response);
      if (isSessionInvalid(response)) {
        logoutWhenSessionIsInvalid(setIsLogout);
      } else {
        setRefreshSession(i => i + 1);
        message.success("A felhasználó törlése sikeresen megtörtént!");
        getCompanyDataTable();
        getAccountDataTable();
      }
    })
    .catch((error) => {
      console.error("deleteAccount axios.delete error: ", error);
      message.error("A felhasználó törlése sikertelen volt!");
    })
  };

  return (
      <Fragment>
        <Divider>Adminisztrátori felület</Divider>

        <Button className="custom-create-button" onClick={() => openNewCompanyModal()} icon={<PlusSquareOutlined/>}>
          Új cég rögzítése
        </Button>

        <FormCompany
            visible={companyModalVisible}
            companyIdForUpdate={companyIdForUpdate}
            onCreate={saveCompany}
            onCancel={closeCompanyModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />

        <Divider/>

        <ConfigProvider locale={huHU}>
          {companyData.length > 0
              ?
              <Table
                  scroll={{y: 550, x: 1000}}
                  columns={companyColumn}
                  dataSource={companyData}
                  loading={companyLoading}
                  rowKey={record => record.companyId}
                  rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total, range) => `Összes találatok száma: ${total}`,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    position: ['bottomCenter']
                  }}
              />
              :
              null
          }
        </ConfigProvider>

        <Divider/>

        <Button className="custom-create-button" onClick={() => openNewAccountModal()} icon={<PlusSquareOutlined/>}>
          Új felhasználó rögzítése
        </Button>

        <FormAccount
            visible={accountModalVisible}
            accountIdForUpdate={accountIdForUpdate}
            accountUserName={accountUserName}
            optionsPPCTypeSelect={optionsPPCType}
            optionsRoleSelect={optionsRole}
            optionsCompanySelect={optionsCompany}
            onCreate={saveAccount}
            onCancel={closeAccountModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />

        <FormPswdChange
            visible={pswdChangeModalVisible}
            accountIdForPasswordUpdate={accountIdForPasswordUpdate}
            accountUserName={accountUserName}
            onCreate={saveNewPswd}
            onCancel={closePswdChangeModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />

        <Divider/>

        <ConfigProvider locale={huHU}>
          {accountData.length > 0
              ?
              <Table
                  scroll={{y: 550, x: 1000}}
                  columns={accountColumn}
                  dataSource={accountData}
                  loading={accountLoading}
                  rowKey={record => record.accountId}
                  rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total, range) => `Összes találatok száma: ${total}`,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    position: ['bottomCenter']
                  }}
              />
              :
              null
          }
        </ConfigProvider>

        <Divider>Statisztika (összesített, cégek szerinti felosztásban)</Divider>

        <Row gutter={[24, 24]}>
          <Col className="gutter-row" span={8}>
            {loadingDataLoginPieChart
                ?
                <Spin tip="Bejelentkezési statisztika betöltése folyamatban van..."/>
                :
                <Chart
                    chartType="PieChart"
                    data={dataLoginPieChart}
                    options={optionsLoginPieChart}
                    width={"100%"}
                    height={"300px"}
                />
            }
          </Col>
          <Col className="gutter-row" span={8}>
            {loadingDataTablePieChart
                ?
                <Spin tip="Táblázatos lekérdezések statisztika betöltése folyamatban van..."/>
                :
                <Chart
                    chartType="PieChart"
                    data={dataTablePieChart}
                    options={optionsTablePieChart}
                    width={"100%"}
                    height={"300px"}
                />
            }
          </Col>
          <Col className="gutter-row" span={8}>
            {loadingDataGanttChartPieChart
                ?
                <Spin tip="GanttChart lekérdezések statisztika betöltése folyamatban van..."/>
                :
                <Chart
                    chartType="PieChart"
                    data={dataGanttChartPieChart}
                    options={optionsGanttChartPieChart}
                    width={"100%"}
                    height={"300px"}
                />
            }
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col className="gutter-row" span={8}>
            {loadingDataProcedureDataExcelPieChart
                ?
                <Spin tip="Eljárás excel lekérdezések statisztika betöltése folyamatban van..."/>
                :
                <Chart
                    chartType="PieChart"
                    data={dataProcedureDataExcelPieChart}
                    options={optionsProcedureDataExcelPieChart}
                    width={"100%"}
                    height={"300px"}
                />
            }
          </Col>
          <Col className="gutter-row" span={8}>
            {loadingDataContractExcelPieChart
                ?
                <Spin tip="Szerződés excel lekérdezések statisztika betöltése folyamatban van..."/>
                :
                <Chart
                    chartType="PieChart"
                    data={dataContractExcelPieChart}
                    options={optionsContractExcelPieChart}
                    width={"100%"}
                    height={"300px"}
                />
            }
          </Col>
        </Row>

        <Divider>Statisztika - Bejelentkezések (részletes, cégek és felhasználók szerinti felosztásban)</Divider>
        <StatisticsDataTable
            url="/api/neak/statistics/details/login"
            statisticsType="details-Login"
        />

        <Divider>Statisztika - Táblázatos lekérdezések (részletes, cégek és felhasználók szerinti felosztásban)</Divider>
        <StatisticsDataTable
            url="/api/neak/statistics/details/table"
            statisticsType="details-Login"
        />

        <Divider>Statisztika - Gantt Chart lekérdezések (részletes, cégek és felhasználók szerinti felosztásban)</Divider>
        <StatisticsDataTable
            url="/api/neak/statistics/details/ganttChart"
            statisticsType="details-Login"
        />

        <Divider>Statisztika - Eljárás excel letöltések (részletes, cégek és felhasználók szerinti felosztásban)</Divider>
        <StatisticsDataTable
            url="/api/neak/statistics/details/procedureDataExcel"
            statisticsType="details-Login"
        />

        <Divider>Statisztika - Szerződés excel letöltések (részletes, cégek és felhasználók szerinti felosztásban)</Divider>
        <StatisticsDataTable
            url="/api/neak/statistics/details/contractExcel"
            statisticsType="details-Login"
        />

      </Fragment>
  );
};

export default Admin;
