import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, InputNumber, Checkbox, Button, Spin, Alert, message} from 'antd';
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const BiddingForm = ({
  visible,
  biddingUpdatePDDID,
  biddingPPDTotalAmount,
  biddingIdForUpdate,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  const successSave = (response) => {
    // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("Az ajánlattétel mentése sikertelen volt!")
    // } else {
    //   message.success("Az ajánlattétel sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("Az ajánlattétel sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("Az ajánlattétel mentése sikertelen volt!")
  };

  const serverSaveProcess = (values) => {
    let saveValues = {
      ...values,
      eljarasReszAdataiId: biddingUpdatePDDID ? biddingUpdatePDDID : null,
      ervenyesEAzAjanlat: values.ervenyesEAzAjanlat ? values.ervenyesEAzAjanlat : false,
      nyertesEAzAjanlat: values.nyertesEAzAjanlat ? values.nyertesEAzAjanlat : false,
    };
    // console.log("saveValues: ", saveValues);
    axios.post("/api/bidding/createBidding", saveValues)
    .then((response) => {
      // console.log("Bidding Form (SAVE) - server saved the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      let ajanlattetelId = (response.data && response.data.ajanlattetelId) ? response.data.ajanlattetelId : null;
      onCreate({...saveValues, ajanlattetelId: ajanlattetelId});
    })
    .catch((error) => {
      console.error("Bidding Form (SAVE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  const serverUpdateProcess = (values) => {
    let updateValues = {
      ...values,
      eljarasReszAdataiId: biddingUpdatePDDID ? biddingUpdatePDDID : null,
      ervenyesEAzAjanlat: values.ervenyesEAzAjanlat ? values.ervenyesEAzAjanlat : false,
      nyertesEAzAjanlat: values.nyertesEAzAjanlat ? values.nyertesEAzAjanlat : false,
    };
    // console.log("updateValues: ", updateValues);
    axios.put("/api/bidding/updateBidding/" + biddingIdForUpdate, updateValues)
    .then((response) => {
      // console.log("Bidding Form (UPDATE) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      let ajanlattetelId = (response.data && response.data.ajanlattetelId) ? response.data.ajanlattetelId : null;
      onCreate({...updateValues, ajanlattetelId: ajanlattetelId});
    })
    .catch((error) => {
      console.error("Bidding Form (UPDATE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  useEffect(() => {
    const findBiddingById = () => {
      if (biddingIdForUpdate) {
        // console.log("findBiddingById: ", biddingIdForUpdate);
        axios.get("/api/bidding/findByIdAndGetDTO/" + biddingIdForUpdate)
        .then((response) => {
          // console.log("findBiddingById axios.get response: ", response);
          if (isSessionInvalid(response)) {
            logoutWhenSessionIsInvalid(setIsLogout)
          } else {
            setRefreshSession(i => i + 1);
            message.success("Az ajánlattétel adatai sikeresen betöltésre kerültek a szerkesztéshez!");
            form.setFieldsValue(response.data);
            setLoadingInitialValues(false);
          }
        })
        .catch((error) => {
          console.error("findBiddingById axios.get error: ", error);
          message.error("Az ajánlattétel szerkesztése sikertelen volt, mert nem található az adatbázisban!");
          form.resetFields();
          setLoadingInitialValues(false);
        })
      }
    };

    if (biddingIdForUpdate) {
      setLoadingInitialValues(true);
      findBiddingById();
      // setTimeout(() => findBiddingById(), 3000);
    }
  }, [biddingIdForUpdate]);

  return (
      <Modal
          visible={visible}
          title={biddingIdForUpdate ? "Ajánlat szerkesztése" : "Új ajánlat rögzítése"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            form.resetFields();
            setServerValidation(null);
            onCancel();
          }}
          cancelButtonProps={{disabled: (loadingInitialValues || saving)}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              if (biddingIdForUpdate) {
                serverUpdateProcess(values);
                // setTimeout(() => serverUpdateProcess(values), 3000);
              } else {
                serverSaveProcess(values);
                // setTimeout(() => serverSaveProcess(values), 3000);
              }
            })
            .catch((error) => {
              console.error("Bidding Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: (loadingInitialValues || saving)}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={loadingInitialValues || saving}>
          <Form
              form={form}
              layout="vertical"
              name="bidding_form_in_modal"
              autoComplete="off"
              // initialValues={}
          >
            <Form.Item
                name="ajanlattevoNeve"
                label="Ajánlattevő neve"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a ajánlattevő nevét!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="ajanlattevoCime"
                label="Ajánlattevő címe"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a ajánlattevő címét!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="ajanlattevoAdoszama"
                label="Ajánlattevő adószáma"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a ajánlattevő adószámát!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="teljesMennyisegBruttoAra"
                label="Teljes mennyiség bruttó ára (5% ÁFA)"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a teljes mennyiség bruttó árát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("teljesMennyisegNettoAra")) {
                    form.setFieldsValue({
                      ["teljesMennyisegBruttoAra"]: (form.getFieldValue("teljesMennyisegNettoAra") * 1.05)
                    });
                  }
                }}
            >
              Számold ki az teljes bruttó árat (Teljes.B.Ár = Teljes.N.Ár * 1.05)
            </Button>
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    onClick={() => {*/}
            {/*      if (form.getFieldValue("alapMennyisegBruttoAra")) {*/}
            {/*        form.setFieldsValue({*/}
            {/*          ["teljesMennyisegBruttoAra"]: (form.getFieldValue("alapMennyisegBruttoAra") * 100) / 66.662*/}
            {/*        });*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Számold ki az teljes bruttó árat (Teljes.B.Ár=(Alap.B.Ár*100)/66.6662)*/}
            {/*</Button>*/}

            <Form.Item
                name="teljesMennyisegNettoAra"
                label="Teljes mennyiség nettó ára"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a teljes mennyiség nettó árát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    onClick={() => {*/}
            {/*      if (form.getFieldValue("alapMennyisegNettoAra")) {*/}
            {/*        form.setFieldsValue({*/}
            {/*          ["teljesMennyisegNettoAra"]: (form.getFieldValue("alapMennyisegNettoAra") * 100) / 66.662*/}
            {/*        });*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Számold ki az teljes nettó árat (Teljes.N.Ár=(Alap.N.Ár*100)/66.6662)*/}
            {/*</Button>*/}

            <Form.Item
                name="alapMennyisegBruttoAra"
                label="Alapmennyiség bruttó ára (5% ÁFA)"
                // rules={[
                //   {
                //     required: true,
                //     message: "Kérjük adja meg az alapmennyiség bruttó árát!"
                //   }
                // ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("alapMennyisegNettoAra")) {
                    form.setFieldsValue({
                      ["alapMennyisegBruttoAra"]: (form.getFieldValue("alapMennyisegNettoAra") * 1.05)
                    });
                  }
                }}
            >
              Számold ki az alap bruttó árat (Alap.B.Ár = Alap.N.Ár * 1.05)
            </Button>
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    onClick={() => {*/}
            {/*      if (form.getFieldValue("teljesMennyisegBruttoAra")) {*/}
            {/*        form.setFieldsValue({*/}
            {/*          ["alapMennyisegBruttoAra"]: (form.getFieldValue("teljesMennyisegBruttoAra") * 66.6662) / 100*/}
            {/*        });*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Számold ki az alap bruttó árat (Alap.B.Ár=(Teljes.B.Ár*66.662)/100)*/}
            {/*</Button>*/}

            <Form.Item
                name="alapMennyisegNettoAra"
                label="Alapmennyiség nettó ára"
                // rules={[
                //   {
                //     required: true,
                //     message: "Kérjük adja meg az alapmennyiség nettó árát!"
                //   }
                // ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    onClick={() => {*/}
            {/*      if (form.getFieldValue("teljesMennyisegNettoAra")) {*/}
            {/*        form.setFieldsValue({*/}
            {/*          ["alapMennyisegNettoAra"]: (form.getFieldValue("teljesMennyisegNettoAra") * 66.6662) / 100*/}
            {/*        });*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Számold ki az alap nettó árat (Alap.N.Ár=(Teljes.N.Ár*66.662)/100)*/}
            {/*</Button>*/}

            <Form.Item
                name="egysegBruttoAra"
                label="Egységmennyiség bruttó ár (5% ÁFA)"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az egységmennyiség bruttó árát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("teljesMennyisegBruttoAra") && biddingPPDTotalAmount) {
                    // console.log("EB - biddingPPDTotalAmount: ", biddingPPDTotalAmount);
                    form.setFieldsValue({
                      ["egysegBruttoAra"]: form.getFieldValue("teljesMennyisegBruttoAra") / biddingPPDTotalAmount
                    });
                  }
                }}
            >
              Számold ki az E.B. árat (E.B.Ár=(TeljesMennyiség.B.Ár/TeljesMennyiség)
            </Button>

            <Form.Item
                name="egysegNettoAra"
                label="Egységmennyiség nettó ár"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az egységmennyiség nettó árát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("teljesMennyisegNettoAra") && biddingPPDTotalAmount) {
                    console.log("BiddingForm - Calc egysegNettoAra: the biddingPPDTotalAmount is: ", biddingPPDTotalAmount);
                    form.setFieldsValue({
                      ["egysegNettoAra"]: form.getFieldValue("teljesMennyisegNettoAra") / biddingPPDTotalAmount
                    });
                  }
                }}
            >
              Számold ki az E.N. árat (E.N.Ár=(TeljesMennyiség.N.Ár/TeljesMennyiség)
            </Button>

            <Form.Item
                name="penznem"
                label="Pénznem"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a pénznemet!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="ervenyesEAzAjanlat"
                valuePropName="checked"
            >
              <Checkbox>Érvényes-e az ajánlat?</Checkbox>
            </Form.Item>

            <Form.Item
                name="nyertesEAzAjanlat"
                valuePropName="checked"
            >
              <Checkbox>Nyertes-e az ajánlat?</Checkbox>
            </Form.Item>

            <Form.Item
                name="alvallalkozo"
                label="Alvállalkozó"
            >
              <Input/>
            </Form.Item>

            <Button onClick={() => {
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );

};

export default BiddingForm;
