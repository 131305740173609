import React, {useState, Fragment} from "react";
import {ConfigProvider, Table} from 'antd';
import huHU from 'antd/es/locale/hu_HU';
import {useGetHTTPMethodWithoutSessionRefresh} from "../../common/HttpMethods";

export const StatisticsDataTable = React.memo(({
  url,
  statisticsType
}) => {

  const [data, loadingData] = useGetHTTPMethodWithoutSessionRefresh
  (
      url,
      [],
      "Statistics - " + statisticsType
  );

  const [columns] = useState([
    {
      title: "Sorcímke",
      dataIndex: "name",
      key: "name",
      width: 100
    },
    {
      title: "01.hó",
      dataIndex: "january2022",
      key: "january2022",
      width: 30
    },
    {
      title: "02.hó",
      dataIndex: "february2022",
      key: "february2022",
      width: 30
    },
    {
      title: "03.hó",
      dataIndex: "march2022",
      key: "march2022",
      width: 30
    },
    {
      title: "04.hó",
      dataIndex: "april2022",
      key: "april2022",
      width: 30
    },
    {
      title: "05.hó",
      dataIndex: "may2022",
      key: "may2022",
      width: 30
    },
    {
      title: "06.hó",
      dataIndex: "june2022",
      key: "june2022",
      width: 30
    },
    {
      title: "07.hó",
      dataIndex: "july2022",
      key: "july2022",
      width: 30
    },
    {
      title: "08.hó",
      dataIndex: "august2022",
      key: "august2022",
      width: 30
    },
    {
      title: "09.hó",
      dataIndex: "september2022",
      key: "september2022",
      width: 30
    },
    {
      title: "10.hó",
      dataIndex: "october2022",
      key: "october2022",
      width: 30
    },
    {
      title: "11.hó",
      dataIndex: "november2022",
      key: "november2022",
      width: 30
    },
    {
      title: "12.hó",
      dataIndex: "december2022",
      key: "december2022",
      width: 30
    },
    {
      title: "2022.év Összeg",
      dataIndex: "yearSum2022",
      key: "yearSum2022",
      width: 30
    },
    {
      title: "Végösszeg",
      dataIndex: "totalSum",
      key: "totalSum",
      width: 35
    },
  ]);

  return (
      <Fragment>
        <ConfigProvider locale={huHU}>
          {data && Array.isArray(data) && data.length > 0
              ?
              <Table
                  className="neak-statistics-table"
                  scroll={{y: 550, x: 1000}}
                  columns={columns}
                  dataSource={data}
                  loading={loadingData}
                  rowKey={record => record.uuid}
                  rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    position: ['bottomCenter']
                  }}
              />
              :
              null
          }
        </ConfigProvider>
      </Fragment>
  );
});
