import {useState, useEffect} from "react";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "./Utils";
import axios from "axios";

/**
 * custom hook for the GET HTTP method with error handling
 * @param url: http URL
 * @param dataInitialValue: initial value of the return data
 * @param loggerText: logger info text
 * @param setIsLogout: function: set the logout
 * @param setRefreshSession: function: refresh the session
 * return: if the status -> error: dataInitialValue and the boolean loadingStatus
 * return: if the status ->    ok: response.data and the boolean loadingStatus
 */
export const useGetHTTPMethod = (url, dataInitialValue, loggerText, setIsLogout, setRefreshSession) => {
  const [data, setData] = useState(dataInitialValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);
        const response = await axios.get(url);
        // console.log("useGetFetch - " + loggerText + " - response: ", response);
        if (isSessionInvalid(response)) {
          // logoutWhenSessionIsInvalid(setIsLogout);
        } else if (response.status === 200) {
          setRefreshSession(i => i + 1);
          setData(response.data);
        } else {
          setData(null);
        }
      } catch (error) {
        console.error("useGetFetch - " + loggerText + " - error: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url, loggerText, setIsLogout]);

  return [data, loading];
};

export const useGetHTTPMethodWithSetter = (url, dataInitialValue, loggerText, setIsLogout, setRefreshSession) => {
  const [data, setData] = useState(dataInitialValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);
        const response = await axios.get(url);
        // console.log("useGetFetch - " + loggerText + " - response: ", response);
        if (isSessionInvalid(response)) {
          // logoutWhenSessionIsInvalid(setIsLogout);
        } else if (response.status === 200) {
          setRefreshSession(i => i + 1);
          setData(response.data);
        } else {
          setData(null);
        }
      } catch (error) {
        console.error("useGetFetch - " + loggerText + " - error: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url, loggerText, setIsLogout]);

  return [data, setData, loading];
};

export const useGetHTTPMethodWithoutSessionRefresh = (url, dataInitialValue, loggerText) => {
  const [data, setData] = useState(dataInitialValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);
        const response = await axios.get(url);
        // console.log("useGetFetch - " + loggerText + " - response: ", response);
        if (isSessionInvalid(response)) {
          // logoutWhenSessionIsInvalid(setIsLogout);
        } else if (response.status === 200) {
          setData(response.data);
        } else {
          setData(null);
        }
      } catch (error) {
        console.error("useGetFetch - " + loggerText + " - error: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url, loggerText]);

  return [data, loading];
};
