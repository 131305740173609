import React, {useState, useRef, useEffect} from "react";
import {useInput, useInputSwitch} from "../../common/InputHooks";
import 'moment/locale/hu';
import * as moment from "moment";
import {Input, DatePicker, Button, Alert, Space, Row, Col, Divider, Collapse, Spin, Popconfirm, message} from 'antd';
import {TableOutlined, AreaChartOutlined, FileExcelOutlined, RestOutlined, SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import locale from "antd/es/date-picker/locale/hu_HU";
import {Gantt} from "./Gantt";
import {ProcedureDataTable} from "./ProcedureDataTable";
import {SuggestBox} from "../../common/SuggestBox";
import {AutoCompleteSelectBox} from "../../common/AutoCompleteSelectBox";
import {CustomSwitch} from "../../common/CustomSwitch";
import {initializeFilterPostBody, initializeGanttChartConfig, isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import {useGetHTTPMethod, useGetHTTPMethodWithSetter} from "../../common/HttpMethods";
import {CustomTooltip} from "../../common/CustomTooltip";
import axios from "axios";

const InfoKeresesiParameterek =
    <>
      <p>Lekérdezések során megjeleníthetjük az adatbázisban található teljes adatmennyiséget, ilyenkor hagyjuk üresen az összes keresési mezőt.</p>
      <p>Azonban arra is van lehetőségünk, hogy valamilyen szempont alapján szűrjük az adatokat. Ehhez töltsük ki az igényeinknek megfelelően a keresési mezőket.</p>
      <p>Miután beállítottuk a szűrési feltételeket, használjuk a Táblázat, Gantt Chart vagy az Excel gombokat a találatok kívánt struktúrában történő megjelenítésért.</p>
      <p>Az Alaphelyzet gomb segítségével az összes keresési paramétert, az aktuálisan kiválasztott saját lekérdezést, illetve a devizaváltó adatait is együttesen törölhetjük.</p>
    </>;

const InfoMentettLekerdezesek =
    <>
      <p>A gyakran használt szűrési paramétereket elmenthetjük. Így a későbbiekben nem szükséges az összetett keresési feltételeket ismételten összeállítani.</p>
      <p>Kiválaszthatjuk egy legördülő listában a már elmentett lekérdezéseket, s így azonnal betöltődnek a keresési paraméterek.</p>
      <p>Továbbá a már elmentett lekérdezéseket módosíthatjuk vagy törölhetjük is.</p>
    </>;

const InfoDevizaValto =
    <>
      <p>A hatóanyag beszerzésekhez megkötött szerződésekben rögzített árakat megjeleníthetjük más devizanemekben is.</p>
      <p>A sikeres átváltáshoz mind a pénznemet, mind pedig az átváltási árfolyamot (egységnyi deviza értéke) meg kell adnunk. Pl. EUR, 358.71 (Fontos
        hogy a tizedesjegyeket ponttal válasszuk el)</p>
    </>;

const InfoOszlopValaszto =
    <>
      <p>Az adatok tetszőleges megjelenítésére biztosít lehetőséget az oszlop választó felület.</p>
      <p>A megjelenő csúszkák ki- és bekapcsolásával állíthatjuk, hogy mely adatokat tartalmazó oszlopokat szeretnénk megjeleníteni a táblázatban.</p>
    </>;

const {Panel} = Collapse;

const Search = React.memo(({setIsLogout, setRefreshSession, demoMode}) => {

  // Filter-Fields
  // SuggestBox and SinlgeSelect
  const [oenoKod, setOenoKod] = useState(null);
  const [oenoKodInputQuery, setOenoKodInputQuery] = useState("");
  const selectOenoKodRef = useRef(null);
  const [optionsOenoKod, loadingOptionsOenoKod] = useGetHTTPMethod
  (
      "/api/procedureDataDetails/oenocode/selectDTOList",
      [],
      "suggestBox - getOENOCodeOptions",
      setIsLogout,
      setRefreshSession
  );

  // AutoCompleteSelectBox (MULTI-SELECT)
  const [hatoanyagNeve, setHatoanyagNeve] = useState(null);
  const selectHatoanyagNeveRef = useRef(null);
  const [optionsHatoanyagNeve, loadingOptionsHatoanyagNeve] = useGetHTTPMethod
  (
      "/api/activeIngredient/selectDTOList",
      [],
      "suggestBox - getHatoanyagNeveOptions",
      setIsLogout,
      setRefreshSession
  );

  // SuggestBox and SinlgeSelect
  const [termekNeve, setTermekNeve] = useState(null);
  const [termekNeveInputQuery, setTermekNeveInputQuery] = useState("");
  const selectTermekNeveRef = useRef(null);
  const [optionsTermekNeve, loadingOptionsTermekNeve] = useGetHTTPMethod
  (
      "/api/contract/product/selectDTOList",
      [],
      "suggestBox - getTermekNeveOptions",
      setIsLogout,
      setRefreshSession
  );

  // SuggestBox and SinlgeSelect
  const [gyartoNeve, setGyartoNeve] = useState(null);
  const [gyartoNeveInputQuery, setGyartoNeveInputQuery] = useState("");
  const selectGyartoNeveRef = useRef(null);
  const [optionsGyartoNeve, loadingOptionsGyartoNeve] = useGetHTTPMethod
  (
      "/api/contract/manufacturer/selectDTOList",
      [],
      "suggestBox - getGyartoNeveOptions",
      setIsLogout,
      setRefreshSession
  );

  // DatePicker
  const [keletkezesKezdetiDatuma, setKeletkezesKezdetiDatuma] = useState("");
  const [keletkezesVegDatuma, setKeletkezesVegDatuma] = useState("");

  // personal queries
  const [personalQueries, setPersonalQueries, loadingPersonalQueries] = useGetHTTPMethodWithSetter
  (
      "/api/personalQuery/getAllPersonalQueryByUserName",
      [],
      "get all personalQueries",
      setIsLogout,
      setRefreshSession
  );
  // personal queries - AutoCompleteSelectBox (SINGLE-SELECT)
  const [PQ, setPQ] = useState(null);
  const selectPQRef = useRef(null);
  const [PQOptions, setPQOptions] = useState([]);
  // personal query save name input field; we use this parameter to delete the personalQuery by name
  const [personalQuerySaveName, setPersonalQuerySaveName, handlePersonalQuerySaveName] = useInput("");

  // currency converter
  const [penznemKonvert, setPenznemKonvert, handlePenznemKonvert] = useInput("");
  const [penzValtoSzamKonvert, setPenzValtoSzamKonvert, handlePenzValtoSzamKonvert] = useInput("");

  // ProcedureDataTable
  const showProcedureDataTable = useRef(false);

  // ProcedureDataTable - hidden columns handling
  const [ekr, handleEkr] = useInputSwitch(true);
  const [targya, handleTargya] = useInputSwitch(true);
  const [rendje, handleRendje] = useInputSwitch(true);
  const [fajtaja, handleFajtaja] = useInputSwitch(true);
  const [iktatoszam, handleIktatoszam] = useInputSwitch(false);
  const [kozzetetel, handleKozzetetel] = useInputSwitch(true);

  const [reszAdataiSorszam, handleReszAdataiSorszam] = useInputSwitch(true);
  const [oeno, handleOeno] = useInputSwitch(true);
  const [hatoanyag, handleHatoanyag] = useInputSwitch(true);
  const [osszmennyiseg, handleOsszmennyiseg] = useInputSwitch(true);
  const [alapmennyiseg, handleAlapmennyiseg] = useInputSwitch(true);
  const [opciosmennyiseg, handleOpciosmennyiseg] = useInputSwitch(true);

  const [mertek, handleMertek] = useInputSwitch(true);
  const [arurabatt, handleArurabatt] = useInputSwitch(false);
  const [aktiv, handleAktiv] = useInputSwitch(true);
  const [passziv, handlePassziv] = useInputSwitch(true);
  const [hosszabithatosag, handleHosszabithatosag] = useInputSwitch(true);
  const [reszvetel, handleReszvetel] = useInputSwitch(true);

  const [ajanlattetel, handleAjanlattetel] = useInputSwitch(true);
  const [elbiralas, handleElbiralas] = useInputSwitch(true);
  const [kMinoseg, handleKMinoseg] = useInputSwitch(true);
  const [kKoltseg, handleKKoltseg] = useInputSwitch(true);
  const [kAr, handleKAr] = useInputSwitch(true);
  const [ajanlatTNeve, handleAjanlatTNeve] = useInputSwitch(true);

  const [ajanlatTCime, handleAjanalatTCime] = useInputSwitch(false);
  const [ajanlatTAdoszam, handleAjanlatTAdoszam] = useInputSwitch(false);
  const [nemNyertesek, handleNemNyertesek] = useInputSwitch(true);
  const [alCeg, handleAlCeg] = useInputSwitch(false);
  const [termek, handleTermek] = useInputSwitch(true);
  const [gyarto, handleGyarto] = useInputSwitch(true);

  const [keletkezes, handleKeletkezes] = useInputSwitch(true);
  const [teljesitesKezd, handleTeljesitesKezd] = useInputSwitch(true);
  const [teljesitesVeg, handleTeljesitesVeg] = useInputSwitch(true);
  const [hosszabitas, handleHosszabitas] = useInputSwitch(true);
  const [teljesBruttoAr, handleTeljesBruttoAr] = useInputSwitch(false);
  const [teljesNettoAr, handleTeljesNettoAr] = useInputSwitch(true);

  const [alapBruttoAr, handleAlapBruttoAr] = useInputSwitch(false);
  const [alapNettoAr, handleAlapNettoAr] = useInputSwitch(true);
  const [egysegBruttoAr, handleEgysegBruttoAr] = useInputSwitch(false);
  const [egysegNettoAr, handleEgysegNettoAr] = useInputSwitch(true);
  const [dddErtek, handleDddErtek] = useInputSwitch(true);
  const [ddd1NapiKoltsegErtek, handleDdd1NapiKoltsegErtek] = useInputSwitch(true);

  const [ddd28NaposKoltsegErtek, handleDdd28NaposKoltsegErtek] = useInputSwitch(true);
  const [dddAtlagosHaviKoltsegErtek, handleDddAtlagosHaviKoltsegErtek] = useInputSwitch(true);
  const [dddEvesKoltsegErtek, handleDddEvesKoltsegErtek] = useInputSwitch(true);
  const [penz, handlePenz] = useInputSwitch(true);
  const [eljarasEKRWebLink, handleEljarasEKRWebLink] = useInputSwitch(true);
  const [szerzodesEKRWebLink, handleSzerzodesEKRWebLink] = useInputSwitch(true);

  const [szerzodesKozBeszWebLink, handleSzerzodesKozBeszWebLink] = useInputSwitch(true);

  // Filter-Sort-Page Object for the POST HTTP Method when you want to search procedureDataTable
  const [filterPostBodyProcedureDataTable, setFilterPostBodyProcedureDataTable] = useState(
      initializeFilterPostBody(10, 1, null, false, null, null, null, null, null,null, null, null, false));

  // GantChart
  const [ganttChartConfig, setGanttChartConfig] = useState(initializeGanttChartConfig([], "", "", false));
  const [loadingGanttChartData, setLoadingGanttChartData] = useState(false);
  const showGanttChart = useRef(false);

  // Filter Object for the POST HTTP Method when you want to search gantt-chart
  const [filterPostBodyGanttChart, setFilterPostBodyGanttChart] = useState(
      initializeFilterPostBody(10, 1, null, false, null, null, null, null, null, null, null, null, false));

  // Calculate and Set PersonalQueries Single-Select Options
  useEffect(() => {
    const pqArray = [];
    if (personalQueries && Array.isArray(personalQueries) && personalQueries.length !== 0) {
      personalQueries.forEach(item =>
          pqArray.push(
              {
                value: item.personalQueryName.substring(item.personalQueryName.indexOf("_@_") + 3),
                label: item.personalQueryName.substring(item.personalQueryName.indexOf("_@_") + 3)
              }
          )
      );
    }
    setPQOptions(pqArray);
  }, [personalQueries]);

  // Set SearchFields when the PersonalQuery is selected
  useEffect(() => {
    // console.log("PQ selected:", PQ);
    // console.log("All personalQueries Array:", personalQueries);

    let personalQuery;
    if (PQ && PQ.label) {
      // personalQueryName is always UNIQUE in the database
      personalQuery = personalQueries.filter(item => item.personalQueryName.includes(PQ.label))[0];
      setPersonalQuerySaveName(PQ.label);
    }
    // console.log("personalQuery:", personalQuery);

    if (personalQuery) {
      if (personalQuery.oenoCode) {
        setOenoKodInputQuery(personalQuery.oenoCode);
        setOenoKod({value: personalQuery.oenoCode, label: personalQuery.oenoCode});
      } else {
        setOenoKodInputQuery("");
        setOenoKod(null);
      }
      if (personalQuery.productName) {
        setTermekNeveInputQuery(personalQuery.productName);
        setTermekNeve({value: personalQuery.productName, label: personalQuery.productName});
      } else {
        setTermekNeveInputQuery("");
        setTermekNeve(null);
      }
      if (personalQuery.manufacturerName) {
        setGyartoNeveInputQuery(personalQuery.manufacturerName);
        setGyartoNeve({value: personalQuery.manufacturerName, label: personalQuery.manufacturerName});
      } else {
        setGyartoNeveInputQuery("");
        setGyartoNeve(null);
      }
      if (personalQuery.activeIngredients) {
        let pqArrayTemp = personalQuery.activeIngredients.split("&AIName=");
        let pqArray = [];
        pqArrayTemp.forEach(item => pqArray.push({value: item, label: item}));
        setHatoanyagNeve(pqArray);
      } else {
        setHatoanyagNeve(null);
      }
      if (personalQuery.fromContract) {
        setKeletkezesKezdetiDatuma(moment(personalQuery.fromContract));
      } else {
        setKeletkezesKezdetiDatuma(null);
      }
      if (personalQuery.toContract) {
        setKeletkezesVegDatuma(moment(personalQuery.toContract));
      } else {
        setKeletkezesVegDatuma(null);
      }
    }
  }, [PQ]);

  // Search Gantt-Chart data - AXIOS.
  useEffect(() => {
    const getGanttChartData = () => {
      setLoadingGanttChartData(true);
      setGanttChartConfig(initializeGanttChartConfig([], "", "", false));
      axios.post("/api/procedureDataDetails/getGanttChart", filterPostBodyGanttChart)
      .then((response) => {
        // console.log("getGanttChartData - axios.post response: ", response);
        if (isSessionInvalid(response)) {
          logoutWhenSessionIsInvalid(setIsLogout);
        } else {
          setRefreshSession(i => i + 1);
          setLoadingGanttChartData(false);
          let currencyGender = penznemKonvert ? penznemKonvert : "";
          let currencyValue = parseFloat(penzValtoSzamKonvert) ? parseFloat(penzValtoSzamKonvert) : "";
          setGanttChartConfig(initializeGanttChartConfig(response.data, currencyGender, currencyValue, !!demoMode));
        }
      })
      .catch((error) => {
        setLoadingGanttChartData(false);
        console.error("getGanttChartData - axios.post error: ", error);
      });
    };

    if (showGanttChart.current) {
      getGanttChartData();
    }
  }, [filterPostBodyGanttChart]);

  // Search Gantt-Chart data: Server will ignore the pageSize, pageNum and orderBy parameters, it will process only the filter parameters.
  const searchGanttChart = () => {
    let oenoKodValue = (oenoKod && oenoKod.label) ? oenoKod.label : null;
    let hatoanyagNeveValue = handleMultiSelectValues(hatoanyagNeve);
    let termekNeveValue = (termekNeve && termekNeve.label) ? termekNeve.label : null;
    let gyartoNeveValue = (gyartoNeve && gyartoNeve.label) ? gyartoNeve.label : null;
    let keletkezesKezdetiDatumaValue = keletkezesKezdetiDatuma ? keletkezesKezdetiDatuma._d.format("yyyy-mm-dd") : null;
    let keletkezesVegDatumaValue = keletkezesVegDatuma ? keletkezesVegDatuma._d.format("yyyy-mm-dd") : null;
    let currencyGender = penznemKonvert ? penznemKonvert : null;
    let currencyValue = parseFloat(penzValtoSzamKonvert) ? parseFloat(penzValtoSzamKonvert) : null;
    let isDemo = !!demoMode;

    showGanttChart.current = true;
    showProcedureDataTable.current = false;

    setFilterPostBodyGanttChart(
        initializeFilterPostBody(10, 1, null, false,
            oenoKodValue, hatoanyagNeveValue, termekNeveValue, gyartoNeveValue,
            keletkezesKezdetiDatumaValue, keletkezesVegDatumaValue,
            currencyGender, currencyValue, isDemo)
    );
  };

  // Render Gantt-Chart component.
  const renderGanttChart = (show, loading, config) => {
    if (!show) {
      return null;
    } else {
      if (loading) {
        return <Spin tip="Gantt Chart adatok betöltése folyamatban van..."/>
      }
      if (!loading && config.data.resources.data.length === 0) {
        return <Alert message="A keresés nem hozott eredményt a Gantt Chart-on! Kérjük ajdon meg más szűrési feltételeket." type="info" showIcon/>
      }
      if (!loading && config.data.resources.data.length !== 0) {
        return <Gantt config={config}/>
      }
    }
  };

  // Search ProcedureDataTable: Server will receive the pageSize, pageNum, orderBy and filter parameters.
  const searchProcedureDataTable = () => {
    let oenoKodValue = (oenoKod && oenoKod.label) ? oenoKod.label : null;
    let hatoanyagNeveValue = handleMultiSelectValues(hatoanyagNeve);
    let termekNeveValue = (termekNeve && termekNeve.label) ? termekNeve.label : null;
    let gyartoNeveValue = (gyartoNeve && gyartoNeve.label) ? gyartoNeve.label : null;
    let keletkezesKezdetiDatumaValue = keletkezesKezdetiDatuma ? keletkezesKezdetiDatuma._d.format("yyyy-mm-dd") : null;
    let keletkezesVegDatumaValue = keletkezesVegDatuma ? keletkezesVegDatuma._d.format("yyyy-mm-dd") : null;
    let currencyGender = penznemKonvert ? penznemKonvert : null;
    let currencyValue = parseFloat(penzValtoSzamKonvert) ? parseFloat(penzValtoSzamKonvert) : null;
    let isDemo = !!demoMode;

    showGanttChart.current = false;
    showProcedureDataTable.current = true;

    setFilterPostBodyProcedureDataTable(
        initializeFilterPostBody(10, 1, null, false,
            oenoKodValue, hatoanyagNeveValue, termekNeveValue, gyartoNeveValue,
            keletkezesKezdetiDatumaValue, keletkezesVegDatumaValue,
            currencyGender, currencyValue, isDemo)
    );
  };

  const exportTableToEXCEL = () => {
    let oenoKodValue = (oenoKod && oenoKod.label) ? oenoKod.label : null;
    let hatoanyagNeveValue = handleMultiSelectValues(hatoanyagNeve);
    let termekNeveValue = (termekNeve && termekNeve.label) ? termekNeve.label : null;
    let gyartoNeveValue = (gyartoNeve && gyartoNeve.label) ? gyartoNeve.label : null;
    let keletkezesKezdetiDatumaValue = keletkezesKezdetiDatuma ? keletkezesKezdetiDatuma._d.format("yyyy-mm-dd") : null;
    let keletkezesVegDatumaValue = keletkezesVegDatuma ? keletkezesVegDatuma._d.format("yyyy-mm-dd") : null;
    let currencyGender = penznemKonvert ? penznemKonvert : null;
    let currencyValue = parseFloat(penzValtoSzamKonvert) ? parseFloat(penzValtoSzamKonvert) : null;
    let isDemo = !!demoMode;

    let filterPostBody = initializeFilterPostBody(10, 1, 'EKR_ID', false,
        oenoKodValue, hatoanyagNeveValue, termekNeveValue, gyartoNeveValue,
        keletkezesKezdetiDatumaValue, keletkezesVegDatumaValue,
        currencyGender, currencyValue, isDemo);

    axios.post("/api/procedureData/generateExcel", filterPostBody)
    .then((response) => {
      // console.log("generateExcel (procedureData) - axios.post response: ", response);
      if (isSessionInvalid(response)) {
        logoutWhenSessionIsInvalid(setIsLogout);
      } else {
        setRefreshSession(i => i + 1);
        let downloadURL = "/api/procedureData/downloadExcel?excelId=" + response.data;

        let tempDate = new Date(new Date().toString().split("GMT")[0] + " UTC")
        .toISOString()
        .split(".")[0]
        .replace("T", " ");

        let a = document.createElement("a");
        a.href = downloadURL;
        a.download = ("PPC_Eljaras_" + tempDate + ".xlsx").replaceAll(":", "_");
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
    .catch((error) => {
      console.error("generateExcel (procedureData) - axios.post error: ", error);
    });
  };

  // Save PersonalQuery
  const savePersonalQuery = () => {
    let personalQueryName = personalQuerySaveName ? personalQuerySaveName : "névtelen";
    let oenoCode = (oenoKod && oenoKod.label) ? oenoKod.label : null;
    let activeIngredients =  handleMultiSelectValues(hatoanyagNeve);
    let productName = (termekNeve && termekNeve.label) ? termekNeve.label : null;
    let manufacturerName = (gyartoNeve && gyartoNeve.label) ? gyartoNeve.label : null;
    let fromContract = keletkezesKezdetiDatuma ? keletkezesKezdetiDatuma._d.format("yyyy-mm-dd") : null;
    let toContract = keletkezesVegDatuma ? keletkezesVegDatuma._d.format("yyyy-mm-dd") : null;

    let saveObject = {
      personalQueryName: personalQueryName,
      oenoCode: oenoCode,
      activeIngredients: activeIngredients,
      productName: productName,
      manufacturerName: manufacturerName,
      fromContract: fromContract,
      toContract: toContract
    };

    axios.post("/api/personalQuery/createPersonalQuery", saveObject)
    .then((response)=>{
      // console.log("createPersonalQuery - axios.post response: ", response);
      if (isSessionInvalid(response)) {
        logoutWhenSessionIsInvalid(setIsLogout);
      } else {
        setRefreshSession(i => i + 1);
        setPQ({value: personalQueryName, label: personalQueryName});
        getAllPersonalQueries();
        message.success("A lekérdezés (" + personalQueryName + ") sikeresen mentve lett.");
      }
    })
    .catch((error) => {
      console.error("createPersonalQuery - axios.post error: ", error);
      message.error("A lekérdezés (" + personalQueryName + ") mentése sikertelen volt.");
    });
  };

  // Delete PersonalQuery
  const deletePersonalQuery = () => {
    if (personalQuerySaveName) {
      axios.delete("/api/personalQuery/deletePersonalQuery/" + personalQuerySaveName)
      .then((response) => {
        // console.log("deletePersonalQuery - axios.delete response: ", response);
        if (isSessionInvalid(response)) {
          logoutWhenSessionIsInvalid(setIsLogout);
        } else {
          setRefreshSession(i => i + 1);
          selectPQRef.current.select.clearValue();
          setPersonalQuerySaveName("");
          getAllPersonalQueries();
          message.success("A lekérdezés törlése sikeresen megtörtént.");
        }
      })
      .catch((error) => {
        console.error("deletePersonalQuery - axios.delete error: ", error);
        message.error("A lekérdezés törlése sikertelen volt.");
      });
    }
  };

  // Get all PersonalQueries
  const getAllPersonalQueries = () => {
    axios.get("/api/personalQuery/getAllPersonalQueryByUserName")
    .then((response) => {
      // console.log("getAllPersonalQueryByUserName - axios.get response: ", response);
      if (isSessionInvalid(response)) {
        logoutWhenSessionIsInvalid(setIsLogout);
      } else if (response.status === 200) {
        setRefreshSession(i => i + 1);
        setPersonalQueries(response.data);
        message.success("A felhasználóhoz tartozó lekérdezések sikeresen frissültek a kiválasztó mezőben.");
      } else {
        setPersonalQueries([]);
      }
    })
    .catch((error) => {
      console.error("getAllPersonalQueryByUserName - axios.get error: ", error);
      message.error("A felhasználóhoz tartozó lekérdezések nem frissültek a kiválasztó mezőben.");
    });
  };

  // Handle Save Or Update PersonalQuery
  const saveOrUpdatePersonalQuery = (personalQueryName) => {
    if (personalQueryName && personalQueries && Array.isArray(personalQueries) && personalQueries.length !== 0) {
      let pq = personalQueries.filter(item => item.personalQueryName.includes(personalQueryName))[0];
      if (pq) {
        return "Ezen a néven (" + personalQueryName + ") már létezik lekérdezés. Felülírja?";
      }
    }
    return "Biztosan menti ezt az új lekérdezést?";
  };

  // Empty the value of the filter fields.
  const resetFilterFields = () => {
    selectOenoKodRef.current.select.clearValue();
    selectHatoanyagNeveRef.current.select.clearValue();
    selectTermekNeveRef.current.select.clearValue();
    selectGyartoNeveRef.current.select.clearValue();
    setKeletkezesKezdetiDatuma("");
    setKeletkezesVegDatuma("");
    // when "Lekérdezések" page loads for the first time the "Mentett Lekérdezések" section is collapsed --> so the selectPQRef.current is null
    // OR use this to reset "Mentett lekérdezések Single-Select Field": setPQ(null);
    if (selectPQRef.current && selectPQRef.current.select) {
      selectPQRef.current.select.clearValue();
    }
    setPersonalQuerySaveName("");
    setOenoKodInputQuery("");
    setTermekNeveInputQuery("");
    setGyartoNeveInputQuery("");
    setPenznemKonvert("");
    setPenzValtoSzamKonvert("");

    setFilterPostBodyGanttChart(initializeFilterPostBody(10, 1, null, false, null, null, null, null,null, null, null, null, !!demoMode));

    setFilterPostBodyProcedureDataTable(initializeFilterPostBody(10, 1, null, false, null, null, null, null,null, null, null, null, !!demoMode));
  };

  // handle MULTI-SELECT activeIngredient
  const handleMultiSelectValues = (selectArray) => {
    let result = "";
    if (selectArray && Array.isArray(selectArray) && selectArray.length !== 0) {
      selectArray.forEach(item => result += "&AIName=" + item.label)
    } else {
      result = null;
    }
    if (result) {
      result = result.substring(8);
    }
    return result;
  };

  return (
      <div>

        <div>
          <Collapse defaultActiveKey={["searchParameters"]} className="custom-collapse">
            <Panel
                header={
                  <>
                    Keresési paraméterek
                    <CustomTooltip tooltipTitle={InfoKeresesiParameterek}/>
                  </>
                }
                key="searchParameters"
            >
              <Row gutter={[24, 24]}>
                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="oenoKod">
                      OENO / ATC kód
                    </label>
                    <div>
                      {loadingOptionsOenoKod
                          ?
                          <Spin tip="OENO / ATC kódok betöltése folyamatban van..."/>
                          :
                          <SuggestBox
                              nameAndClassAttr="oenoKod"
                              placeholder="Válasszon OENO / ATC kódot!"
                              selectFilterFieldRef={selectOenoKodRef}
                              filterField={oenoKod}
                              setFilterField={setOenoKod}
                              optionsArray={optionsOenoKod}
                              initInputQuery={oenoKodInputQuery}
                          />
                      }
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="hatoanyagNeve">
                      Hatóanyag neve
                    </label>
                    <div>
                      {loadingOptionsHatoanyagNeve
                          ?
                          <Spin tip="Hatóanyag nevek betöltése folyamatban van..."/>
                          :
                          <AutoCompleteSelectBox
                              nameAndClassAttr="hatoanyagNeve"
                              placeholder="Válasszon hatóanyagot!"
                              selectFilterFieldRef={selectHatoanyagNeveRef}
                              filterField={hatoanyagNeve}
                              setFilterField={setHatoanyagNeve}
                              optionsArray={optionsHatoanyagNeve}
                              isMultiSelect={true}
                              isClearable={true}
                          />
                      }
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="keletkezesKezdetiDatuma">
                      Szerződés kelte (-tól)
                    </label>
                    <div>
                      <DatePicker
                          id="keletkezesKezdetiDatuma"
                          name="keletkezesKezdetiDatuma"
                          placeholder="Keletkezés kezdete."
                          format="YYYY.MM.DD"
                          locale={locale}
                          value={keletkezesKezdetiDatuma}
                          onChange={date => setKeletkezesKezdetiDatuma(date)}
                          allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row gutter={[24, 24]}>
                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="termekNeve">
                      Termék neve
                    </label>
                    <div>
                      {loadingOptionsTermekNeve
                          ?
                          <Spin tip="Termék nevek betöltése folyamatban van..."/>
                          :
                          <SuggestBox
                              nameAndClassAttr="termekNeve"
                              placeholder="Válasszon terméket!"
                              selectFilterFieldRef={selectTermekNeveRef}
                              filterField={termekNeve}
                              setFilterField={setTermekNeve}
                              optionsArray={optionsTermekNeve}
                              initInputQuery={termekNeveInputQuery}
                          />
                      }
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="gyartoNeve">
                      Gyártó neve
                    </label>
                    <div>
                      {loadingOptionsGyartoNeve
                          ?
                          <Spin tip="Gyártó nevek betöltése folyamatban van..."/>
                          :
                          <SuggestBox
                              nameAndClassAttr="gyartoNeve"
                              placeholder="Válasszon gyártót!"
                              selectFilterFieldRef={selectGyartoNeveRef}
                              filterField={gyartoNeve}
                              setFilterField={setGyartoNeve}
                              optionsArray={optionsGyartoNeve}
                              initInputQuery={gyartoNeveInputQuery}
                          />
                      }
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="keletkezesVegDatuma">
                      Szerződés kelte (-ig)
                    </label>
                    <div>
                      <DatePicker
                          id="keletkezesVegDatuma"
                          name="keletkezesVegDatuma"
                          placeholder="Keletkezés vége."
                          format="YYYY.MM.DD"
                          locale={locale}
                          value={keletkezesVegDatuma}
                          onChange={date => setKeletkezesVegDatuma(date)}
                          allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Collapse className="custom-collapse">
            <Panel
                header={
                  <>
                    Mentett lekérdezések
                    <CustomTooltip tooltipTitle={InfoMentettLekerdezesek}/>
                  </>
                }
                key="savedPersonalQuery"
            >
              <Row gutter={[24, 24]}>
                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="PQ">
                      Lekérdezés választó
                    </label>
                    <div>
                      {loadingPersonalQueries
                          ?
                          <Spin tip="Mentett lekérdezések betöltése folyamatban van..."/>
                          :
                          <AutoCompleteSelectBox
                              nameAndClassAttr="PQ"
                              placeholder="Válasszon lekérdezést!"
                              selectFilterFieldRef={selectPQRef}
                              filterField={PQ}
                              setFilterField={setPQ}
                              optionsArray={PQOptions}
                              isMultiSelect={false}
                              // it's important to disable the clearable function, use the "Alaphelyzet button" only, instead of the clearable function
                              isClearable={false}
                          />
                      }
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="personalQuerySaveName">
                      Lekérdezés neve (mentés/törlés)
                    </label>
                    <div>
                      <Input
                          id="personalQuerySaveName"
                          name="personalQuerySaveName"
                          placeholder='Milyen néven mentsünk/töröljünk?'
                          value={personalQuerySaveName}
                          onChange={handlePersonalQuerySaveName}
                          allowClear
                      />
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="personal-queries">
                    <Popconfirm
                        title={() => saveOrUpdatePersonalQuery(personalQuerySaveName)}
                        okText="Mentés"
                        cancelText="Mégsem"
                        disabled={!personalQuerySaveName}
                        onConfirm={()=> savePersonalQuery()}
                    >
                      <Button
                          className="custom-pq-save-button"
                          shape="circle"
                          icon={<SaveOutlined/>}
                          disabled={!personalQuerySaveName}
                      />
                    </Popconfirm>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Popconfirm
                        title="Biztosan törli ezt a lekérdezést?"
                        okText="Törlés"
                        cancelText="Mégsem"
                        disabled={!personalQuerySaveName}
                        onConfirm={()=> deletePersonalQuery()}
                    >
                      <Button
                          className="custom-pq-delete-button"
                          shape="circle"
                          icon={<DeleteOutlined/>}
                          disabled={!personalQuerySaveName}
                      />
                    </Popconfirm>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Collapse className="custom-collapse">
            <Panel
                header={
                  <>
                    Devizaváltó
                    <CustomTooltip tooltipTitle={InfoDevizaValto}/>
                  </>
                }
                key="currencyConverter"
            >
              <Row gutter={[24, 24]}>
                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="penznemKonvert">
                      Pénznem
                    </label>
                    <div>
                      <Input
                          id="penznemKonvert"
                          name="penznemKonvert"
                          placeholder='Adja meg a pénz nemét!'
                          value={penznemKonvert}
                          onChange={handlePenznemKonvert}
                          allowClear
                      />
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="query-field">
                    <label htmlFor="penzValtoSzamKonvert">
                      Egységnyi deviza értéke (HUF)
                    </label>
                    <div>
                      <Input
                          id="penzValtoSzamKonvert"
                          name="penzValtoSzamKonvert"
                          placeholder='Adja meg az 1 egységnyi deviza HUF értéket!'
                          value={penzValtoSzamKonvert}
                          onChange={handlePenzValtoSzamKonvert}
                          allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>

        <Divider/>

        <Space>
          <Button type="primary" size="large" style={{background: "#0077b5", borderColor: "#0077b5"}}
                  onClick={() => searchProcedureDataTable()} icon={<TableOutlined/>}>
            Táblázat
          </Button>

          <Button type="primary" size="large" style={{background: "#dd5143", borderColor: "#dd5143"}}
                  onClick={() => searchGanttChart()} icon={<AreaChartOutlined/>}>
            Gantt Chart
          </Button>

          <Button type="primary" size="large" style={{background: "#1D6F42", borderColor: "#1D6F42"}}
                  onClick={() => exportTableToEXCEL()} icon={<FileExcelOutlined/>}>
            Excel
          </Button>

          <Button type="primary" size="large" onClick={() => resetFilterFields()} icon={<RestOutlined/>}>Alaphelyzet</Button>
        </Space>

        <Divider/>

        {showProcedureDataTable.current
            ?
            <div>
              <Collapse className="custom-collapse">
                <Panel
                    header={
                      <>
                        Oszlop választó
                        <CustomTooltip tooltipTitle={InfoOszlopValaszto}/>
                      </>
                    }
                    key="columnSelector"
                >
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ekr} handleValue={handleEkr}
                                                                       label="EKR azonosító"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={targya} handleValue={handleTargya}
                                                                       label="Eljárás tárgya"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={rendje} handleValue={handleRendje}
                                                                       label="Eljárás rendje"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={fajtaja} handleValue={handleFajtaja}
                                                                       label="Eljárás fajtája"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={iktatoszam} handleValue={handleIktatoszam}
                                                                       label="Hirdetmény iktatószáma"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={kozzetetel} handleValue={handleKozzetetel}
                                                                       label="Hirdetmény közzétételének dátuma"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={reszAdataiSorszam} handleValue={handleReszAdataiSorszam}
                                                                       label="Eljárás rész adatai - Sorszám"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={oeno} handleValue={handleOeno}
                                                                       label="OENO / ATC kód"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={hatoanyag} handleValue={handleHatoanyag}
                                                                       label="Hatóanyag"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={osszmennyiseg} handleValue={handleOsszmennyiseg}
                                                                       label="Összmennyiség"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={alapmennyiseg} handleValue={handleAlapmennyiseg}
                                                                       label="Alapmennyiség"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={opciosmennyiseg} handleValue={handleOpciosmennyiseg}
                                                                       label="Opciós mennyiség"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={mertek} handleValue={handleMertek}
                                                                       label="Mértékegység"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={arurabatt} handleValue={handleArurabatt}
                                                                       label="Árurabatt"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={aktiv} handleValue={handleAktiv}
                                                                       label="Aktív időszak hossza (hónap)"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={passziv} handleValue={handlePassziv}
                                                                       label="Passzív időszak hossza (hónap)"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={hosszabithatosag} handleValue={handleHosszabithatosag}
                                                                       label="Meghosszabbíthatóság (opció) hossza (hónap)"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={reszvetel} handleValue={handleReszvetel}
                                                                       label="Részvételi jelentkezés határideje"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ajanlattetel} handleValue={handleAjanlattetel}
                                                                       label="Ajánlattételi határidő"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={elbiralas} handleValue={handleElbiralas}
                                                                       label="Ajánlatok összegző elbírálásának dátuma"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={kMinoseg} handleValue={handleKMinoseg}
                                                                       label="Minőségi kritérium"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={kKoltseg} handleValue={handleKKoltseg}
                                                                       label="Költség kritérium"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={kAr} handleValue={handleKAr}
                                                                       label="Ár kritérium"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ajanlatTNeve} handleValue={handleAjanlatTNeve}
                                                                       label="Szerződő neve"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ajanlatTCime} handleValue={handleAjanalatTCime}
                                                                       label="Szerződő címe"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ajanlatTAdoszam} handleValue={handleAjanlatTAdoszam}
                                                                       label="Szerződő adószáma"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={nemNyertesek} handleValue={handleNemNyertesek}
                                                                       label="Érvényes, de nem nyertes ajánlattevők"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={alCeg} handleValue={handleAlCeg}
                                                                       label="Alvállalkozó igénybevétele"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={termek} handleValue={handleTermek}
                                                                       label="Termék neve"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={gyarto} handleValue={handleGyarto}
                                                                       label="Gyártó cég neve"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={keletkezes} handleValue={handleKeletkezes}
                                                                       label="Szerződés keletkezése"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={teljesitesKezd} handleValue={handleTeljesitesKezd}
                                                                       label="Szerződés teljesítésének kezdete"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={teljesitesVeg} handleValue={handleTeljesitesVeg}
                                                                       label="Szerződés teljesítésének vége"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={hosszabitas} handleValue={handleHosszabitas}
                                                                       label="Szerződés meghosszabbítása"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={teljesBruttoAr} handleValue={handleTeljesBruttoAr}
                                                                       label="Összmennyiség bruttó ára"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={teljesNettoAr} handleValue={handleTeljesNettoAr}
                                                                       label="Összmennyiség nettó ára"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={alapBruttoAr} handleValue={handleAlapBruttoAr}
                                                                       label="Alapmennyiség bruttó ára"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={alapNettoAr} handleValue={handleAlapNettoAr}
                                                                       label="Alapmennyiség nettó ára"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={egysegBruttoAr} handleValue={handleEgysegBruttoAr}
                                                                       label="Egység bruttó ára (összemennyiségre vonatkoztatva)"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={egysegNettoAr} handleValue={handleEgysegNettoAr}
                                                                       label="Egység nettó ára (összemennyiségre vonatkoztatva)"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={dddErtek} handleValue={handleDddErtek}
                                                                       label="DDD értéke (mg)"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ddd1NapiKoltsegErtek} handleValue={handleDdd1NapiKoltsegErtek}
                                                                       label="DDD - 1 napi költség"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={ddd28NaposKoltsegErtek} handleValue={handleDdd28NaposKoltsegErtek}
                                                                       label="DDD - 28 napos költség"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={dddAtlagosHaviKoltsegErtek} handleValue={handleDddAtlagosHaviKoltsegErtek}
                                                                       label="DDD - Átlagos havi költség"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={dddEvesKoltsegErtek} handleValue={handleDddEvesKoltsegErtek}
                                                                       label="DDD - Éves költség"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={penz} handleValue={handlePenz}
                                                                       label="Pénznem"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={eljarasEKRWebLink} handleValue={handleEljarasEKRWebLink}
                                                                       label="Eljárás EKR fájlok"/></Col>
                    <Col className="gutter-row" span={4}><CustomSwitch value={szerzodesEKRWebLink} handleValue={handleSzerzodesEKRWebLink}
                                                                       label="Szerződés EKR fájlok"/></Col>
                  </Row>
                  <Divider/>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={4}><CustomSwitch value={szerzodesKozBeszWebLink} handleValue={handleSzerzodesKozBeszWebLink}
                                                                       label="Szerződés KözBesz fájlok"/></Col>
                  </Row>
                </Panel>
              </Collapse>
              <ProcedureDataTable
                  setIsLogout={setIsLogout}
                  setRefreshSession={setRefreshSession}
                  filterPostBodyProcedureDataTable={filterPostBodyProcedureDataTable}

                  ekr={ekr}
                  targya={targya}
                  rendje={rendje}
                  fajtaja={fajtaja}
                  iktatoszam={iktatoszam}
                  kozzetetel={kozzetetel}

                  reszAdataiSorszam={reszAdataiSorszam}
                  oeno={oeno}
                  hatoanyag={hatoanyag}
                  osszmennyiseg={osszmennyiseg}
                  alapmennyiseg={alapmennyiseg}
                  opciosmennyiseg={opciosmennyiseg}

                  mertek={mertek}
                  arurabatt={arurabatt}
                  aktiv={aktiv}
                  passziv={passziv}
                  hosszabithatosag={hosszabithatosag}
                  reszvetel={reszvetel}

                  ajanlattetel={ajanlattetel}
                  elbiralas={elbiralas}
                  kMinoseg={kMinoseg}
                  kKoltseg={kKoltseg}
                  kAr={kAr}
                  ajanlatTNeve={ajanlatTNeve}

                  ajanlatTCime={ajanlatTCime}
                  ajanlatTAdoszam={ajanlatTAdoszam}
                  nemNyertesek={nemNyertesek}
                  alCeg={alCeg}
                  termek={termek}
                  gyarto={gyarto}

                  keletkezes={keletkezes}
                  teljesitesKezd={teljesitesKezd}
                  teljesitesVeg={teljesitesVeg}
                  hosszabitas={hosszabitas}
                  teljesBruttoAr={teljesBruttoAr}
                  teljesNettoAr={teljesNettoAr}

                  alapBruttoAr={alapBruttoAr}
                  alapNettoAr={alapNettoAr}
                  egysegBruttoAr={egysegBruttoAr}
                  egysegNettoAr={egysegNettoAr}
                  dddErtek={dddErtek}
                  ddd1NapiKoltsegErtek={ddd1NapiKoltsegErtek}

                  ddd28NaposKoltsegErtek={ddd28NaposKoltsegErtek}
                  dddAtlagosHaviKoltsegErtek={dddAtlagosHaviKoltsegErtek}
                  dddEvesKoltsegErtek={dddEvesKoltsegErtek}
                  penz={penz}
                  eljarasEKRWebLink={eljarasEKRWebLink}
                  szerzodesEKRWebLink={szerzodesEKRWebLink}

                  szerzodesKozBeszWebLink={szerzodesKozBeszWebLink}
              />
            </div>
            :
            null
        }

        {renderGanttChart(showGanttChart.current, loadingGanttChartData, ganttChartConfig)}

      </div>
  );
});

export default Search;
