import React, {useEffect, useState} from "react";
import {Redirect, Switch, Route, NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import {Button, Layout, Menu, Popconfirm, message} from "antd";
import {PoweroffOutlined, UserOutlined, ClockCircleOutlined, MailOutlined} from "@ant-design/icons";
import Admin from "../adminpage/Admin";
import Editor from "../editorpage/Editor";
import Search from "../querypage/Search";
import NotFound from "../notfoundpage/NotFound";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import {useInputSwitch} from "../../common/InputHooks";
import {CustomSwitchDemoMode} from "../../common/CustomSwitch";
import logo from "../../common/PPC_logo_white.svg"
import axios from "axios";

const {Header, Content, Footer} = Layout;
const admin = "ROLE_ADMIN";
const editor = "ROLE_EDITOR";
const user = "ROLE_USER";

const LinkMenu = withRouter(props => {
  const {location} = props;
  // console.log("LinkMenu - withRouter - location: ", location);

  let menu = null;
  if (localStorage.getItem("hda_ppc_userName") && localStorage.getItem("hda_ppc_userRoles")) {
    let roles = localStorage.getItem("hda_ppc_userRoles");
    if (roles.includes(admin) && roles.includes(editor) && roles.includes(user)) {
      menu =
          <Menu className="site-layout-menu" theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
            <Menu.Item key="/dashboard/admin">
              <NavLink to="/dashboard/admin">Adminisztrátor</NavLink>
            </Menu.Item>
            <Menu.Item key="/dashboard/editor">
              <NavLink to="/dashboard/editor">Szerkesztés</NavLink>
            </Menu.Item>
            <Menu.Item key="/dashboard/search">
              <NavLink to="/dashboard/search">Lekérdezés</NavLink>
            </Menu.Item>
          </Menu>
    }
    if (roles === admin) {
      menu =
          <Menu className="site-layout-menu" theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
            <Menu.Item key="/dashboard/admin">
              <NavLink to="/dashboard/admin">Adminisztrátor</NavLink>
            </Menu.Item>
          </Menu>
    }
    if (roles === editor) {
      menu =
          <Menu className="site-layout-menu" theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
            <Menu.Item key="/dashboard/editor">
              <NavLink to="/dashboard/editor">Szerkesztés</NavLink>
            </Menu.Item>
          </Menu>
    }
    if (roles === user) {
      menu =
          <Menu className="site-layout-menu" theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
            <Menu.Item key="/dashboard/search">
              <NavLink to="/dashboard/search">Lekérdezés</NavLink>
            </Menu.Item>
          </Menu>
    }
  }

  return menu;
});

const Routing = ({urlPath, setIsLogout, setRefreshSession, demoMode}) => {
  // console.log("Routing - urlPath: ", urlPath);

  let routing = <Switch><Route path="*"><NotFound/></Route></Switch>;
  if (localStorage.getItem("hda_ppc_userName") && localStorage.getItem("hda_ppc_userRoles")) {
    let roles = localStorage.getItem("hda_ppc_userRoles");
    if (roles.includes(admin) && roles.includes(editor) && roles.includes(user)) {
      routing =
          <Switch>
            <Route path={`${urlPath}/admin`}>
              <Admin setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
            <Route path={`${urlPath}/editor`}>
              <Editor setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
            <Route exact path={`${urlPath}/search`}>
              <Search setIsLogout={setIsLogout} setRefreshSession={setRefreshSession} demoMode={demoMode}/>
            </Route>
            <Route path="*">
              <Search setIsLogout={setIsLogout} setRefreshSession={setRefreshSession} demoMode={demoMode}/>
            </Route>
          </Switch>
    }
    if (roles === admin) {
      routing =
          <Switch>
            <Route path={`${urlPath}/admin`}>
              <Admin setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
            <Route path="*">
              <Admin setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
          </Switch>
    }
    if (roles === editor) {
      routing =
          <Switch>
            <Route path={`${urlPath}/editor`}>
              <Editor setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
            <Route path="*">
              <Editor setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
          </Switch>
    }
    if (roles === user) {
      routing =
          <Switch>
            <Route path={`${urlPath}/user`}>
              <Search setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
            <Route path="*">
              <Search setIsLogout={setIsLogout} setRefreshSession={setRefreshSession}/>
            </Route>
          </Switch>
    }
  }

  return routing;
};

const LogoutIfSessionHasExpired = ({setIsLogout}) => {
  useEffect(()=>{
    const logoutWhenSessionHasExpired = () => {
      axios.get('/api/logout')
      .then(response => {
        localStorage.removeItem("hda_ppc_userName");
        localStorage.removeItem("hda_ppc_userRoles");
        console.log("Logout (session timeout - countdownTimer) response: ", response);
        setIsLogout(true);
        message.warn("A munkamenet ideje lejárt! Kérjük jelentkezzen be újra.", 5);
      })
      .catch(error => {
        console.error("react-logout error: ", error);
      })
    };

    logoutWhenSessionHasExpired();
  }, []);

  return (<>A munkamenet ideje lejárt!</>);
};

const SessionCountdownTimer = ({sessionTimeoutValue,  refreshSession, sessionCountdownHasExpired, setSessionCountdownHasExpired, setIsLogout}) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(()=>{
    setMinutes(sessionTimeoutValue);
    setSeconds(0);
  }, [sessionTimeoutValue, refreshSession]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setSessionCountdownHasExpired(i => i + 1);
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
      <>
        {minutes === 0 && seconds === 0 && sessionTimeoutValue > 0 && sessionCountdownHasExpired > 0
            ? <LogoutIfSessionHasExpired setIsLogout={setIsLogout}/>
            : <>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>
        }
      </>
  )
};

const Dashboard = withRouter(({match, ...props}) => {

  // console.log("Dashboard withRouter - match: ", match);

  const [appInfo, setAppInfo] = useState({});
  const [sessionTimeoutValue, setSessionTimeoutValue] = useState(0);
  const [refreshSession, setRefreshSession] = useState(0);
  const [sessionCountdownHasExpired, setSessionCountdownHasExpired] = useState(0);
  const [isLogout, setIsLogout] = useState(false);

  // Switch to DEMO mode (quantities and money * 1.37)
  // DEMO mode: only for the SUPER-ADMIN
  const [demoMode, handleDemoMode] = useInputSwitch(false);

  useEffect(() => {
    const getSessionTimeoutValue = () => {
      axios.get("/api/session/timeout")
      .then((response) => {
        // console.log("getSessionTimeoutValue - axios.get response: ", response);
        setSessionTimeoutValue(response.data / 60);
      })
      .catch((error) => {
        console.error("getSessionTimeoutValue - axios.get error: ", error);
      });
    };

    const getAppInfo = () => {
      axios.get("/api/app/info")
      .then((response) => {
        // console.log("getAppInfo - axios.get response: ", response);
        if (isSessionInvalid(response)) {
          logoutWhenSessionIsInvalid(setIsLogout);
        } else {
          setAppInfo(response.data);
        }
      })
      .catch((error) => {
        console.error("getAppInfo - axios.get error: ", error);
      });
    };

    getSessionTimeoutValue();
    getAppInfo();
  }, []);

  if (isLogout) {
    return <Redirect to="/login"/>;
  }

  const logout = () => {
    // console.log("Logout clicked!");
    axios.get('/api/logout')
    .then(response => {
      localStorage.removeItem("hda_ppc_userName");
      localStorage.removeItem("hda_ppc_userRoles");
      console.log("Logout response: ", response);
      setIsLogout(true);
    })
    .catch(error => {
      console.error("react-logout: ", error);
    })
  };

  return (
      <Layout className="site-layout">

        <Header>
          <div className="header-ppc-logo">
            <img src={logo} alt="PPC" width="70"/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <UserOutlined/>
            &nbsp;&nbsp;
            {localStorage.getItem("hda_ppc_userName")}
            &nbsp;&nbsp;&nbsp;
            {
              localStorage.getItem("hda_ppc_userName") === "ponte.hu.kft"
                  ? <CustomSwitchDemoMode value={demoMode} handleValue={handleDemoMode}/>
                  : null
            }
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <ClockCircleOutlined/>
            &nbsp;&nbsp;
            <SessionCountdownTimer
                sessionTimeoutValue={sessionTimeoutValue}
                refreshSession={refreshSession}
                sessionCountdownHasExpired={sessionCountdownHasExpired}
                setSessionCountdownHasExpired={setSessionCountdownHasExpired}
                setIsLogout={setIsLogout}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Popconfirm
                title="Biztosan ki szeretne lépni?"
                okText="Kilépés"
                cancelText="Mégsem"
                onConfirm={() => logout()}
            >
              <Button
                  type="primary"
                  icon={<PoweroffOutlined/>}
              />
            </Popconfirm>
          </div>
          <LinkMenu/>
        </Header>

        <Content className="site-layout-content">
          <div className="site-layout-content-main-div">
            <Routing urlPath={match.path} setIsLogout={setIsLogout} setRefreshSession={setRefreshSession} demoMode={demoMode}/>
          </div>
        </Content>

        <Footer className="site-layout-footer">
          <p>
            Ponte.hu Kft. ©2021&nbsp;&nbsp;&nbsp;
            <Button
                className="custom-mail-to-button"
                shape="circle"
                icon={<MailOutlined/>}
                onClick={() => window.open('mailto:info@ponte.hu?subject=PPC')}
            />
            info@ponte.hu&nbsp;&nbsp;&nbsp;
            version:&nbsp;{appInfo.version}&nbsp;&nbsp;&nbsp;
            build:&nbsp;{appInfo.buildDate}
          </p>
        </Footer>

      </Layout>
  );

});

export default Dashboard;
