import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, Spin, Alert, DatePicker, message, InputNumber} from 'antd';
import locale from "antd/es/date-picker/locale/hu_HU";
import 'moment/locale/hu';
import * as moment from "moment";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const ContractForm = ({
  visible,
  contractPDDID,
  contractWBID,
  contractWBUnitNetPrice,
  contractActiveMonths,
  contractIdForUpdate,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  const successSave = (response) => {
    // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("A szerződés mentése sikertelen volt!")
    // } else {
    //   message.success("A szerződés sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("A szerződés sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("A szerződés mentése sikertelen volt!")
  };

  const serverSaveProcess = (values) => {
    let saveValues = {
      ...values,
      eljarasReszAdataiId: contractPDDID ? contractPDDID : null,
      nyertesAjanlattetelId: contractWBID ? contractWBID : null,
      keletkezesDatuma: values.keletkezesDatuma.format('YYYY-MM-DD'),
      teljesitesVege: values.teljesitesVege.format('YYYY-MM-DD')
    };
    // console.log("saveValues: ", saveValues);
    axios.post("/api/contract/createContract", saveValues)
    .then((response) => {
      // console.log("Contract Form (SAVE) - server saved the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      let szerzodesId = (response.data && response.data.szerzodesId) ? response.data.szerzodesId : null;
      onCreate({...saveValues, szerzodesId: szerzodesId});
    })
    .catch((error) => {
      console.error("Contract Form (SAVE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  const serverUpdateProcess = (values) => {
    let updateValues = {
      ...values,
      eljarasReszAdataiId: contractPDDID ? contractPDDID : null,
      nyertesAjanlattetelId: contractWBID ? contractWBID : null,
      keletkezesDatuma: values.keletkezesDatuma.format('YYYY-MM-DD'),
      teljesitesVege: values.teljesitesVege.format('YYYY-MM-DD')
    };
    // console.log("updateValues: ", updateValues);
    axios.put("/api/contract/updateContract/" + contractIdForUpdate, updateValues)
    .then((response) => {
      // console.log("Contract Form (UPDATE) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      let szerzodesId = (response.data && response.data.szerzodesId) ? response.data.szerzodesId : null;
      onCreate({...updateValues, szerzodesId: szerzodesId});
    })
    .catch((error) => {
      console.error("Contract Form (UPDATE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  useEffect(() => {
    const findContractById = () => {
      if (contractIdForUpdate) {
        // console.log("findContractById: ", contractIdForUpdate);
        axios.get("/api/contract/findByIdAndGetDTO/" + contractIdForUpdate)
        .then((response) => {
          // console.log("findContractById axios.get response: ", response);
          if (isSessionInvalid(response)) {
            logoutWhenSessionIsInvalid(setIsLogout)
          } else {
            setRefreshSession(i => i + 1);
            message.success("A szerződés adatai sikeresen betöltésre kerültek a szerkesztéshez!");
            let fieldsValue = {
              ...response.data,
              keletkezesDatuma: moment(response.data.keletkezesDatuma),
              teljesitesVege: moment(response.data.teljesitesVege)
            };
            form.setFieldsValue(fieldsValue);
            setLoadingInitialValues(false);
          }
        })
        .catch((error) => {
          console.error("findContractById axios.get error: ", error);
          message.error("A szerződés szerkesztése sikertelen volt, mert nem található az adatbázisban!");
          form.resetFields();
          setLoadingInitialValues(false);
        })
      }
    };

    if (contractIdForUpdate) {
      setLoadingInitialValues(true);
      findContractById();
      // setTimeout(() => findContractById(), 3000);
    }
  }, [contractIdForUpdate]);

  return (
      <Modal
          visible={visible}
          title={contractIdForUpdate ? "Szerződés szerkesztése" : "Új szerződés rögzítése"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            form.resetFields();
            setServerValidation(null);
            onCancel();
          }}
          cancelButtonProps={{disabled: (loadingInitialValues || saving)}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              if (contractIdForUpdate) {
                serverUpdateProcess(values);
                // setTimeout(() => serverUpdateProcess(values), 3000);
              } else {
                serverSaveProcess(values);
                // setTimeout(() => serverSaveProcess(values), 3000);
              }
            })
            .catch((error) => {
              console.error("Contract Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: (loadingInitialValues || saving)}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={loadingInitialValues || saving}>
          <Form
              form={form}
              layout="vertical"
              name="contract_form_in_modal"
              autoComplete="off"
              // initialValues={}
          >
            <Form.Item
                name="termekNeve"
                label="Termék neve"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a termék nevét!"
                  }
                ]}
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
                name="gyartoNeve"
                label="Gyártó neve"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a gyártó nevét!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="keletkezesDatuma"
                label="Szerződés keletkezésének dátuma"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a szerződés keltének a dátumát!"
                  }
                ]}
            >
              <DatePicker
                  placeholder="Kelte dátum"
                  format="YYYY.MM.DD"
                  locale={locale}
                  allowClear
              />
            </Form.Item>

            <Form.Item
                name="honapokSzama"
                label="Szerződés időtartama (aktív hónapok száma + meghosszabítás hónapjai)"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a szerződés időtartamát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} step="1"/>
            </Form.Item>

            <Form.Item
                name="teljesitesVege"
                label="Szerződés befejezésének dátuma"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a szerződés befejezésének a dátumát!"
                  }
                ]}
            >
              <DatePicker
                  placeholder="Vége dátum"
                  format="YYYY.MM.DD"
                  locale={locale}
                  allowClear
              />
            </Form.Item>

            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("keletkezesDatuma") && form.getFieldValue("honapokSzama")) {
                    form.setFieldsValue({
                      ["teljesitesVege"]: moment(
                          form.getFieldValue("keletkezesDatuma")
                          .clone()
                          .add(form.getFieldValue("honapokSzama"), 'month')
                          .format('YYYY-MM-DD')
                      )
                    });
                  }
                }}
            >
              Számold ki a szerződés befejezésének dátumát (kezdete + időtartama)
            </Button>

            <Form.Item
                name="meghosszabitas"
                label="Szerződés meghosszabítása (mennyiség: X mg; időtartam: Y hónap)"
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="szerzodesHosszArany"
                label="Szerződés időtartam aránya (aktív.hónapok/szerződés időtartama)*100"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a szerződés hosszának arányát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} step="1"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("honapokSzama") && contractActiveMonths) {
                    console.log("ContractForm - Calc szerzodesHosszArany: the contractActiveMonths is: ", contractActiveMonths);
                    form.setFieldsValue({
                      ["szerzodesHosszArany"]: Math.round((contractActiveMonths / form.getFieldValue("honapokSzama")) * 100)
                    });
                  }
                }}
            >
              Számold ki a szerződés időtartam arányt
            </Button>

            <Form.Item
                name="ddd"
                label="DDD értéke (mg)"
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>

            <Form.Item
                name="ddd1NapiKoltseg"
                label="DDD-vel súlyozott 1 napi terápiás költség (nettó ár)"
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("ddd") && contractWBUnitNetPrice) {
                    console.log("ContractForm - Calc ddd1NapiKoltseg: the contractWBUnitNetPrice is: ", contractWBUnitNetPrice);
                    form.setFieldsValue({
                      ["ddd1NapiKoltseg"]: form.getFieldValue("ddd") * contractWBUnitNetPrice * 1
                    });
                  }
                }}
            >
              Számold ki a DDD-vel súlyozott 1 napi terápiás költséget (nettó ár)
            </Button>

            <Form.Item
                name="ddd28NaposKoltseg"
                label="DDD-vel súlyozott 28 napos terápiás költség (nettó ár)"
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("ddd") && contractWBUnitNetPrice) {
                    form.setFieldsValue({
                      ["ddd28NaposKoltseg"]: form.getFieldValue("ddd") * contractWBUnitNetPrice * 28
                    });
                  }
                }}
            >
              Számold ki a DDD-vel súlyozott 28 napos terápiás költséget (nettó ár)
            </Button>

            <Form.Item
                name="dddAtlagosHaviKoltseg"
                label="DDD-vel súlyozott átlagos havi terápiás költség (nettó ár)"
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("ddd") && contractWBUnitNetPrice) {
                    form.setFieldsValue({
                      ["dddAtlagosHaviKoltseg"]: form.getFieldValue("ddd") * contractWBUnitNetPrice * (365/12)
                    });
                  }
                }}
            >
              Számold ki a DDD-vel súlyozott átlagos havi terápiás költséget (nettó ár)
            </Button>

            <Form.Item
                name="dddEvesKoltseg"
                label="DDD-vel súlyozott éves terápiás költség (nettó ár)"
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("ddd") && contractWBUnitNetPrice) {
                    form.setFieldsValue({
                      ["dddEvesKoltseg"]: form.getFieldValue("ddd") * contractWBUnitNetPrice * 365
                    });
                  }
                }}
            >
              Számold ki a DDD-vel súlyozott éves terápiás költséget (nettó ár)
            </Button>

            <Form.Item
                name="szerzodesEKRLink"
                label="Szerződés EKR Link"
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="szerzodesKozBeszLink"
                label="Szerződés KözBesz Link"
            >
              <Input/>
            </Form.Item>

            <Button onClick={() => {
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );

};

export default ContractForm;
