import React, {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {Form, Input, Button, Alert, Card, Row, Col} from 'antd';
import logo from "../../common/PPC_logo_white.svg"
import axios from 'axios';

const Login = () => {

  const [isLogged, setIsLogged] = useState(false);
  const [serverAuthFailed, setServerAuthFailed] = useState(false);

  useEffect(() => {
    // cleanUp function for the unMounted component
    return () => {
      // console.log("Login - useEffect - cleanUp function");
    }
  }, [isLogged, serverAuthFailed]);

  if (localStorage.getItem("hda_ppc_userName") && localStorage.getItem("hda_ppc_userRoles")) {
    // console.log("LOGIN - localStorage-Check: ", localStorage);
    return <Redirect to="/"/>;
  }

  const onFinish = (values) => {
    axios.get("/api/account/login", {
      auth: {
        username: values.username,
        password: values.password
      }
    })
    .then(response => {
      // console.log("Logged in successfully, response: ", response);
      localStorage.setItem("hda_ppc_userName", response.data.userName);
      localStorage.setItem("hda_ppc_userRoles", response.data.roles);
      setIsLogged(true);
      setServerAuthFailed(false);
      return <Redirect to="/"/>;
    })
    .catch(error => {
      console.error("Login error: ", error);
      console.error("Login failed with these values: ", values);
      setServerAuthFailed(true);
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Login client validation failed: ", errorInfo);
  };

  return (
      <div className="site-card-wrapper">
        <Row type="flex" justify="center" align="middle" style={{minHeight: '30vh'}}>
          <Col span={12}>
            <Card title={<><img src={logo} alt="PPC" width="100"/>&nbsp;&nbsp;&nbsp;&nbsp;Public Procurement Catalogue</>}>
              <Card.Grid style={{width: '100%'}}>
                <Form
                    name="login"
                    labelCol={{span: 7}}
                    wrapperCol={{span: 12}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                  <Form.Item
                      label="Felhasználónév"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Kérjük adja meg a felhasználónevét!",
                        }
                      ]}
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                      label="Jelszó"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Kérjük adja meg a jelszavát!",
                        },
                      ]}
                  >
                    <Input.Password/>
                  </Form.Item>

                  <Form.Item
                      wrapperCol={{offset: 7, span: 12}}
                  >
                    <Button type="primary" htmlType="submit">
                      Bejelentkezés
                    </Button>
                  </Form.Item>

                  {serverAuthFailed ? <Alert message="Helytelen felhasználónév / jelszó párost adott meg!" type="error"/> : null}

                </Form>
              </Card.Grid>
            </Card>
          </Col>
        </Row>
      </div>
  );

};

export default Login;
