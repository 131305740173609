import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, Spin, Alert, DatePicker, message} from 'antd';
import locale from "antd/es/date-picker/locale/hu_HU";
import 'moment/locale/hu';
import * as moment from "moment";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const ProcedureDataForm = ({
  visible,
  procedureDataIdForUpdate,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  const successSave = (response) => {
    // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("Az eljárás mentése sikertelen volt!")
    // } else {
    //   message.succr  ess("Az eljárás sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("Az eljárás sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("Az eljárás mentése sikertelen volt!")
  };

  const serverSaveProcess = (values) => {
    let saveValues = {...values, hirdetmenyKozzetetelenekDatuma: values.hirdetmenyKozzetetelenekDatuma.format('YYYY-MM-DD')};
    axios.post("/api/procedureData/createProcedureData", saveValues)
    .then((response) => {
      // console.log("ProcedureData Form (SAVE) - server saved the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      let eljarasId = (response.data && response.data.eljarasId) ? response.data.eljarasId : null;
      onCreate({...saveValues, eljarasId: eljarasId});
    })
    .catch((error) => {
      console.error("ProcedureData Form (SAVE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  const serverUpdateProcess = (values) => {
    let updateValues = {...values, hirdetmenyKozzetetelenekDatuma: values.hirdetmenyKozzetetelenekDatuma.format('YYYY-MM-DD')};
    axios.put("/api/procedureData/updateProcedureData/" + procedureDataIdForUpdate, updateValues)
    .then((response) => {
      // console.log("ProcedureData Form (UPDATE) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      let eljarasId = (response.data && response.data.eljarasId) ? response.data.eljarasId : null;
      onCreate({...updateValues, eljarasId: eljarasId});
    })
    .catch((error) => {
      console.error("ProcedureData Form (UPDATE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  useEffect(() => {
    const findProcedureDataById = () => {
      if (procedureDataIdForUpdate) {
        // console.log("findProcedureDataById: ", procedureDataIdForUpdate);
        axios.get("/api/procedureData/findByIdAndGetDTO/" + procedureDataIdForUpdate)
        .then((response) => {
          // console.log("findProcedureDataById axios.get response: ", response);
          if (isSessionInvalid(response)) {
            logoutWhenSessionIsInvalid(setIsLogout)
          } else {
            setRefreshSession(i => i + 1);
            message.success("Az eljárás adatai sikeresen betöltésre kerültek a szerkesztéshez!");
            let fieldsValue = {...response.data, hirdetmenyKozzetetelenekDatuma: moment(response.data.hirdetmenyKozzetetelenekDatuma)};
            form.setFieldsValue(fieldsValue);
            setLoadingInitialValues(false);
          }
        })
        .catch((error) => {
          console.error("findProcedureDataById axios.get error: ", error);
          message.error("Az eljárás szerkesztése sikertelen volt, mert nem található az adatbázisban!");
          form.resetFields();
          setLoadingInitialValues(false);
        })
      }
    };

    if (procedureDataIdForUpdate) {
      setLoadingInitialValues(true);
      findProcedureDataById();
      // setTimeout(() => findProcedureDataById(), 3000);
    }
  }, [procedureDataIdForUpdate]);

  return (
      <Modal
          visible={visible}
          title={procedureDataIdForUpdate ? "Eljárás szerkesztése" : "Új eljárás rögzítése"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            form.resetFields();
            setServerValidation(null);
            onCancel();
          }}
          cancelButtonProps={{disabled: (loadingInitialValues || saving)}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              if (procedureDataIdForUpdate) {
                serverUpdateProcess(values);
                // setTimeout(() => serverUpdateProcess(values), 3000);
              } else {
                serverSaveProcess(values);
                // setTimeout(() => serverSaveProcess(values), 3000);
              }
            })
            .catch((error) => {
              console.error("ProcedureData Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: (loadingInitialValues || saving)}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={loadingInitialValues || saving}>
          <Form
              form={form}
              layout="vertical"
              name="procedure_data_form_in_modal"
              autoComplete="off"
              // initialValues={}
          >
            <Form.Item
                name="eljarasEKRId"
                label="EKR azonosító"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az EKR azonosítót!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="ajanlatkeroNeve"
                label="Ajánlatkérő neve"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az ajánlatkérő nevét!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="eljarasTargya"
                label="Eljárás tárgya"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az eljárás tárgyát!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="eljarasRendje"
                label="Eljárás rendje"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az eljárás rendjét!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="eljarasFajtaja"
                label="Eljárás fajtája"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az eljárás fajtáját!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="hirdetmenyKozzetetelenekDatuma"
                label="Hirdetmény közzétételének dátuma"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a hirdetmény közzétételének dátumát!"
                  }
                ]}
            >
              <DatePicker
                  placeholder="Közzététel dátuma"
                  format="YYYY.MM.DD"
                  locale={locale}
                  allowClear
              />
            </Form.Item>
            <Form.Item
                name="hirdetmenyIktatoszama"
                label="Hirdetmény iktatószáma"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a hirdetmény iktatószámát!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Button onClick={() => {
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );
};

export default ProcedureDataForm;
