import React, {useState, useMemo, useEffect} from "react";
import {ConfigProvider, Table} from 'antd';
import huHU from 'antd/es/locale/hu_HU';
import {numberWithCommas, roundDouble, isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

export const ProcedureDataTable = React.memo(({
  setIsLogout,
  setRefreshSession,
  filterPostBodyProcedureDataTable,
  ekr,
  targya,
  rendje,
  fajtaja,
  iktatoszam,
  kozzetetel,
  reszAdataiSorszam,
  oeno,
  hatoanyag,
  osszmennyiseg,
  alapmennyiseg,
  opciosmennyiseg,
  mertek,
  arurabatt,
  aktiv,
  passziv,
  hosszabithatosag,
  reszvetel,
  ajanlattetel,
  elbiralas,
  kMinoseg,
  kKoltseg,
  kAr,
  ajanlatTNeve,
  ajanlatTCime,
  ajanlatTAdoszam,
  nemNyertesek,
  alCeg,
  termek,
  gyarto,
  keletkezes,
  teljesitesKezd,
  teljesitesVeg,
  hosszabitas,
  teljesBruttoAr,
  teljesNettoAr,
  alapBruttoAr,
  alapNettoAr,
  egysegBruttoAr,
  egysegNettoAr,
  dddErtek,
  ddd1NapiKoltsegErtek,
  ddd28NaposKoltsegErtek,
  dddAtlagosHaviKoltsegErtek,
  dddEvesKoltsegErtek,
  penz,
  eljarasEKRWebLink,
  szerzodesEKRWebLink,
  szerzodesKozBeszWebLink
}) => {

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
    showQuickJumper: true,
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ['10', '20', '30', '50', '100'],
    position: ['bottomCenter']
  });

  const [sortedInfo, setSortedInfo] = useState({
    order: '',
    columnKey: '',
  });

  const [data, setData] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        title: 'EKR azonosító',
        dataIndex: 'eljarasEKRId',
        key: 'EKR_ID',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'EKR_ID' && sortedInfo.order,
        hidden: ekr,
        width: 150
      },
      {
        title: 'Eljárás tárgya',
        dataIndex: 'eljarasTargya',
        key: 'eljarasTargya',
        hidden: targya,
        width: 130
      },
      {
        title: 'Eljárás rendje',
        dataIndex: 'eljarasRendje',
        key: 'eljarasRendje',
        hidden: rendje,
        width: 130
      },
      {
        title: 'Eljárás fajtája',
        dataIndex: 'eljarasFajtaja',
        key: 'eljarasFajtaja',
        hidden: fajtaja,
        width: 130
      },
      {
        title: 'Hirdetmény iktatószáma',
        dataIndex: 'hirdetmeny_iktatoszama',
        key: 'hirdetmeny_iktatoszama',
        hidden: iktatoszam,
        width: 130
      },
      {
        title: 'Hirdetmény közzétételének dátuma',
        dataIndex: 'hirdetmenyKozzetetelenekDatuma',
        key: "DATE_OF_PUBLICATION",
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'DATE_OF_PUBLICATION' && sortedInfo.order,
        render: date => date
            ? date.replaceAll('-', '.')
            : "",
        hidden: kozzetetel,
        width: 130
      },
      {
        title: 'Eljárás rész adatai - Sorszám',
        dataIndex: ['eljarasReszAdatai', 'eljarasReszAdataiSorszam'],
        key: "eljarasReszAdataiSorszam",
        hidden: reszAdataiSorszam,
        width: 150
      },
      {
        title: 'OENO / ATC kód',
        dataIndex: ['eljarasReszAdatai', 'oenoCode'],
        key: "OENO_CODE",
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'OENO_CODE' && sortedInfo.order,
        hidden: oeno,
        width: 150
      },
      {
        title: 'Hatóanyag',
        dataIndex: ['eljarasReszAdatai', 'hatoanyag', 'hatoanyagNeve'],
        key: "ACTIVE_INGREDIENT",
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'ACTIVE_INGREDIENT' && sortedInfo.order,
        hidden: hatoanyag,
        width: 150
      },
      {
        title: 'Összmennyiség',
        dataIndex: ['eljarasReszAdatai', 'osszmennyiseg'],
        key: "osszmennyiseg",
        render: osszm => osszm
            ? numberWithCommas(roundDouble(osszm, 1))
            : "",
        hidden: osszmennyiseg,
        width: 150
      },
      {
        title: 'Alapmennyiség',
        dataIndex: ['eljarasReszAdatai', 'alapmennyiseg'],
        key: "alapmennyiseg",
        render: alapm => alapm
            ? numberWithCommas(roundDouble(alapm, 1))
            : "",
        hidden: alapmennyiseg,
        width: 150
      },
      {
        title: 'Opciós mennyiség',
        dataIndex: ['eljarasReszAdatai', 'opciosmennyiseg'],
        key: "opciosmennyiseg",
        render: opciosm => opciosm
            ? numberWithCommas(roundDouble(opciosm, 1))
            : "",
        hidden: opciosmennyiseg,
        width: 150
      },
      {
        title: 'Mértékegység',
        dataIndex: ['eljarasReszAdatai', 'mertekegyseg'],
        key: "mertekegyseg",
        hidden: mertek,
        width: 130
      },
      {
        title: 'Árurabatt',
        dataIndex: ['eljarasReszAdatai', 'arurabatt'],
        key: "arurabatt",
        hidden: arurabatt,
        width: 150
      },
      {
        title: 'Aktív időszak hossza (hónap)',
        dataIndex: ['eljarasReszAdatai', 'aktivIdoszakHossza'],
        key: "aktivIdoszakHossza",
        hidden: aktiv,
        width: 110
      },
      {
        title: 'Passzív időszak hossza (hónap)',
        dataIndex: ['eljarasReszAdatai', 'passzivIdoszakHossza'],
        key: "passzivIdoszakHossza",
        hidden: passziv,
        width: 110
      },
      {
        title: 'Meghosszabbíthatóság (opció) hossza (hónap)',
        dataIndex: ['eljarasReszAdatai', 'meghosszabithatosaganakHossza'],
        key: "meghosszabithatosaganakHossza",
        hidden: hosszabithatosag,
        width: 110
      },
      {
        title: 'Részvételi jelentkezés határideje',
        dataIndex: ['eljarasReszAdatai', 'reszveteliJelentkezesHatarideje'],
        key: "reszveteliJelentkezesHatarideje",
        render: reszveteliJelentkezesHatarideje => reszveteliJelentkezesHatarideje
            ? reszveteliJelentkezesHatarideje.replaceAll('-', '.')
            : "",
        hidden: reszvetel,
        width: 130
      },
      {
        title: 'Ajánlattételi határidő',
        dataIndex: ['eljarasReszAdatai', 'ajanlatteteliHatarido'],
        key: "ajanlatteteliHatarido",
        render: ajanlatteteliHatarido => ajanlatteteliHatarido
            ? ajanlatteteliHatarido.replaceAll('-', '.')
            : "",
        hidden: ajanlattetel,
        width: 130
      },
      {
        title: 'Ajánlatok összegző elbírálásának dátuma',
        dataIndex: ['eljarasReszAdatai', 'ajanlatokOsszegzoElbiralasanakDatuma'],
        key: "ajanlatokOsszegzoElbiralasanakDatuma",
        render: ajanlatokOsszegzoElbiralasanakDatuma => ajanlatokOsszegzoElbiralasanakDatuma
            ? ajanlatokOsszegzoElbiralasanakDatuma.replaceAll('-', '.')
            : "",
        hidden: elbiralas,
        width: 130
      },
      {
        title: 'Minőségi kritérium',
        dataIndex: ['eljarasReszAdatai', 'kriteriumMinosegi'],
        key: "kriteriumMinosegi",
        render: kriteriumMinosegi => {
          if (kriteriumMinosegi !== null && typeof (kriteriumMinosegi) !== 'undefined') {
            return kriteriumMinosegi ? "igen" : "nem";
          } else {
            return "";
          }
        },
        hidden: kMinoseg,
        width: 100
      },
      {
        title: 'Költség kritérium',
        dataIndex: ['eljarasReszAdatai', 'kriteriumKoltseg'],
        key: "kriteriumKoltseg",
        render: kriteriumKoltseg => {
          if (kriteriumKoltseg !== null && typeof (kriteriumKoltseg) !== 'undefined') {
            return kriteriumKoltseg ? "igen" : "nem";
          } else {
            return "";
          }
        },
        hidden: kKoltseg,
        width: 100
      },
      {
        title: 'Ár kritérium',
        dataIndex: ['eljarasReszAdatai', 'kriteriumAr'],
        key: "kriteriumAr",
        render: kriteriumAr => {
          if (kriteriumAr !== null && typeof (kriteriumAr) !== 'undefined') {
            return kriteriumAr ? "igen" : "nem";
          } else {
            return "";
          }
        },
        hidden: kAr,
        width: 100
      },
      {
        title: 'Szerződő neve',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'ajanlattevoNeve'],
        key: "ajanlattevoNeve",
        hidden: ajanlatTNeve,
        width: 200
      },
      {
        title: 'Szerződő címe',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'ajanlattevoCime'],
        key: "ajanlattevoCime",
        hidden: ajanlatTCime,
        width: 100
      },
      {
        title: 'Szerződő adószáma',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'ajanlattevoAdoszama'],
        key: "ajanlattevoAdoszama",
        hidden: ajanlatTAdoszam,
        width: 120
      },
      {
        title: 'Érvényes, de nem nyertes ajánlattevők',
        dataIndex: 'nemNyertesAjanlatok',
        key: "nemNyertesAjanlatok",
        hidden: nemNyertesek,
        width: 300
      },
      {
        title: 'Alvállalkozó igénybevétele',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'alvallalkozo'],
        key: "alvallalkozo",
        hidden: alCeg,
        width: 300
      },
      {
        title: 'Termék neve',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'termekNeve'],
        key: "NAME_OF_PRODUCT",
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'NAME_OF_PRODUCT' && sortedInfo.order,
        hidden: termek,
        width: 300
      },
      {
        title: 'Gyártó cég neve',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'gyartoNeve'],
        key: "NAME_OF_MANUFACTURER",
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'NAME_OF_MANUFACTURER' && sortedInfo.order,
        hidden: gyarto,
        width: 150
      },
      {
        title: 'Szerződés keletkezése',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'keletkezesDatuma'],
        key: "SIGNING_DATE_OF_CONTRACT",
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'SIGNING_DATE_OF_CONTRACT' && sortedInfo.order,
        render: keletkezesDatuma => keletkezesDatuma
            ? keletkezesDatuma.replaceAll('-', '.')
            : "",
        hidden: keletkezes,
        width: 130
      },
      {
        title: 'Szerződés teljesítésének kezdete',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'teljesitesKezdete'],
        key: "teljesitesKezdete",
        render: teljesitesKezdete => teljesitesKezdete
            ? teljesitesKezdete.replaceAll('-', '.')
            : "",
        hidden: teljesitesKezd,
        width: 130
      },
      {
        title: 'Szerződés teljesítésének vége',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'teljesitesVege'],
        key: "teljesitesVege",
        render: teljesitesVege => teljesitesVege
            ? teljesitesVege.replaceAll('-', '.')
            : "",
        hidden: teljesitesVeg,
        width: 130
      },
      {
        title: 'Szerződés meghosszabbítása',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'meghosszabitas'],
        key: "meghosszabitas",
        render: meghosszabitas => meghosszabitas ? meghosszabitas : "",
        hidden: hosszabitas,
        width: 250
      },
      {
        title: 'Összmennyiség bruttó ára',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'teljesMennyisegBruttoAra'],
        key: "teljesMennyisegBruttoAra",
        render: teljesMennyisegBruttoAra => teljesMennyisegBruttoAra
            ? numberWithCommas(roundDouble(teljesMennyisegBruttoAra, 1))
            : "",
        hidden: teljesBruttoAr,
        width: 140
      },
      {
        title: 'Összmennyiség nettó ára',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'teljesMennyisegNettoAra'],
        key: "teljesMennyisegNettoAra",
        render: teljesMennyisegNettoAra => teljesMennyisegNettoAra
            ? numberWithCommas(roundDouble(teljesMennyisegNettoAra, 1))
            : "",
        hidden: teljesNettoAr,
        width: 140
      },
      {
        title: 'Alapmennyiség bruttó ára',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'alapMennyisegBruttoAra'],
        key: "alapMennyisegBruttoAra",
        render: alapMennyisegBruttoAra => alapMennyisegBruttoAra
            ? numberWithCommas(roundDouble(alapMennyisegBruttoAra, 1))
            : "",
        hidden: alapBruttoAr,
        width: 140
      },
      {
        title: 'Alapmennyiség nettó ára',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'alapMennyisegNettoAra'],
        key: "alapMennyisegNettoAra",
        render: alapMennyisegNettoAra => alapMennyisegNettoAra
            ? numberWithCommas(roundDouble(alapMennyisegNettoAra, 1))
            : "",
        hidden: alapNettoAr,
        width: 140
      },
      {
        title: 'Egység bruttó ára (összemennyiségre vonatkoztatva)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'egysegBruttoAra'],
        key: "egysegBruttoAra",
        render: egysegBruttoAra => egysegBruttoAra
            ? numberWithCommas(roundDouble(egysegBruttoAra, 2))
            : "",
        hidden: egysegBruttoAr,
        width: 160
      },
      {
        title: 'Egység nettó ára (összemennyiségre vonatkoztatva)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'egysegNettoAra'],
        key: "egysegNettoAra",
        render: egysegNettoAra => egysegNettoAra
            ? numberWithCommas(roundDouble(egysegNettoAra, 2))
            : "",
        hidden: egysegNettoAr,
        width: 160
      },
      {
        title: 'DDD értéke (mg)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'ddd'],
        key: "ddd",
        render: ddd => ddd
            ? numberWithCommas(roundDouble(ddd, 2))
            : "",
        hidden: dddErtek,
        width: 160
      },
      {
        title: 'DDD-vel súlyozott 1 napi terápiás költség (nettó ár)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'ddd1NapiKoltseg'],
        key: "ddd1NapiKoltseg",
        render: ddd1NapiKoltseg => ddd1NapiKoltseg
            ? numberWithCommas(roundDouble(ddd1NapiKoltseg, 2))
            : "",
        hidden: ddd1NapiKoltsegErtek,
        width: 160
      },
      {
        title: 'DDD-vel súlyozott 28 napos terápiás költség (nettó ár)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'ddd28NaposKoltseg'],
        key: "ddd28NaposKoltseg",
        render: ddd28NaposKoltseg => ddd28NaposKoltseg
            ? numberWithCommas(roundDouble(ddd28NaposKoltseg, 2))
            : "",
        hidden: ddd28NaposKoltsegErtek,
        width: 160
      },
      {
        title: 'DDD-vel súlyozott átlagos havi terápiás költség (nettó ár)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'dddAtlagosHaviKoltseg'],
        key: "dddAtlagosHaviKoltseg",
        render: dddAtlagosHaviKoltseg => dddAtlagosHaviKoltseg
            ? numberWithCommas(roundDouble(dddAtlagosHaviKoltseg, 2))
            : "",
        hidden: dddAtlagosHaviKoltsegErtek,
        width: 160
      },
      {
        title: 'DDD-vel súlyozott éves terápiás költség (nettó ár)',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'dddEvesKoltseg'],
        key: "dddEvesKoltseg",
        render: dddEvesKoltseg => dddEvesKoltseg
            ? numberWithCommas(roundDouble(dddEvesKoltseg, 2))
            : "",
        hidden: dddEvesKoltsegErtek,
        width: 160
      },
      {
        title: 'Pénznem',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'nyertesAjanlattetel', 'penznem'],
        key: "penznem",
        hidden: penz,
        width: 100
      },
      {
        title: 'Eljárás EKR fájlok',
        dataIndex: 'eljarasEKRLink',
        key: "eljarasEKRLink",
        render: eljarasEKRLink => eljarasEKRLink
            ? <a href={eljarasEKRLink} target="_blank" rel="noopener noreferrer">Eljárás-EKR</a>
            : "",
        hidden: eljarasEKRWebLink,
        width: 140
      },
      {
        title: 'Szerződés EKR fájlok',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'szerzodesEKRLink'],
        key: "szerzodesEKRLink",
        render: szerzodesEKRLink => szerzodesEKRLink
            ? <a href={szerzodesEKRLink} target="_blank" rel="noopener noreferrer">Szerződés-EKR</a>
            : "",
        hidden: szerzodesEKRWebLink,
        width: 140
      },
      {
        title: 'Szerződés KözBesz fájlok',
        dataIndex: ['eljarasReszAdatai', 'szerzodes', 'szerzodesKozBeszLink'],
        key: "szerzodesKozBeszLink",
        render: szerzodesKozBeszLink => szerzodesKozBeszLink
            ? <a href={szerzodesKozBeszLink} target="_blank" rel="noopener noreferrer">Szerződés-KözBesz</a>
            : "",
        hidden: szerzodesKozBeszWebLink,
        width: 140
      },
    ].filter(item => item.hidden);
  }, [
    sortedInfo,
    ekr,
    targya,
    rendje,
    fajtaja,
    iktatoszam,
    kozzetetel,
    reszAdataiSorszam,
    oeno,
    hatoanyag,
    osszmennyiseg,
    alapmennyiseg,
    opciosmennyiseg,
    mertek,
    arurabatt,
    aktiv,
    passziv,
    hosszabithatosag,
    reszvetel,
    ajanlattetel,
    elbiralas,
    kMinoseg,
    kKoltseg,
    kAr,
    ajanlatTNeve,
    ajanlatTCime,
    ajanlatTAdoszam,
    nemNyertesek,
    alCeg,
    termek,
    gyarto,
    keletkezes,
    teljesitesKezd,
    teljesitesVeg,
    hosszabitas,
    teljesBruttoAr,
    teljesNettoAr,
    alapBruttoAr,
    alapNettoAr,
    egysegBruttoAr,
    egysegNettoAr,
    dddErtek,
    ddd1NapiKoltsegErtek,
    ddd28NaposKoltsegErtek,
    dddAtlagosHaviKoltsegErtek,
    dddEvesKoltsegErtek,
    penz,
    eljarasEKRWebLink,
    szerzodesEKRWebLink,
    szerzodesKozBeszWebLink
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProcedureDataTable = () => {
      setLoading(true);
      setSortedInfo({
        order: 'ascend',
        columnKey: 'EKR_ID',
      });

      let filterPostBody =
          {
            ...filterPostBodyProcedureDataTable,
            pageNum: 1,
            pageSize: pagination.pageSize,
            hdaOrderBy: {fieldName: 'EKR_ID', descending: false}
          };

      axios.post("/api/procedureData/getAllSeparately", filterPostBody)
      .then((response) => {
        // console.log("getProcedureDataTable axios.post response: ", response);
        if (isSessionInvalid(response)) {
          logoutWhenSessionIsInvalid(setIsLogout);
        } else {
          setRefreshSession(i => i + 1);
          setLoading(false);
          setData(response.data.hdaResult.resultValues);
          setPagination({...pagination, current: 1, total: response.data.hdaResult.totalCount});
        }
      })
      .catch((error) => {
        console.error("getProcedureDataTable axios.post error: ", error);
        setLoading(false);
      });
    };

    getProcedureDataTable();
  }, [filterPostBodyProcedureDataTable]);

  const handleProcedureDataTable = (paginationTable, filterTable, sortTable) => {
    setLoading(true);
    setSortedInfo(sortTable);

    let filterPostBody =
        {
          ...filterPostBodyProcedureDataTable,
          pageSize: paginationTable.pageSize,
          pageNum: paginationTable.current,
          hdaOrderBy: initializeOrderBy(sortTable)
        };

    axios.post("/api/procedureData/getAllSeparately", filterPostBody)
    .then((response) => {
      // console.log("getProcedureDataTable axios.post response: ", response);
      if (isSessionInvalid(response)) {
        logoutWhenSessionIsInvalid(setIsLogout);
      } else {
        setRefreshSession(i => i + 1);
        setLoading(false);
        setData(response.data.hdaResult.resultValues);
        setPagination({...pagination, current: paginationTable.current, pageSize: paginationTable.pageSize, total: response.data.hdaResult.totalCount});
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error("getProcedureDataTable axios.post error: ", error);
    });
  };

  const initializeOrderBy = (sorter) => {
    // console.log("sorter: ", sorter);
    return {
      fieldName: typeof (sorter.order) === "undefined" || sorter.order === false ? null : sorter.columnKey,
      descending: sorter.order === "descend"
    }
  };

  return (
      <div>
        <ConfigProvider locale={huHU}>
          <Table
              scroll={{y: 550, x: 4820}}
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
              columns={columns}
              rowKey={record => record.uuid}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={handleProcedureDataTable}
          />
        </ConfigProvider>
      </div>
  );

});
