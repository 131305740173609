import React from "react";
import {Route, Redirect} from "react-router-dom";

const ProtectedRoute = ({children, ...rest}) => {

  return (
      <Route {...rest}
             render={({location}) =>
                 (localStorage.getItem("hda_ppc_userName") && localStorage.getItem("hda_ppc_userRoles"))
                     ? (children)
                     : (<Redirect to={{pathname: "/login", state: {from: location}}}/>)
             }
      />
  );
};
export default ProtectedRoute;
