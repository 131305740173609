import React from "react";
import {Tooltip} from "antd";
import {InfoCircleOutlined} from '@ant-design/icons';

export const CustomTooltip = ({tooltipTitle}) => {
  return (
      <div className="custom-tooltip">
        <Tooltip
            title={tooltipTitle}
            color={'#264a60'}
            overlayStyle={{maxWidth: '310px'}}
            arrowPointAtCenter
        >
          <InfoCircleOutlined/>
        </Tooltip>
      </div>
  );
};
