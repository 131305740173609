import React, {useEffect, useRef, useState} from 'react';
import {Modal, Form, Input, InputNumber, Checkbox, Button, Spin, Alert, DatePicker, message} from 'antd';
import locale from "antd/es/date-picker/locale/hu_HU";
import 'moment/locale/hu';
import * as moment from "moment";
import {SuggestBox} from "../../common/SuggestBox";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const ProcedureDataDetailsForm = ({
  visible,
  optionsAISelect,
  procedureDataDetailsUpdatePDID,
  procedureDataDetailsIdForUpdate,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);
  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  // active ingredient - hatóanyag neve
  const [AI, setAI] = useState(null);
  const [AIInputQuery, setAIInputQuery] = useState("");
  const selectAIRef = useRef(null);
  const [optionsAI, setOptionsAI] = useState(optionsAISelect);

  useEffect(() => {
    setOptionsAI(optionsAISelect)
  }, [optionsAISelect]);

  const successSave = (response) => {
    // // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("Az eljárásrész mentése sikertelen volt!")
    // } else {
    //   message.success("Az eljárásrész sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("Az eljárásrész sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("Az eljárásrész mentése sikertelen volt!")
  };

  const serverSaveProcess = (values) => {
    let saveValues = {
      ...values,
      eljarasId: procedureDataDetailsUpdatePDID ? procedureDataDetailsUpdatePDID : null,
      hatoanyagNeve: (AI && AI.label) ? AI.label : null,
      szamitottEAzOsszmennyiseg: values.szamitottEAzOsszmennyiseg ? values.szamitottEAzOsszmennyiseg : false,
      szamitottEAzAlapmennyiseg: values.szamitottEAzAlapmennyiseg ? values.szamitottEAzAlapmennyiseg : false,
      szamitottEAzOpciosmennyiseg: values.szamitottEAzOpciosmennyiseg ? values.szamitottEAzOpciosmennyiseg : false,
      kriteriumMinosegi: values.kriteriumMinosegi ? values.kriteriumMinosegi : false,
      kriteriumKoltseg: values.kriteriumKoltseg ? values.kriteriumKoltseg : false,
      kriteriumAr: values.kriteriumAr ? values.kriteriumAr : false,
      reszveteliJelentkezesHatarideje: values.reszveteliJelentkezesHatarideje.format('YYYY-MM-DD HH:mm'),
      ajanlatteteliHatarido: values.ajanlatteteliHatarido.format('YYYY-MM-DD HH:mm'),
      ajanlatokOsszegzoElbiralasanakDatuma: values.ajanlatokOsszegzoElbiralasanakDatuma
          ? values.ajanlatokOsszegzoElbiralasanakDatuma.format('YYYY-MM-DD HH:mm')
          : ""
    };
    // console.log("saveValues: ", saveValues);
    axios.post("/api/procedureDataDetails/createProcedureDataDetails", saveValues)
    .then((response) => {
      // console.log("ProcedureDataDetails Form (SAVE) - server saved the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      selectAIRef.current.select.clearValue();
      setAI(null);
      setAIInputQuery("");
      form.resetFields();
      let eljarasReszAdataiId = (response.data && response.data.eljarasReszAdataiId) ? response.data.eljarasReszAdataiId : null;
      onCreate({...saveValues, eljarasReszAdataiId: eljarasReszAdataiId});
    })
    .catch((error) => {
      console.error("ProcedureDataDetails Form (SAVE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  const serverUpdateProcess = (values) => {
    let updateValues = {
      ...values,
      eljarasId: procedureDataDetailsUpdatePDID ? procedureDataDetailsUpdatePDID : null,
      hatoanyagNeve: (AI && AI.label) ? AI.label : null,
      szamitottEAzOsszmennyiseg: values.szamitottEAzOsszmennyiseg ? values.szamitottEAzOsszmennyiseg : false,
      szamitottEAzAlapmennyiseg: values.szamitottEAzAlapmennyiseg ? values.szamitottEAzAlapmennyiseg : false,
      szamitottEAzOpciosmennyiseg: values.szamitottEAzOpciosmennyiseg ? values.szamitottEAzOpciosmennyiseg : false,
      kriteriumMinosegi: values.kriteriumMinosegi ? values.kriteriumMinosegi : false,
      kriteriumKoltseg: values.kriteriumKoltseg ? values.kriteriumKoltseg : false,
      kriteriumAr: values.kriteriumAr ? values.kriteriumAr : false,
      reszveteliJelentkezesHatarideje: values.reszveteliJelentkezesHatarideje.format('YYYY-MM-DD HH:mm'),
      ajanlatteteliHatarido: values.ajanlatteteliHatarido.format('YYYY-MM-DD HH:mm'),
      ajanlatokOsszegzoElbiralasanakDatuma: values.ajanlatokOsszegzoElbiralasanakDatuma
          ? values.ajanlatokOsszegzoElbiralasanakDatuma.format('YYYY-MM-DD HH:mm')
          : ""
    };
    // console.log("updateValues: ", updateValues);
    axios.put("/api/procedureDataDetails/updateProcedureDataDetails/" + procedureDataDetailsIdForUpdate, updateValues)
    .then((response) => {
      // console.log("ProcedureDataDetails Form (UPDATE) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      selectAIRef.current.select.clearValue();
      setAI(null);
      setAIInputQuery("");
      form.resetFields();
      let eljarasReszAdataiId = (response.data && response.data.eljarasReszAdataiId) ? response.data.eljarasReszAdataiId : null;
      onCreate({...updateValues, eljarasReszAdataiId: eljarasReszAdataiId});
    })
    .catch((error) => {
      console.error("ProcedureDataDetails Form (UPDATE) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  useEffect(() => {
    const findProcedureDataDetailsById = () => {
      if (procedureDataDetailsIdForUpdate) {
        // console.log("findProcedureDataDetailsById: ", procedureDataDetailsIdForUpdate);
        axios.get("/api/procedureDataDetails/findByIdAndGetDTO/" + procedureDataDetailsIdForUpdate)
        .then((response) => {
          // console.log("findProcedureDataDetailsById axios.get response: ", response);
          if (isSessionInvalid(response)) {
            logoutWhenSessionIsInvalid(setIsLogout)
          } else {
            setRefreshSession(i => i + 1);
            message.success("Az eljárárész adatai sikeresen betöltésre kerültek a szerkesztéshez!");
            setAIInputQuery(response.data.hatoanyag.hatoanyagNeve);
            setAI({value: response.data.hatoanyag.hatoanyagId, label: response.data.hatoanyag.hatoanyagNeve});
            let fieldsValue = {
              ...response.data,
              // hatoanyagNeve: response.data.hatoanyag.hatoanyagNeve,
              reszveteliJelentkezesHatarideje: moment(response.data.reszveteliJelentkezesHatarideje),
              ajanlatteteliHatarido: moment(response.data.ajanlatteteliHatarido),
              ajanlatokOsszegzoElbiralasanakDatuma: response.data.ajanlatokOsszegzoElbiralasanakDatuma
                  ? moment(response.data.ajanlatokOsszegzoElbiralasanakDatuma)
                  : null
            };
            form.setFieldsValue(fieldsValue);
            setLoadingInitialValues(false);
          }
        })
        .catch((error) => {
          console.error("findProcedureDataDetailsById axios.get error: ", error);
          message.error("Az eljárásrész szerkesztése sikertelen volt, mert nem található az adatbázisban!");
          selectAIRef.current.select.clearValue();
          setAI(null);
          setAIInputQuery("");
          form.resetFields();
          setLoadingInitialValues(false);
        })
      }
    };

    if (procedureDataDetailsIdForUpdate) {
      setLoadingInitialValues(true);
      findProcedureDataDetailsById();
      // setTimeout(() => findProcedureDataDetailsById(), 3000);
    }
  }, [procedureDataDetailsIdForUpdate]);

  return (
      <Modal
          visible={visible}
          title={procedureDataDetailsIdForUpdate ? "Eljárásrész szerkesztése" : "Új eljárásrész rögzítése"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            selectAIRef.current.select.clearValue();
            setAI(null);
            setAIInputQuery("");
            form.resetFields();
            setServerValidation(null);
            // setTimeout: This is necessary: clear SELECT values before close the modal.
            setTimeout(() => onCancel(), 500);
          }}
          cancelButtonProps={{disabled: (loadingInitialValues || saving)}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              if (procedureDataDetailsIdForUpdate) {
                serverUpdateProcess(values);
                // setTimeout(() => serverUpdateProcess(values), 3000);
              } else {
                serverSaveProcess(values);
                // setTimeout(() => serverSaveProcess(values), 3000);
              }
            })
            .catch((error) => {
              console.error("ProcedureDataDetails Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: (loadingInitialValues || saving)}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={loadingInitialValues || saving}>
          <Form
              form={form}
              layout="vertical"
              name="procedure_data_details_form_in_modal"
              autoComplete="off"
              // initialValues={}
          >
            <Form.Item
                name="eljarasReszAdataiSorszam"
                label="Eljárásrész sorszám"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az eljárásrész sorszámát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={1}/>
            </Form.Item>

            <Form.Item
                name="oenoCode"
                label="OENO / ATC kód"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az OENO / ATC kódot!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="hatoanyagNeve"
                label="* Hatóanyag neve (kötelező egy létezőt kiválasztani vagy egy újat megadni)"
            >
              <SuggestBox
                  nameAndClassAttr="hatoanyagNeveSELECT"
                  placeholder="Válasszon hatóanyagot!"
                  selectFilterFieldRef={selectAIRef}
                  filterField={AI}
                  setFilterField={setAI}
                  optionsArray={optionsAI}
                  initInputQuery={AIInputQuery}
              />
            </Form.Item>

            {/*<Form.Item*/}
            {/*    name="hatoanyagNeve"*/}
            {/*    label="Hatóanyag neve"*/}
            {/*    rules={[*/}
            {/*      {*/}
            {/*        required: true,*/}
            {/*        message: "Kérjük adja meg a hatóanyag nevét!"*/}
            {/*      }*/}
            {/*    ]}*/}
            {/*>*/}
            {/*  <Input/>*/}
            {/*</Form.Item>*/}

            <Form.Item
                name="osszmennyiseg"
                label="Összmennyiség"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az összmennyiséget!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            <Button
                type="primary"
                className="formCalculateButton"
                onClick={() => {
                  if (form.getFieldValue("alapmennyiseg") && form.getFieldValue("opciosmennyiseg")) {
                    form.setFieldsValue({
                      ["osszmennyiseg"]: form.getFieldValue("alapmennyiseg") + form.getFieldValue("opciosmennyiseg")
                    });
                  }
                }}
            >
              Számold ki az Összmennyiséget (Össz=Alap+Opció)
            </Button>

            <Form.Item
                name="szamitottEAzOsszmennyiseg"
                valuePropName="checked"
            >
              <Checkbox>Számított-e az összmennyiség?</Checkbox>
            </Form.Item>

            <Form.Item
                name="alapmennyiseg"
                label="Alapmennyiség"
                // rules={[
                //   {
                //     required: true,
                //     message: "Kérjük adja meg az alapmennyiséget!"
                //   }
                // ]}
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    onClick={()=>{*/}
            {/*      if(form.getFieldValue("osszmennyiseg")){*/}
            {/*        form.setFieldsValue({*/}
            {/*          ["alapmennyiseg"]: form.getFieldValue("osszmennyiseg") * 0.666662*/}
            {/*        });*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Számold ki az Alapmennyiséget (Alap=Össz*0.666662)*/}
            {/*</Button>*/}

            <Form.Item
                name="szamitottEAzAlapmennyiseg"
                valuePropName="checked"
            >
              <Checkbox>Számított-e az alapmennyiség?</Checkbox>
            </Form.Item>

            <Form.Item
                name="opciosmennyiseg"
                label="Opciósmennyiség"
            >
              <InputNumber style={{width: 200}} min={0} step="0.01"/>
            </Form.Item>
            {/*<Button*/}
            {/*    type="primary"*/}
            {/*    onClick={()=>{*/}
            {/*      if(form.getFieldValue("osszmennyiseg")){*/}
            {/*        form.setFieldsValue({*/}
            {/*          ["opciosmennyiseg"]: form.getFieldValue("osszmennyiseg") * 0.333338*/}
            {/*        });*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Számold ki az Opciósmennyiséget (Opció=Össz*0.333338)*/}
            {/*</Button>*/}

            <Form.Item
                name="szamitottEAzOpciosmennyiseg"
                valuePropName="checked"
            >
              <Checkbox>Számított-e az opciósmennyiség?</Checkbox>
            </Form.Item>

            <Form.Item
                name="mertekegyseg"
                label="Mértékegység"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a mértékegységet!"
                  }
                ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="arurabatt"
                label="Árurabatt"
            >
              <Input/>
            </Form.Item>

            <Form.Item
                name="aktivIdoszakHossza"
                label="Aktív időszak hossza"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az aktív időszak hosszát!"
                  }
                ]}
            >
              <InputNumber style={{width: 200}} min={0}/>
            </Form.Item>

            <Form.Item
                name="passzivIdoszakHossza"
                label="Passzív időszak hossza"
            >
              <InputNumber style={{width: 200}} min={0}/>
            </Form.Item>

            <Form.Item
                name="meghosszabithatosaganakHossza"
                label="Meghosszabíthatóság (opció) hossza"
            >
              <InputNumber style={{width: 200}} min={0}/>
            </Form.Item>

            <Form.Item
                name="reszveteliJelentkezesHatarideje"
                label="Részvételi jelentkezés határideje"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg a részvételi jelentkezés határidejét!"
                  }
                ]}
            >
              <DatePicker
                  placeholder="Részvételi jelentkezés határideje"
                  showTime
                  format="YYYY.MM.DD HH:mm"
                  locale={locale}
                  allowClear
              />
            </Form.Item>

            <Form.Item
                name="ajanlatteteliHatarido"
                label="Ajánlattételi határidő"
                rules={[
                  {
                    required: true,
                    message: "Kérjük adja meg az ajánlattétel határidejét!"
                  }
                ]}
            >
              <DatePicker
                  placeholder="Ajánlattételi határidő"
                  showTime
                  format="YYYY.MM.DD HH:mm"
                  locale={locale}
                  allowClear
              />
            </Form.Item>

            <Form.Item
                name="ajanlatokOsszegzoElbiralasanakDatuma"
                label="Ajánlatok összegző elbírálásának dátuma"
            >
              <DatePicker
                  placeholder="Összegző elbírálás dátuma"
                  showTime
                  format="YYYY.MM.DD HH:mm"
                  locale={locale}
                  allowClear
              />
            </Form.Item>

            <Form.Item
                name="kriteriumMinosegi"
                valuePropName="checked"
            >
              <Checkbox>Kritérium: minőségi</Checkbox>
            </Form.Item>

            <Form.Item
                name="kriteriumKoltseg"
                valuePropName="checked"
            >
              <Checkbox>Kritérium: költség</Checkbox>
            </Form.Item>

            <Form.Item
                name="kriteriumAr"
                valuePropName="checked"
            >
              <Checkbox>Kritérium: ár</Checkbox>
            </Form.Item>

            <Button onClick={() => {
              selectAIRef.current.select.clearValue();
              setAI(null);
              setAIInputQuery("");
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );
};

export default ProcedureDataDetailsForm;
