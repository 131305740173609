import React, {useState, useMemo, useEffect} from "react";
import WindowedSelect, {components} from "react-windowed-select";

/**
 * REACT-SELECT library info
 * https://react-select.com/home
 * https://github.com/JedWatson/react-select
 */

/**
 * REACT-WINDOWED-SELECT library info
 * An integration of react-window with react-select to efficiently render large lists.
 * https://www.npmjs.com/package/react-windowed-select
 * https://github.com/jacobworrel/react-windowed-select
 */

const Input = (props) => <components.Input {...props} isHidden={false}/>;

// Ant Design react component style
const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 2,
    borderColor: state.isFocused ? "#40a9ff" : "#d9d9d9",
    boxShadow: state.isFocused? "0 0 0 2px rgb(24 144 255 / 20%)" : "#40a9ff",
    "&:hover": {borderColor: "#40a9ff"}
  })
};

/**
 * AutoComplete SuggestBox field on the form
 * In the AutoComplete SuggestBox you can type/choose a non-exists option and then you can edit the typed/chosen option.
 * @param nameAndClassAttr: name and class html attribute's value
 * @param placeholder: placeholder of the SuggestBox
 * @param selectFilterFieldRef: react useRef
 * @param filterField: filter field object on the form
 * @param setFilterField: setter for the filter field object on the form
 * @param optionsArray: all the options from which we filter, example format: [{value: "1", label: "test-1"}, {value: "2", label: "test-2"}]
 *        optionsArray: by default we get the optionsArray from server in alphabetical ascending order by LABEL
 * @param filterMode: null || "prefixFirst" || "abcFilter"
 * @param initInputQuery: initialize custom input value
 * @return WindowedSelect react component
 */
export const SuggestBox = React.memo(
    ({nameAndClassAttr, placeholder, selectFilterFieldRef, filterField, setFilterField, optionsArray, filterMode, initInputQuery}) => {
      const DEFAULT_FILTER_MODE = "prefixFirst";

      // Input field query's value
      const [inputQuery, setInputQuery] = useState(initInputQuery);

      // Filter logic of the optionsArray (option.label)
      const filteredOptions = useMemo(() => {
        // Which will check for empty strings (""), null, undefined, false and the numbers 0 and NaN. (!null -> true; !"" -> true; etc...)
        if (!inputQuery) {
          return optionsArray;
        }

        let selectedFilterMode = filterMode == null || filterMode === "" ? DEFAULT_FILTER_MODE : filterMode;

        if (selectedFilterMode === "abcFilter") {
          // INFO: if the inputQuery is not in the optionsArray --> add {value: inputQuery, label: inputQuery} as a new option to the optionsArray
          // let result = [...abcFilter(optionsArray)];
          // return result.length === 0 ? [...result, {value: inputQuery, label: inputQuery}] : result;
          // INFO: if the inputQuery is not in the optionsArray --> return an empty array and a message: "Nincs találat"
          return abcFilter(optionsArray);
        } else {
          // INFO: if the inputQuery is not in the optionsArray --> add {value: inputQuery, label: inputQuery} as a new option to the optionsArray
          // let result = [...prefixFirstFilter(optionsArray)];
          // return result.length === 0 ? [...result, {value: inputQuery, label: inputQuery}] : result;
          // INFO: if the inputQuery is not in the optionsArray --> return an empty array and a message: "Nincs találat"
          return prefixFirstFilter(optionsArray);
        }
      }, [inputQuery, optionsArray]);

      useEffect(() => {
        setInputQuery(initInputQuery);
      }, [initInputQuery]);

      // Logic to handle inputQuery change.
      const onInputChange = (inputQuery, {action}) => {
        if (action === "input-change") {
          setFilterField({value: inputQuery, label: inputQuery});
          setInputQuery(inputQuery);
        }
      };

      // Logic to handle select's option change.
      const onChange = (option) => {
        setFilterField(option);
        setInputQuery(option ? option.label : "");
      };

      // optionsArray: by default we get the optionsArray from server in alphabetical ascending order by LABEL
      function abcFilter(optionsArray) {
        const matchArray = [];

        for (const option of optionsArray) {
          if (option.label.toLowerCase().indexOf(inputQuery.toLowerCase()) >= 0) {
            matchArray.push(option);
          }
        }

        return matchArray;
      }

      // optionsArray: by default we get the optionsArray from server in alphabetical ascending order by LABEL
      function prefixFirstFilter(optionsArray) {
        let matchArray = [];
        const firstArr = [];
        const secArr = [];

        for (const option of optionsArray) {
          let ind = option.label.toLowerCase().indexOf(inputQuery.toLowerCase());
          if (ind === 0) {
            firstArr.push(option);
          } else if (ind > 0) {
            secArr.push(option);
          }
        }

        matchArray = firstArr.concat(secArr);

        return matchArray;
      }

      return (
          <WindowedSelect
              styles={customStyles}
              maxMenuHeight={200}
              ref={selectFilterFieldRef}
              inputId={nameAndClassAttr} // input HTML field id
              name={nameAndClassAttr}
              className={nameAndClassAttr}
              classNamePrefix="select"
              placeholder={placeholder}
              options={filteredOptions}
              value={filterField}
              inputValue={inputQuery}
              onInputChange={onInputChange}
              onChange={onChange}
              // onFocus={onFocus}
              controlShouldRenderValue={false}
              components={{
                Input
              }}
              filterOption={() => true} // Disable the REACT-SELECT's native filter logic!
              noOptionsMessage={() => "Nincs találat"}
              isClearable={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#264a60',
                  primary25: '#e6f7ff'
                }
              })}
          />
      );
    });
