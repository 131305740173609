import React from "react";
import {Switch} from "antd";
import {CloseOutlined, CheckOutlined} from '@ant-design/icons';

export const CustomSwitch = React.memo(({value, handleValue, label}) => {
  return (
      <div>
        <Switch
            size="small"
            checked={value}
            onChange={handleValue}
            checkedChildren={<CheckOutlined/>}
            unCheckedChildren={<CloseOutlined/>}
        />
        <p className="custom-switch-label">{label}</p>
      </div>
  );
});

export const CustomSwitchDemoMode = React.memo(({value, handleValue}) => {
  return (
      <Switch
          size="small"
          checked={value}
          onChange={handleValue}
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
          className={"switch-demo-mode"}
      />
  );
});
