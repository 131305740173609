import React, {useState} from 'react';
import {Modal, Form, Input, Button, Spin, Alert, message} from 'antd';
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const FormPswdChange = ({
  visible,
  accountIdForPasswordUpdate,
  accountUserName,
  onCreate,
  onCancel,
  setIsLogout,
  setRefreshSession
}) => {

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [serverValidation, setServerValidation] = useState(null);

  const successSave = (response) => {
    // 302 not found --> data will be "HTML"
    // if (response && response.data && (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("A felhasználó jalszavának cseréje sikertelen volt!")
    // } else {
    //   message.success("A felhasználó új jelszava sikeresen mentve lett!")
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("A felhasználó új jelszava sikeresen mentve lett!")
    }
  };

  const errorSave = () => {
    message.error("A felhasználó jalszavának cseréje sikertelen volt!")
  };

  const serverPasswordChangeProcess = (values) => {
    let pswd = {password: values.password};

    axios.put("/api/account/updatePassword/" + accountIdForPasswordUpdate, pswd)
    .then((response) => {
      console.log("Account Form (Password Change) - server updated the data successfully. ", response);
      successSave(response);
      setSaving(false);
      setServerValidation(null);
      form.resetFields();
      onCreate(pswd);
    })
    .catch((error) => {
      console.error("Account Form (Password Change) - server validation failed: ", error.response);
      errorSave();
      setSaving(false);
      setServerValidation((error.response && error.response.data) ? error.response.data : error);
    });
  };

  return (
      <Modal
          visible={visible}
          title={"Felhasználó ("+ accountUserName +") jelszavának megváltoztatása"}
          okText="Mentés"
          cancelText="Elvetés"
          onCancel={() => {
            form.resetFields();
            setServerValidation(null);
            onCancel();
          }}
          cancelButtonProps={{disabled: saving}}
          onOk={() => {
            form
            .validateFields()
            .then((values) => {
              setSaving(true);
              serverPasswordChangeProcess(values);
            })
            .catch((error) => {
              console.error("Account Form - client validation failed: ", error);
              errorSave();
            })
          }}
          okButtonProps={{disabled: saving}}
          closable={false}
          maskClosable={false}
      >
        <Spin spinning={saving}>
          <Form
              form={form}
              layout="vertical"
              name="pswd_change_form_in_modal"
              autoComplete="off"
          >
            <Form.Item
                name="password"
                label="Új jelszó"
                rules={[
                  {
                    required: true,
                    message: "Kérjük töltse ki a jelszót!",
                  },
                ]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item
                name="confirm_password"
                label="Új jelszó megerősítése"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Kérjük erősítse meg a fentebb már megadott jelszót!",
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("A jelszavak nem egyeznek meg!"));
                    },
                  }),
                ]}
            >
              <Input.Password/>
            </Form.Item>

            <Button onClick={() => {
              form.resetFields();
              setServerValidation(null);
            }}>
              Alaphelyzet
            </Button>

            {serverValidation
                ?
                <Alert
                    type="error"
                    message={<pre className="server-validation-failed">{JSON.stringify(serverValidation, null, 2)}</pre>}
                />
                :
                null
            }

          </Form>
        </Spin>
      </Modal>
  );
};

export default FormPswdChange;
