import React, {Fragment, useState} from "react";
import {Divider, Row, Col, Input, Button, message} from "antd";
import {PlusSquareOutlined, SearchOutlined} from "@ant-design/icons";
import {useInput} from "../../common/InputHooks";
import BuildEditorPage from "./BuildEditorPage";
import ProcedureDataForm from "./ProcedureDataForm";
import ProcedureDataDetailsForm from "./ProcedureDataDetailsForm";
import BiddingForm from "./BiddingForm";
import ContractForm from "./ContractForm";
import {useGetHTTPMethodWithSetter} from "../../common/HttpMethods";
import {isSessionInvalid, logoutWhenSessionIsInvalid} from "../../common/Utils";
import axios from "axios";

const Editor = ({setIsLogout, setRefreshSession}) => {

  // MAIN DATA
  const [procedureDataMain, setProcedureDataMain] = useState(null);

  // ProcedureData (ELJÁRÁS) search by EKR
  const [ekr, setEkr, handleEkr] = useInput("");
  // When ProcedureData: Search or Create
  const [savedEkr, setSavedEkr] = useState(null);

  // ProcedureData (ELJÁRÁS) Modal Form (CREATE and EDIT)
  const [procedureDataModalVisible, setProcedureDataModalVisible] = useState(false);
  const [procedureDataIdForUpdate, setProcedureDataIdForUpdate] = useState(null);

  // ProcedureDataDetails (ELJÁRÁS_RÉSZ_ADAT) Modal Form (CREATE and EDIT)
  const [procedureDataDetailsModalVisible, setProcedureDataDetailsModalVisible] = useState(false);
  const [procedureDataDetailsIdForUpdate, setProcedureDataDetailsIdForUpdate] = useState(null);
  const [procedureDataDetailsUpdatePDID, setProcedureDataDetailsUpdatePDID] = useState(null);
  // ProcedureDataDetails - FORM - ActiveIngredients - SuggestBox and SingleSelect
  const [optionsAI, setOptionsAI, loadingOptionsAI] = useGetHTTPMethodWithSetter
  (
      "/api/activeIngredient/selectDTOList",
      [],
      "suggestBox - getHatoanyagNeveOptions",
      setIsLogout,
      setRefreshSession
  );

  // Bidding (AJÁNLATTÉTEL) Modal Form (CREATE AND EDIT)
  const [biddingModalVisible, setBiddingModalVisible] = useState(false);
  const [biddingIdForUpdate, setBiddingIdForUpdate] = useState(null);
  const [biddingUpdatePDDID, setBiddingUpdatePDDID] = useState(null);
  const [biddingPPDTotalAmount, setBiddingPPDTotalAmount] = useState(null);

  // Contract (SZERZŐDÉS) Modal Form (CREATE AND EDIT)
  const [contractModalVisible, setContractModalVisible] = useState(false);
  const [contractIdForUpdate, setContractIdForUpdate] = useState(null);
  const [contractPDDID, setContractPDDID] = useState(null);
  const [contractWBID, setContractWBID] = useState(null);
  const [contractWBUnitNetPrice, setContractWBUnitNetPrice] = useState(null);
  const [contractActiveMonths, setContractActiveMonths] = useState(null);

  const successSearchMessage = (response) => {
    // 302 not found --> data will be "HTML"
    // if ((response.status !== 200) || (typeof response.data === 'string' || response.data instanceof String)) {
    //   message.error("Az eljárás keresése során hiba lépett fel!");
    // } else {
    //   message.success("Az eljárást sikeresen megtaláltuk!");
    // }
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success("Az eljárást sikeresen megtaláltuk!");
    }
  };

  const notFoundMessage = () => {
    setRefreshSession(i => i + 1);
    message.warning("Az eljárás a megadott EKR azonosítóval nem található az adatbázisban!");
  };

  const errorSearchMessage = () => {
    message.error("Az eljárás keresése során hiba lépett fel!");
  };

  // Get / Refresh active ingredient OPTIONS
  const getOptionsAI = () => {
    axios.get("/api/activeIngredient/selectDTOList")
    .then((response) => {
      // console.log("getOptionsAI axios.get response: ", response);
      setOptionsAI((response && response.data && Array.isArray(response.data)) ? response.data : []);
    })
    .catch((error) => {
      console.error("getOptionsAI axios.get error: ", error);
    });
  };

  // Search ProcedureData by EKR id.
  const searchProcedureDataByEKR = (pdEKR) => {
    let ekrId = pdEKR ? pdEKR : null;
    // console.log("Searching ProcedureData by EKR id: ", ekrId);
    if (ekrId !== null) {
      axios.get("/api/procedureData/getProcedureDataByEKR/" + ekrId)
      .then((response) => {
        // console.log("searchProcedureDataByEKR axios.get response: ", response);
        successSearchMessage(response);
        if (response.data && response.status && response.status === 200 && (typeof response.data !== 'string')) {
          setSavedEkr(ekrId);
          setProcedureDataMain(response.data);
        } else {
          setSavedEkr(null);
          setProcedureDataMain(null);
        }
      })
      .catch((error) => {
        console.error("searchProcedureDataByEKR axios.get error: ", error);
        if (error && error.response && error.response.status && error.response.status === 404) {
          notFoundMessage();
        } else {
          errorSearchMessage();
        }
        setSavedEkr(null);
        setProcedureDataMain(null);
      });
    } else {
      message.warning("Az eljárás kereséséhez kérjük adjon meg egy EKR azonosítót!")
    }
  };

  // CREATE AND EDIT -- PROCEDURE_DATA METHODS for MODAL
  const openNewProcedureDataModal = () => {
    setProcedureDataIdForUpdate(null);
    setProcedureDataModalVisible(true);
  };

  const openUpdateProcedureDataModal = (pdId) => {
    setProcedureDataIdForUpdate(pdId);
    setProcedureDataModalVisible(true);
  };

  const closeProcedureDataModal = () => {
    setProcedureDataIdForUpdate(null);
    setProcedureDataModalVisible(false);
  };

  const saveProcedureData = (values) => {
    // console.log("Saved values of procedureDataModalForm: ", values);
    setProcedureDataIdForUpdate(null);
    setProcedureDataModalVisible(false);
    if (values.eljarasId && values.eljarasEKRId) {
      searchProcedureDataByEKR(values.eljarasEKRId);
      setSavedEkr(values.eljarasEKRId);
    }
  };

  // CREATE AND EDIT -- PROCEDURE_DATA_DETAILS METHODS for MODAL
  const openNewProcedureDataDetailsModal = (pdId) => {
    setProcedureDataDetailsUpdatePDID(pdId);
    setProcedureDataDetailsIdForUpdate(null);
    setProcedureDataDetailsModalVisible(true);
  };

  const openUpdateProcedureDataDetailsModal = (pdId, pddId) => {
    setProcedureDataDetailsUpdatePDID(pdId);
    setProcedureDataDetailsIdForUpdate(pddId);
    setProcedureDataDetailsModalVisible(true);
  };

  const closeProcedureDataDetailsModal = () => {
    setProcedureDataDetailsUpdatePDID(null);
    setProcedureDataDetailsIdForUpdate(null);
    setProcedureDataDetailsModalVisible(false);
  };

  const saveProcedureDataDetails = (values) => {
    // console.log("Saved values of procedureDataDetailsModalForm: ", values);
    getOptionsAI();
    setProcedureDataDetailsUpdatePDID(null);
    setProcedureDataDetailsIdForUpdate(null);
    setProcedureDataDetailsModalVisible(false);
    if (values.eljarasReszAdataiId) {
      searchProcedureDataByEKR(savedEkr);
    }
  };

  // CREATE AND EDIT -- BIDDING METHODS for MODAL
  const openNewBiddingModal = (pddId, totalAmount) => {
    setBiddingUpdatePDDID(pddId);
    setBiddingPPDTotalAmount(totalAmount);
    setBiddingIdForUpdate(null);
    setBiddingModalVisible(true);
  };

  const openUpdateBiddingModal = (bId, pddId, totalAmount) => {
    setBiddingUpdatePDDID(pddId);
    setBiddingPPDTotalAmount(totalAmount);
    setBiddingIdForUpdate(bId);
    setBiddingModalVisible(true);
  };

  const closeBiddingModal = () => {
    setBiddingUpdatePDDID(null);
    setBiddingPPDTotalAmount(null);
    setBiddingIdForUpdate(null);
    setBiddingModalVisible(false);
  };

  const saveBidding = (values) => {
    // console.log("Saved values of biddingModalForm: ", values);
    setBiddingUpdatePDDID(null);
    setBiddingPPDTotalAmount(null);
    setBiddingIdForUpdate(null);
    setBiddingModalVisible(false);
    if (values.ajanlattetelId) {
      searchProcedureDataByEKR(savedEkr);
    }
  };

  // CREATE AND EDIT -- CONTRACT METHODS for MODAL
  const openNewContractModal = (pddId, wbId, wbUnp, cAM) => {
    setContractPDDID(pddId);
    setContractWBID(wbId);
    setContractWBUnitNetPrice(wbUnp);
    setContractActiveMonths(cAM);
    setContractIdForUpdate(null);
    setContractModalVisible(true);
  };

  const openUpdateContractModal = (cId, pddId, wbId, wbUnp, cAM) => {
    setContractPDDID(pddId);
    setContractWBID(wbId);
    setContractWBUnitNetPrice(wbUnp);
    setContractActiveMonths(cAM);
    setContractIdForUpdate(cId);
    setContractModalVisible(true);
  };

  const closeContractModal = () => {
    setContractPDDID(null);
    setContractWBID(null);
    setContractWBUnitNetPrice(null);
    setContractActiveMonths(null);
    setContractIdForUpdate(null);
    setContractModalVisible(false);
  };

  const saveContract = (values) => {
    // console.log("Saved values of contractModalForm: ", values);
    setContractPDDID(null);
    setContractWBID(null);
    setContractWBUnitNetPrice(null);
    setContractActiveMonths(null);
    setContractIdForUpdate(null);
    setContractModalVisible(false);
    if (values.szerzodesId) {
      searchProcedureDataByEKR(savedEkr);
    }
  };

  const successDeleteMessage = (response, type) => {
    if (isSessionInvalid(response)) {
      logoutWhenSessionIsInvalid(setIsLogout)
    } else {
      setRefreshSession(i => i + 1);
      message.success(type + " sikeresen törölve lett!")
    }
  };

  const errorDeleteMessage = (type) => {
    message.error(type + " törlése sikertelen volt!")
  };

  const serverDeleteProcess = (url, type) => {
    axios.delete(url)
    .then((response) => {
      successDeleteMessage(response, type);
      if (type === "Eljárás") {
        setEkr("");
        setSavedEkr(null);
        setProcedureDataMain(null);
      } else {
        if (type === "Eljárásrész") {
          getOptionsAI();
        }
        searchProcedureDataByEKR(savedEkr);
      }
    }).catch((error) => {
      console.error(type + " (DELETE) - failed: ", error.response);
      errorDeleteMessage();
    });
  };

  return (
      <Fragment>
        <Divider>Szerkesztő felület</Divider>

        <Divider orientation="left">Eljárás keresése EKR azonosító alapján</Divider>
        <Row justify="start">
          <Col span={6}>
            <Input
                id="ekr"
                name="ekr"
                placeholder="Kérjük adjon meg egy EKR azonosítót!"
                value={ekr}
                onChange={handleEkr}
                allowClear
            />
          </Col>
          <Col span={6}>
            <Button type="primary" size="large" onClick={() => searchProcedureDataByEKR(ekr)} icon={<SearchOutlined/>}/>
          </Col>
        </Row>

        <Divider orientation="left">Eljárás rögzítése</Divider>
        <Button className="custom-create-button" onClick={() => openNewProcedureDataModal()} icon={<PlusSquareOutlined/>}>
          Új Eljárás rögzítése
        </Button>

        <BuildEditorPage
            procedureData={procedureDataMain}
            openUpdateProcedureDataModal={openUpdateProcedureDataModal}
            openNewProcedureDataDetailsModal={openNewProcedureDataDetailsModal}
            openUpdateProcedureDataDetailsModal={openUpdateProcedureDataDetailsModal}
            openNewBiddingModal={openNewBiddingModal}
            openUpdateBiddingModal={openUpdateBiddingModal}
            openNewContractModal={openNewContractModal}
            openUpdateContractModal={openUpdateContractModal}
            serverDeleteProcess={serverDeleteProcess}
        />

        <ProcedureDataForm
            visible={procedureDataModalVisible}
            procedureDataIdForUpdate={procedureDataIdForUpdate}
            onCreate={saveProcedureData}
            onCancel={closeProcedureDataModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />

        <ProcedureDataDetailsForm
            visible={procedureDataDetailsModalVisible}
            optionsAISelect={optionsAI}
            procedureDataDetailsUpdatePDID={procedureDataDetailsUpdatePDID}
            procedureDataDetailsIdForUpdate={procedureDataDetailsIdForUpdate}
            onCreate={saveProcedureDataDetails}
            onCancel={closeProcedureDataDetailsModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />

        <BiddingForm
            visible={biddingModalVisible}
            biddingUpdatePDDID={biddingUpdatePDDID}
            biddingPPDTotalAmount={biddingPPDTotalAmount}
            biddingIdForUpdate={biddingIdForUpdate}
            onCreate={saveBidding}
            onCancel={closeBiddingModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />

        <ContractForm
            visible={contractModalVisible}
            contractPDDID={contractPDDID}
            contractWBID={contractWBID}
            contractWBUnitNetPrice={contractWBUnitNetPrice}
            contractActiveMonths={contractActiveMonths}
            contractIdForUpdate={contractIdForUpdate}
            onCreate={saveContract}
            onCancel={closeContractModal}
            setIsLogout={setIsLogout}
            setRefreshSession={setRefreshSession}
        />
      </Fragment>
  );
};

export default Editor;
