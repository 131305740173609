// Util functions
import axios from "axios";
import {message} from "antd";

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const roundDouble = (value, decimalPlaces) => {
  return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces)
};

export const initializeFilterPostBody = (
    pageSize,
    pageNum,
    orderByFieldName,
    orderByDescending,
    oenoCode,
    activeIngredient,
    nameOfProduct,
    nameOfManufacturer,
    signingStartDateOfContract,
    signingEndDateOfContract,
    currencyGender,
    currencyValue,
    isDemo) => {
  return {
    pageSize: pageSize,
    pageNum: pageNum,
    hdaOrderBy: {
      fieldName: orderByFieldName,
      descending: orderByDescending,
    },
    hdaFilter: {
      searchFields: {
        OENO_CODE: {
          name: "OENO_CODE",
          value: oenoCode,
        },
        ACTIVE_INGREDIENT: {
          name: "ACTIVE_INGREDIENT",
          value: activeIngredient
        },
        NAME_OF_PRODUCT: {
          name: "NAME_OF_PRODUCT",
          value: nameOfProduct
        },
        NAME_OF_MANUFACTURER: {
          name: "NAME_OF_MANUFACTURER",
          value: nameOfManufacturer
        },
        SIGNING_START_DATE_OF_CONTRACT: {
          name: "SIGNING_START_DATE_OF_CONTRACT",
          value: signingStartDateOfContract
        },
        SIGNING_END_DATE_OF_CONTRACT: {
          name: "SIGNING_END_DATE_OF_CONTRACT",
          value: signingEndDateOfContract
        },
        CURRENCY_GENDER: {
          name: "CURRENCY_GENDER",
          value: currencyGender,
        },
        CURRENCY_VALUE: {
          name: "CURRENCY_VALUE",
          value: currencyValue,
        },
        DEMO_MODE: {
          name: "DEMO_MODE",
          value: isDemo,
        },
      }
    }
  };
};

export const initializeGanttChartConfig = (data, currencyGender, currencyValue, isDemoMode) => {
  return {
    data: {
      // Configures how to fetch resources for the Gantt
      resources: {
        data: data, // resources are provided in an array. Instead, we could configure a request to the server.
        // Activities of the resources are provided along with the 'activities' property of resource objects.
        // Alternatively, they could be listed from the 'data.activities' configuration.
        activities: 'activities',
        name: 'name', // The name of the resource is provided with the name property of the resource object.
        id: 'id', // The id of the resource is provided with the id property of the resource object.
      },
      // Configures how to fetch activities for the Gantt
      // As activities are provided along with the resources, this section only describes how to create
      // activity Gantt properties from the activity model objects.
      activities: {
        start: 'start', // The start of the activity is provided with the start property of the model object
        end: 'end', // The end of the activity is provided with the end property of the model object
        name: 'name', // The name of the activity is provided with the name property of the model object // in the GanttChart you can (LIKE)-search
        ekr: 'ekr',
        aktivIdoszakHossza: 'aktivIdoszakHossza',
        passzivIdoszakHossza: 'passzivIdoszakHossza',
        meghosszabithatosaganakHossza: 'meghosszabithatosaganakHossza',
        oenoCode: 'oenoCode',
        hatoanyagNeve: 'hatoanyagNeve',
        ajanlattevoNeve: 'ajanlattevoNeve',
        termekNeve: 'termekNeve',
        gyartoNeve: 'gyartoNeve',
        osszmennyiseg: 'osszmennyiseg',
        alapmennyiseg: 'alapmennyiseg',
        opciosmennyiseg: 'opciosmennyiseg',
        mertekegyseg: 'mertekegyseg',
        teljesMennyisegBruttoAra: 'teljesMennyisegBruttoAra',
        teljesMennyisegNettoAra: 'teljesMennyisegNettoAra',
        egysegBruttoAra: 'egysegBruttoAra',
        egysegNettoAra: 'egysegNettoAra',
        ddd: 'ddd',
        ddd1NapiKoltseg: 'ddd1NapiKoltseg',
        ddd28NaposKoltseg: 'ddd28NaposKoltseg',
        dddAtlagosHaviKoltseg: 'dddAtlagosHaviKoltseg',
        dddEvesKoltseg: 'dddEvesKoltseg',
        penznem: 'penznem',
        hirdetmenyKozzetetelenekDatuma: 'hirdetmenyKozzetetelenekDatuma',
        reszveteliJelentkezesHatarideje: 'reszveteliJelentkezesHatarideje',
        ajanlatteteliHatarido: 'ajanlatteteliHatarido',
        ajanlatokOsszegzoElbiralasanakDatuma: 'ajanlatokOsszegzoElbiralasanakDatuma',
        arurabatt: "arurabatt",
        kriteriumMinosegi: 'kriteriumMinosegi',
        kriteriumKoltseg: 'kriteriumKoltseg',
        kriteriumAr: 'kriteriumAr',
        eljarasEKRLink: 'eljarasEKRLink',
        szerzodesEKRLink: 'szerzodesEKRLink',
        szerzodesKozBeszLink: 'szerzodesKozBeszLink',
        eljarasId: 'eljarasId',
        eljarasReszAdataiId: 'eljarasReszAdataiId',
        szerzodesId: 'szerzodesId',
        nemNyertesAjanlatok: 'nemNyertesAjanlatok',
        honapokSzama: 'honapokSzama',
        meghosszabitas: 'meghosszabitas',
        szerzodesHosszArany: 'szerzodesHosszArany'
      },
      dateFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSZ" // Format to parse date strings from.
    },
    // Configure a custom tooltip
    timeTable: {
      renderer: {
        classes: function (activity, ctx) {
          // console.log("szerzodesHosszArany: ", activity.getData().szerzodesHosszArany);
          let szerzodesHosszAranyCSS = activity.getData().szerzodesHosszArany
              ? "contract-chart-" + activity.getData().szerzodesHosszArany
              : "contract-chart-100";
          return [szerzodesHosszAranyCSS];
        },
        tooltipProperties: function (activity, ctx) {

          setTimeout(() => addPropertiesToTheTooltip(activity, currencyGender, currencyValue, isDemoMode), 700);

          return [
            'Kezdete:', activity.getData().start.substring(0, 10).replaceAll('-', '.'),
            'Vége:', activity.getData().end.substring(0, 10).replaceAll('-', '.'),
            'Aktív időszak (hónapok):', activity.getData().aktivIdoszakHossza
                ? activity.getData().aktivIdoszakHossza
                : "",
            'Passzív időszak (hónapok):', activity.getData().passzivIdoszakHossza
                ? activity.getData().passzivIdoszakHossza
                : "",
            'Meghosszabbíthatóság (opció) (hónapok):', activity.getData().meghosszabithatosaganakHossza
                ? activity.getData().meghosszabithatosaganakHossza
                : "",
            'Szerződés meghosszabbítása:', activity.getData().meghosszabitas
                ? activity.getData().meghosszabitas
                : "Nincs hosszabítás.",
            'EKR azonosító:', activity.getData().ekr,
            'OENO / ATC kód:', activity.getData().oenoCode,
            'Hatóanyag:', activity.getData().hatoanyagNeve,
            'Nyertes ajánlattevő neve:', activity.getData().ajanlattevoNeve,
            'Érvényes, nem nyertes ajánlattevők: ', activity.getData().nemNyertesAjanlatok
                ? activity.getData().nemNyertesAjanlatok
                : "Csak egy érvényes ajánlattétel volt.",
            'Termék neve:', activity.getData().termekNeve,
            'Gyártó cég neve:', activity.getData().gyartoNeve,
            'Teljes mennyiség:', activity.getData().osszmennyiseg
                ? numberWithCommas(roundDouble(activity.getData().osszmennyiseg, 1))
                : "",
            'Alapmennyiség:', activity.getData().alapmennyiseg
                ? numberWithCommas(roundDouble(activity.getData().alapmennyiseg, 1))
                : "",
            'Opciós mennyiség:', activity.getData().opciosmennyiseg
                ? numberWithCommas(roundDouble(activity.getData().opciosmennyiseg, 1))
                : "",
            'Mértékegység:', activity.getData().mertekegyseg
                ? activity.getData().mertekegyseg
                : "",
            'Teljes mennyiség bruttó ára:', activity.getData().teljesMennyisegBruttoAra
                ? numberWithCommas(roundDouble(activity.getData().teljesMennyisegBruttoAra, 1))
                : "",
            'Teljes mennyiség nettó ára:', activity.getData().teljesMennyisegNettoAra
                ? numberWithCommas(roundDouble(activity.getData().teljesMennyisegNettoAra, 1))
                : "",
            'Egység bruttó ára (teljes mennyiségre nézve):', activity.getData().egysegBruttoAra
                ? numberWithCommas(activity.getData().egysegBruttoAra)
                : "",
            'Egység nettó ára (teljes mennyiségre nézve):', activity.getData().egysegNettoAra
                ? numberWithCommas(activity.getData().egysegNettoAra)
                : "",
            'DDD értéke (mg):', activity.getData().ddd
                ? numberWithCommas(roundDouble(activity.getData().ddd, 2))
                : "Nincs megadva.",
            'DDD-vel súlyozott 1 napi terápiás költség (nettó ár):', activity.getData().ddd1NapiKoltseg
                ? numberWithCommas(roundDouble(activity.getData().ddd1NapiKoltseg, 2))
                : "Nincs megadva.",
            'DDD-vel súlyozott 28 napos terápiás költség (nettó ár):', activity.getData().ddd28NaposKoltseg
                ? numberWithCommas(roundDouble(activity.getData().ddd28NaposKoltseg, 2))
                : "Nincs megadva.",
            'DDD-vel súlyozott átlagos havi terápiás költség (nettó ár):', activity.getData().dddAtlagosHaviKoltseg
                ? numberWithCommas(roundDouble(activity.getData().dddAtlagosHaviKoltseg, 2))
                : "Nincs megadva.",
            'DDD-vel súlyozott éves terápiás költség (nettó ár):', activity.getData().dddEvesKoltseg
                ? numberWithCommas(roundDouble(activity.getData().dddEvesKoltseg, 2))
                : "Nincs megadva.",
            'Pénznem:', activity.getData().penznem,
            'Hirdetmény közzététele:', activity.getData().hirdetmenyKozzetetelenekDatuma,
            'Részvételi jelentkezés határideje:', activity.getData().reszveteliJelentkezesHatarideje,
            'Ajánlattételi határidő:', activity.getData().ajanlatteteliHatarido,
            'Ajánlatok összegző elbírálásának dátuma:', activity.getData().ajanlatokOsszegzoElbiralasanakDatuma
                ? activity.getData().ajanlatokOsszegzoElbiralasanakDatuma
                : "",
            'Árurabatt:', activity.getData().arurabatt
                ? activity.getData().arurabatt
                : "",
            'Minőségi kritérium:', activity.getData().kriteriumMinosegi ? "igen" : "nem",
            'Költség kritérium:', activity.getData().kriteriumKoltseg ? "igen" : "nem",
            'Ár kritérium:', activity.getData().kriteriumAr ? "igen" : "nem"
          ];
        },
      }
    },
    // Configure a toolbar associated with the Gantt
    toolbar: [
      'title',
      'search',
      'separator',
      // {
      //   type: 'button',
      //   text: 'Refresh',
      //   fontIcon: 'fa fa-refresh fa-lg',
      //   onclick: function (ctx) {
      //     ctx.gantt.draw();
      //   },
      // },
      'fitToContent',
      'zoomIn',
      'zoomOut',
    ],
    title: 'Hatóanyagokhoz tartozó szerződések', // Title for the Gantt to be displayed in the toolbar
  }
};

const addPropertiesToTheTooltip = (prop, currencyGender, currencyValue, isDemoMode) => {
  // console.log("addPropertiesToTheTooltip prop: ", prop.getData());

  let ganttChartTooltips = document.getElementsByClassName("gantt-tooltip");
  if (ganttChartTooltips.length > 1) {
    while (ganttChartTooltips.length > 1) {
      ganttChartTooltips[0].parentNode.removeChild(ganttChartTooltips[0])
    }
  }

  let trElementsEljarasEKRLink = document.getElementsByClassName("tooltipEljarasEKRLink");
  while (trElementsEljarasEKRLink[0]) {
    trElementsEljarasEKRLink[0].parentNode.removeChild(trElementsEljarasEKRLink[0]);
  }

  let trElementsSzerzodesEKRLink = document.getElementsByClassName("tooltipSzerzodesEKRLink");
  while (trElementsSzerzodesEKRLink[0]) {
    trElementsSzerzodesEKRLink[0].parentNode.removeChild(trElementsSzerzodesEKRLink[0]);
  }

  let trElementsSzerzodesKozBeszLink = document.getElementsByClassName("tooltipSzerzodesKozBeszLink");
  while (trElementsSzerzodesKozBeszLink[0]) {
    trElementsSzerzodesKozBeszLink[0].parentNode.removeChild(trElementsSzerzodesKozBeszLink[0]);
  }

  let trElementsExcel = document.getElementsByClassName("tooltipExcel");
  while (trElementsExcel[0]) {
    trElementsExcel[0].parentNode.removeChild(trElementsExcel[0]);
  }

  let trEljarasEKRLink = createTR("tooltipEljarasEKRLink", "Eljárás EKR fájlok", prop.getData().eljarasEKRLink);
  if (trEljarasEKRLink) {
    document.getElementsByClassName("gantt-tooltip")[0].getElementsByTagName("tbody")[0].appendChild(trEljarasEKRLink);
  }

  let trSzerzodesEKRLink = createTR("tooltipSzerzodesEKRLink", "Szerződés EKR fájlok", prop.getData().szerzodesEKRLink);
  if (trSzerzodesEKRLink) {
    document.getElementsByClassName("gantt-tooltip")[0].getElementsByTagName("tbody")[0].appendChild(trSzerzodesEKRLink);
  }

  let trSzerzodesKozBeszLink = createTR("tooltipSzerzodesKozBeszLink", "Szerződés KözBesz fájlok", prop.getData().szerzodesKozBeszLink);
  if (trSzerzodesKozBeszLink) {
    document.getElementsByClassName("gantt-tooltip")[0].getElementsByTagName("tbody")[0].appendChild(trSzerzodesKozBeszLink);
  }

  let btnExcel = document.createElement("button");
  btnExcel.className = "contractExcelExportButton";
  btnExcel.innerHTML = "Excel export";
  btnExcel.onclick = function () {
    exportContractToEXCEL(
        prop.getData().eljarasId,
        prop.getData().eljarasReszAdataiId,
        prop.getData().szerzodesId,
        currencyGender,
        currencyValue,
        isDemoMode
    );
  };

  let trExcel = createTRWithButton("tooltipExcel", "Excel exportálás", btnExcel);
  document.getElementsByClassName("gantt-tooltip")[0].getElementsByTagName("tbody")[0].appendChild(trExcel);
};

const createTR = (trClassName, td1Text, link) => {
  if (!link) {
    return null;
  }

  let tr = document.createElement("tr");
  tr.className = trClassName;

  let td_1 = document.createElement("td");
  td_1.className = "tooltip-key";
  td_1.appendChild(document.createTextNode(td1Text + ":"));

  let td_2 = document.createElement("td");
  td_2.className = "tooltip-table-separator";

  let td_3 = document.createElement("td");
  td_3.className = "tooltip-value";
  let a = document.createElement("a");
  a.title = link ? link : "";
  a.href = link ? link : "";
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.appendChild(document.createTextNode(td1Text + " link"));
  td_3.appendChild(a);

  tr.appendChild(td_1);
  tr.appendChild(td_2);
  tr.appendChild(td_3);

  return tr;
};

const createTRWithButton = (trClassName, td1Text, button) => {
  let tr = document.createElement("tr");
  tr.className = trClassName;

  let td_1 = document.createElement("td");
  td_1.className = "tooltip-key";
  td_1.appendChild(document.createTextNode(td1Text + ":"));

  let td_2 = document.createElement("td");
  td_2.className = "tooltip-table-separator";

  let td_3 = document.createElement("td");
  td_3.className = "tooltip-value";
  td_3.appendChild(button);

  tr.appendChild(td_1);
  tr.appendChild(td_2);
  tr.appendChild(td_3);

  return tr;
};

const exportContractToEXCEL = (eljarasId, eljarasReszAdataiId, szerzodesId, currencyGender, currencyValue, isDemoMode) => {
  let url = "/api/procedureDataDetails/generateContractExcel?eljarasId=" + eljarasId
      + "&eljarasReszAdataiId=" + eljarasReszAdataiId
      + "&szerzodesId=" + szerzodesId
      + "&currencyGender=" + currencyGender
      + "&currencyValue=" + currencyValue
      + "&isDemoMode=" + isDemoMode;

  axios.get(url)
  .then((response) => {
    // console.log("generateExcel (contract) - axios.get response: ", response);
    let downloadURL = "/api/procedureDataDetails/downloadContractExcel?excelId=" + response.data;

    let tempDate = new Date(new Date().toString().split("GMT")[0] + " UTC")
    .toISOString()
    .split(".")[0]
    .replace("T", " ");

    let a = document.createElement("a");
    a.href = downloadURL;
    a.download = ("PPC_Szerzodes_" + tempDate + ".xlsx").replaceAll(":", "_");
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
  .catch((error) => {
    console.error("generateExcel (contract) - axios.get error: ", error);
  });
};

export const isSessionInvalid = (response) => {
  if (response && response.data && typeof response.data === 'string'
      && response.data.includes("<noscript>index.html file: You need to enable JavaScript to run this app.</noscript>")){
    return true;
  }
  return false;
};

export const logoutWhenSessionIsInvalid = (setIsLogout) => {
  axios.get('/api/logout')
  .then(response => {
    localStorage.removeItem("hda_ppc_userName");
    localStorage.removeItem("hda_ppc_userRoles");
    console.log("Logout (session is invalid / has expired) response: ", response);
    setIsLogout(true);
    message.warn("A munkamenet ideje lejárt! Kérjük jelentkezzen be újra.", 5);
  })
  .catch(error => {
    console.error("react-logout error: ", error);
  })
};
