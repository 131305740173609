import React from "react";
import Login from "./components/loginpage/Login";
import Dashboard from "./components/dashboardpage/Dashboard";
import {HashRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import ProtectedRoute from "./common/ProtectedRoute";

const App = () => {
  return (
      <Router>
        <Switch>
          <Route path="/login">
            <Login/>
          </Route>

          <ProtectedRoute path="/dashboard">
            <Dashboard/>
          </ProtectedRoute>

          <Route exact path="/">
            <Redirect exact from="/" to="dashboard"/>
          </Route>

          <Route path="*">
            <Redirect from="/" to="dashboard"/>
          </Route>
        </Switch>
      </Router>
  );
};

export default App;
