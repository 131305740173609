import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import React from "react";

export const getColumnSearchProps = (dataIndex, searchInput, searchText, searchedColumn, handleTableSearch, handleTableReset) => ({
  filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder="Adjon meg egy kifejezést!"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleTableSearch(selectedKeys, confirm, dataIndex)}
            style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
              type="primary"
              onClick={() => handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined/>}
              size="small"
              style={{width: 90}}
          >
            Keresés
          </Button>
          <Button onClick={() => handleTableReset(clearFilters)} size="small" style={{width: 90}}>
            Törlés
          </Button>
        </Space>
      </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
  onFilter: (value, record) =>
      record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
      searchedColumn === dataIndex ? (
          <Highlighter
              highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
          />
      ) : (
          text
      ),
});
