import React, {useState, useMemo} from "react";
import WindowedSelect from "react-windowed-select";

/**
 * REACT-SELECT library info
 * https://react-select.com/home
 * https://github.com/JedWatson/react-select
 */

/**
 * REACT-WINDOWED-SELECT library info
 * An integration of react-window with react-select to efficiently render large lists.
 * https://www.npmjs.com/package/react-windowed-select
 * https://github.com/jacobworrel/react-windowed-select
 */

// Ant Design react component style
const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 2,
    borderColor: state.isFocused ? "#40a9ff" : "#d9d9d9",
    boxShadow: state.isFocused ? "0 0 0 2px rgb(24 144 255 / 20%)" : "#40a9ff",
    "&:hover": {borderColor: "#40a9ff"}
  })
};

const isSelectedOption = (selectedElementsArray, examinedElementLabel) => {
  let result = false;
  if (selectedElementsArray && Array.isArray(selectedElementsArray) && selectedElementsArray.length > 0) {
    selectedElementsArray.forEach(item => {
      if (item.label && item.label === examinedElementLabel) {
        result = true
      }
    });
  }
  return result;
};

/**
 * AutoComplete Select OR MultiSelect field on the form
 * @param nameAndClassAttr: name and class html attribute's value
 * @param placeholder: placeholder of the AutoComplete Select OR MultiSelect
 * @param selectFilterFieldRef: react useRef
 * @param filterField: filter field object on the form
 * @param setFilterField: setter for the filter field object on the form
 * @param optionsArray: all the options from which we filter, example format: [{value: "1", label: "test-1"}, {value: "2", label: "test-2"}]
 *        optionsArray: by default we get the optionsArray from server in alphabetical ascending order by LABEL
 * @param isMultiSelect: simple select OR multi select
 * @param filterMode: null || "prefixFirst" || "abcFilter"
 * @param isClearable: enable or disable the Clearable function
 * @return WindowedSelect react component
 */
export const AutoCompleteSelectBox = React.memo(
    ({nameAndClassAttr, placeholder, selectFilterFieldRef, filterField, setFilterField, optionsArray, isMultiSelect, filterMode, isClearable}) => {

      // Limit the number of the displayed options
      const MAX_DISPLAYED_OPTIONS = 1000000000; // 1 billion = unlimited
      const DEFAULT_FILTER_MODE = "prefixFirst";

      // Input field query's value
      const [inputQuery, setInputQuery] = useState("");

      // Filter logic of the optionsArray (option.label)
      const filteredOptions = useMemo(() => {
        // Which will check for empty strings (""), null, undefined, false and the numbers 0 and NaN. (!null -> true; !"" -> true; etc...)
        if (!inputQuery) {
          return optionsArray;
        }

        let selectedFilterMode = filterMode === null || filterMode === "" ? DEFAULT_FILTER_MODE : filterMode;

        if (selectedFilterMode === "abcFilter") {
          return abcFilter(optionsArray);
        } else {
          return prefixFirstFilter(optionsArray);
        }
      }, [inputQuery, optionsArray]);

      // optionsArray: by default we get the optionsArray from server in alphabetical ascending order by LABEL
      function abcFilter(optionsArray) {
        const matchArray = [];

        for (const option of optionsArray) {
          if (matchArray.length === MAX_DISPLAYED_OPTIONS) {
            break;
          }
          if (option.label.toLowerCase().indexOf(inputQuery.toLowerCase()) >= 0) {
            matchArray.push(option);
          }
        }

        return matchArray;
      }

      // optionsArray: by default we get the optionsArray from server in alphabetical ascending order by LABEL
      function prefixFirstFilter(optionsArray) {
        let matchArray = [];
        const firstArr = [];
        const secArr = [];

        for (const option of optionsArray) {
          if (firstArr.length >= MAX_DISPLAYED_OPTIONS) {
            break;
          }

          let ind = option.label.toLowerCase().indexOf(inputQuery.toLowerCase());
          if (ind === 0) {
            firstArr.push(option);
          } else if (ind > 0) {
            secArr.push(option);
          }
        }

        matchArray = firstArr.concat(secArr).slice(0, MAX_DISPLAYED_OPTIONS);

        return matchArray;
      }

      // Sliced optionsArray
      let slicedOptions = useMemo(
          () => filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS),
          [filteredOptions]
      );

      // ONLY in the Multi-Select: it's necessary when the user's saved queries were loaded from server --> remove the selected values from the options (temporarily)
      // When the user add a new selected option from the FilterFieldList --> remove this from the options (temporarily)
      // When the user remove a selected option from the FilterFieldList --> add this to the options (temporarily)
      if (isMultiSelect && Array.isArray(slicedOptions) && slicedOptions.length > 0) {
        let tempSlicedOptions = [];
        tempSlicedOptions = slicedOptions.filter(item => item.label && !isSelectedOption(filterField, item.label));
        slicedOptions = tempSlicedOptions;
      }

      return (
          <WindowedSelect
              styles={customStyles}
              maxMenuHeight={200}
              isMulti={isMultiSelect}
              ref={selectFilterFieldRef}
              inputId={nameAndClassAttr} // input HTML field id
              name={nameAndClassAttr}
              className={nameAndClassAttr}
              classNamePrefix={isMultiSelect ? "multi-select" : "select"}
              placeholder={placeholder}
              defaultValue={filterField}
              value={filterField}
              options={slicedOptions}
              onInputChange={inputQuery => setInputQuery(inputQuery)}
              onChange={setFilterField}
              filterOption={() => true} // Disable the REACT-SELECT's native filter logic!
              noOptionsMessage={() => "Nincs találat"}
              isClearable={isClearable}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#264a60',
                  primary25: '#e6f7ff'
                }
              })}
          />
      );
    });
